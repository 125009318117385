import { NativeDateAdapter } from '@angular/material/core';
import { DateUtilities } from 'src/app/core/utilities/app/date.utilities';
import { Injectable } from '@angular/core';

@Injectable()
export class DatePickerAdapter extends NativeDateAdapter {
  formatString: string;

  format(date: Date, displayFormat: Object): string {
    if (!this.formatString) {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      return `${('0' + date.getDate()).slice(-2)} ${months[date.getMonth()]} ${date.getFullYear()}`;
    }
    return DateUtilities.format(date, this.formatString);
  }
}
