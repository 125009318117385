<form [formGroup]="formGroupRef">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">

<!--        <div class="mt-2" *ngIf="!readonly">-->
<!--          <app-location-search (locationSelect)="onLocationSelected($event)"></app-location-search>-->
<!--        </div>-->
<!--        <div class="mt-2">-->
<!--          <mat-form-field class="mr-2 mat-form-field-double">-->
<!--            <mat-label>Full Address</mat-label>-->
<!--            <input [readonly]="readonly" placeholder="e.g.: 1600 Pennsylvania Avenue NW Suite 100, Washington, DC 20500, USA" matInput-->
<!--                   type="text" formControlName="fullAddress">-->
<!--          </mat-form-field>-->
<!--        </div>-->
        <div class="mt-2">
          <mat-form-field class="mr-2 mat-form-field-double">
            <mat-label>Address Line 1</mat-label>
            <input [readonly]="readonly" matInput placeholder="e.g.: 1600 Pennsylvania Avenue NW" type="text"
                   formControlName="addressLineOne">
          </mat-form-field>
        </div>
        <div class="mt-2">
          <mat-form-field class="mat-form-field-double">
            <mat-label>Address Line 2</mat-label>
            <input [readonly]="readonly" matInput placeholder="e.g.: Suite 100" type="text" formControlName="addressLineTwo">
          </mat-form-field>
        </div>
        <div class="mt-2">
          <mat-form-field class="mr-2">
            <mat-label>City</mat-label>
            <input [readonly]="readonly" matInput placeholder="e.g.:Washington" type="text" formControlName="city">
          </mat-form-field>
          <mat-form-field>
            <mat-label>State</mat-label>
            <input [readonly]="readonly" matInput placeholder="e.g.: DC" type="text" formControlName="state">
          </mat-form-field>
        </div>
        <div class="mt-2">
          <mat-form-field class="mr-2">
            <mat-label>Postal Code</mat-label>
            <input [readonly]="readonly" matInput placeholder="e.g.: 20500" type="text" formControlName="postalCode">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Country</mat-label>
            <input [readonly]="readonly" matInput placeholder="e.g.: USA" type="text" formControlName="country">
          </mat-form-field>
        </div>
        <div *ngIf="showGPS" class="mt-2">
          <mat-form-field class="mr-2">
            <mat-label>Latitude</mat-label>
            <input [readonly]="readonly" matInput type="number" formControlName="latitude">
          </mat-form-field>
          <mat-form-field>
            <mat-label>longitude</mat-label>
            <input [readonly]="readonly" matInput type="number" formControlName="longitude">
          </mat-form-field>
        </div>
        <div class="mt-2" *ngIf="showSecondaryAddress">
          <mat-checkbox [disabled]="readonly" formControlName="isMailingAddressSame">
            Use same for mailing address
          </mat-checkbox>
        </div>
      </div>
      <div *ngIf="showSecondaryAddress" class="col-sm-6">
        <div *ngIf="!this.value.isMailingAddressSame">
          <div class="mt-2" *ngIf="!readonly">
            <app-location-search (locationSelect)="onMailingLocationSelected($event)"></app-location-search>
          </div>
          <div class="mt-2">
            <mat-form-field class="mr-2 mat-form-field-double">
              <mat-label>Mailing Full Address</mat-label>
              <input [readonly]="readonly" placeholder="e.g.: 1600 Pennsylvania Avenue NW Suite 100, Washington, DC 20500, USA" matInput
                     type="text" formControlName="fullAddressMailing">
            </mat-form-field>
          </div>
          <div class="mt-2">
            <mat-form-field class="mr-2 mat-form-field-double">
              <mat-label>Address Line 1</mat-label>
              <input [readonly]="readonly" matInput placeholder="e.g.: 1600 Pennsylvania Avenue NW" type="text"
                     formControlName="addressLineOneMailing">
            </mat-form-field>
          </div>
          <div class="mt-2">
            <mat-form-field class="mat-form-field-double">
              <mat-label>Address Line 2</mat-label>
              <input [readonly]="readonly" matInput placeholder="e.g.: Suite 100" type="text" formControlName="addressLineTwoMailing">
            </mat-form-field>
          </div>
          <div class="mt-2">
            <mat-form-field class="mr-2">
              <mat-label>City</mat-label>
              <input [readonly]="readonly" matInput placeholder="e.g.:Washington" type="text" formControlName="cityMailing">
            </mat-form-field>
            <mat-form-field>
              <mat-label>State</mat-label>
              <input [readonly]="readonly" matInput placeholder="e.g.: DC" type="text" formControlName="stateMailing">
            </mat-form-field>
          </div>
          <div class="mt-2">
            <mat-form-field class="mr-2">
              <mat-label>Postal Code</mat-label>
              <input [readonly]="readonly" matInput placeholder="e.g.: 20500" type="text" formControlName="postalCodeMailing">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Country</mat-label>
              <input [readonly]="readonly" matInput placeholder="e.g.: USA" type="text" formControlName="countryMailing">
            </mat-form-field>
          </div>
          <div *ngIf="showGPS" class="mt-2">
            <mat-form-field class="mr-2">
              <mat-label>Latitude</mat-label>
              <input [readonly]="readonly" matInput type="number" formControlName="latitudeMailing">
            </mat-form-field>
            <mat-form-field>
              <mat-label>longitude</mat-label>
              <input [readonly]="readonly" matInput type="number" formControlName="longitudeMailing">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

</form>
