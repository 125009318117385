import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ReportNotificationToSendModel } from 'src/app/shared/models/domain/report-notification-to-send.model';
import { ReportNotificationService } from 'src/app/core/services/domain/report-notification.service';
import { ClientToBusinessAccessModel } from 'src/app/shared/models/domain/client-to-business-access.model';
import { ClientToBusinessAccessService } from 'src/app/core/services/domain/client-to-business-access.service';
import { BusinessService } from 'src/app/core/services/domain/business.service';

@Component({
  selector: 'app-add-access-dialog',
  templateUrl: './add-access-dialog.component.html',
  styleUrls: ['./add-access-dialog.component.scss']
})
export class AddAccessDialogComponent extends BaseComponent implements OnInit {
  businessName: string;
  formGroupRef: UntypedFormGroup;
  isSaving: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<AddAccessDialogComponent>,
    private clientToBusinessAccessService: ClientToBusinessAccessService,
    @Inject(MAT_DIALOG_DATA) public dialogData: { businessKey: string, email: string, lastName: string, firstName: string },
    private businessService: BusinessService,
    protected notificationService: NotificationService) {
    super();
  }

  ngOnInit(): void {
    this.businessService.get(this.dialogData.businessKey).subscribe(business => {
      this.businessName = business?.name;
    })

    this.formGroupRef = new UntypedFormGroup({
      firstName: new UntypedFormControl(this.dialogData.firstName ?? null),
      lastName: new UntypedFormControl(this.dialogData.lastName ?? null),
      email: new UntypedFormControl(this.dialogData.email ?? null),
      message: new UntypedFormControl(null),
      copyToEmail: new UntypedFormControl(null)
    })
  }

  onSaveClicked(): void {
    this.isSaving = true;

    let model = <ClientToBusinessAccessModel>{
      clientReference: {
        email: this.formGroupRef.value.email,
        firstName: this.formGroupRef.value.firstName,
        lastName: this.formGroupRef.value.lastName
      },
      copyToEmail: this.formGroupRef.value.copyToEmail,
      message: this.formGroupRef.value.message,
      businessKey: this.dialogData.businessKey
    };

    this.clientToBusinessAccessService.create(model).subscribe(result => {
      this.notificationService.showSuccessNotification("Access and invite have been created successfully.")
      this.dialogRef.close(true);
    }, err => {
      this.isSaving = false;
      this.onHttpFailure(this.notificationService, err);
    });
  }

  onCancelClicked(): void {
    this.dialogRef.close(false);
  }
}


// this.isSending = true;
// let model: ReportNotificationToSendModel = {
//   report: this.dialogData.report,
//   reportBeneficialOwner: this.dialogData.reportBeneficialOwner,
//   reportCompanyApplicant: this.dialogData.reportCompanyApplicant,
//   copyToEmail: this.formGroupRef.value.copyToEmail,
//   message: this.formGroupRef.value.message
// }
//
// this.reportNotificationService.create(model).subscribe(_ => {
//   this.notificationService.showSuccessNotification('Reminder has been sent successfully.');
//   this.dialogRef.close(this.formGroupRef.value);
//   this.isSending = false;
// }, err => {
//   this.isSending = false;
//   this.onHttpFailure(this.notificationService, err);
// });

