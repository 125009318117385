<mat-form-field class="mat-form-field-date">
  <mat-label [ngClass]="{required: required}">{{label}}</mat-label>
  <input gtnDisableAutofill matInput [matDatepicker]="picker" [value]="value" [min]="minDate" [max]="maxDate"
         [readonly]="disabled || readonly" (dateChange)="value = $event.target.value"/>
  <mat-datepicker-toggle *ngIf=" !disabled && !readonly" matSuffix [for]="picker">
  </mat-datepicker-toggle>
  <mat-datepicker #picker>
  </mat-datepicker>
  <mat-icon *ngIf="helpText" matSuffix [matTooltip]="helpText">
    help_outline
  </mat-icon>
</mat-form-field>
