import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ClientModel } from 'src/app/shared/models/domain/client.model';
import { ClientSearchModel } from 'src/app/shared/models/domain/client-search.model';
import { CountryModel } from 'src/app/shared/models/domain/country.model';
import { map } from 'rxjs/operators';

@Injectable()
export class CountryService {
  private http: HttpClient;
  private url = environment.staticDataUrl + '/';

  static usCountryCode = 'US'

  countryCache: CountryModel[] = null;

  constructor(httpBackend: HttpBackend) {
    this.http = new HttpClient(httpBackend);
  }

  list(): Observable<CountryModel[]> {
    //https://github.com/lukes/ISO-3166-Countries-with-Regional-Codes/blob/master/all/all.json
    if (this.countryCache !== null) {
      return of(JSON.parse(JSON.stringify(this.countryCache)));
    }

    return this.http.get<CountryModel[]>(`${this.url}countries.json`).pipe(map((data: any[]) => {
      let mappedData = data.map(t => {
        return {
          name: t.name,
          code: t['alpha-2']
        }
      });

      let usCountryCode = mappedData.find(t => t.code === CountryService.usCountryCode);
      mappedData.unshift(usCountryCode);

      this.countryCache = mappedData;

      return mappedData;
    }));
  }

  listUSCountries(): Observable<CountryModel[]> {
    let usBasedCountryCodes = ['PR', CountryService.usCountryCode, 'AS', 'GU', 'MH', 'FM', 'MP', 'PW', 'VI']

    return this.list().pipe(map(t => {
      return t.filter(t => usBasedCountryCodes.indexOf(t.code) !== -1);
    }));
  }
}
