import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(private authService: AuthService) {

  }

  ngOnInit(): void {
    this.authService.logout();
    //ALSO IN APP COMPONENT
    if (window.location.href.indexOf('?signup=1') !== -1) {
      //http://localhost:4200/login?email=hello@world.com
      //let email = decodeURI(queryStringParts[1]);
      //this.authService.loginWithRedirect({screen_hint: 'signup', login_hint: email });
      this.authService.loginWithRedirect({screen_hint: 'signup' });
      return;
    } else {
      this.authService.loginWithRedirect();
    }
  }
}
