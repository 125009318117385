import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { ViewModesEnum } from 'src/app/shared/enums/app/view-modes.enum';
import { MatTab, MatTabGroup, MatTabHeader } from '@angular/material/tabs';

@Component({
  template: ''
})
export abstract class BaseFormComponent<T> extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild(MatTabGroup) tabs: MatTabGroup;

  @Input() formGroupRef: UntypedFormGroup;
  @Output() formGroupRefChange = new EventEmitter<UntypedFormGroup>();

  @Input() value: T = <T>{};
  @Output() valueChange = new EventEmitter<T>();

  @Input() viewMode: ViewModesEnum = ViewModesEnum.All;

  @Input() selectedTabIndex: number = 0;
  @Output() selectedTabIndexChange = new EventEmitter<number>();
  @Output() beforeTabChange = new EventEmitter<number>();

  viewModes = ViewModesEnum;

  ngOnInit() {
    this.formGroupRef = this.getFormGroup();
    this.formGroupRefChange.emit(this.formGroupRef);

    this.subscriptions.add(this.formGroupRef.valueChanges.subscribe(val => {
      this.value = JSON.parse(JSON.stringify(val));
      this.valueChange.emit(this.value);
    }));

    this.value = JSON.parse(JSON.stringify(this.formGroupRef.value));
    this.valueChange.emit(this.value);
  }

  ngAfterViewInit(): void {
    if (this.tabs) {
      this.tabs._handleClick = this.interceptTabChange.bind(this);
    }
  }

  protected abstract getFormGroup(): UntypedFormGroup;

  getFormGroupFromAbstractControl(abstractControl: AbstractControl): UntypedFormGroup {
    return abstractControl as UntypedFormGroup;
  }

  private interceptTabChange(tab: MatTab, tabHeader: MatTabHeader, idx: number) {
    if (!this.beforeTabChange.observers.length) {
      this.selectedTabIndex = idx;
      this.selectedTabIndexChange.emit(idx);
      MatTabGroup.prototype._handleClick.apply(this.tabs, arguments);
    } else {
      this.beforeTabChange.emit(idx);
    }
  }
}
