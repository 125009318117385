import { Component, Input, OnInit } from '@angular/core';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { ReportActivityModel } from 'src/app/shared/models/domain/report-activity.model';
import { ReportActivityTypesEnum } from 'src/app/shared/enums/domain/report-activity-types.enum';
import { ReportActivityService } from 'src/app/core/services/domain/report.activity.service';
import { ReportStatusesEnum } from 'src/app/shared/enums/domain/report-statuses-enum';

@Component({
  selector: 'app-report-activities-grid',
  templateUrl: './report-activities-grid.component.html',
  styleUrls: ['./report-activities-grid.component.scss']
})
export class ReportActivitiesGridComponent extends BaseGridComponent<ReportActivityModel> implements OnInit {
  @Input() businessKey: string = null;
  @Input() includeChildBusinesses: boolean = false;
  @Input() isAdmin: boolean = false;
  @Input() isMinimalistView: boolean = false;

  gridTitle: string = 'Activities';

  constructor(public authClientService: AuthClientService,
              private reportActivityService: ReportActivityService) {
    super();
    this.gridKey = `report_activities_${this.isAdmin}_v4`;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected setColumnDefinitions(): ColDef[] {
    return [
      this.getDefaultColumn('business.legalName', 'Legal Name'),
      this.getEnumColumn('reportStatus', 'Report Status', ReportStatusesEnum),
      this.getEnumColumn('type', 'Type', ReportActivityTypesEnum),
      this.getCreatedByColumn(),
      this.getCreatedOnColumn(),
      this.getDefaultColumn('activity', 'Details')
    ];
  }

  protected onGridReady(): void {
    this.subscriptions.add(this.cellClick.subscribe((cellClick: { data: ReportActivityModel, event: CellClickedEvent }) => {
      if (cellClick.event.colDef.field === this.actionFieldName) {

      }
    }));
  }

  protected setRowData(): Observable<any[]> {
    let businessKeys = [];
    let statuses = [];
    if (this.businessKey) {
      businessKeys.push(this.businessKey);
    }

    return this.reportActivityService.search({
      businessKeys: businessKeys,
      includeChildBusinesses: this.includeChildBusinesses,
      isAdmin: this.isAdmin,
    });
  }
}
