<form [formGroup]="formGroupRef">
  <div class="form-section-header-title mt-2">Select Your Plan Type</div>
  <div class="flex mt-3" *ngIf="!hideTypeSelection && type">
    <mat-radio-group
      class="flex flex-wrap"
      (change)="onTypeChanged()"
      formControlName="type">
      <mat-radio-button class="mr-1 ml-1" [value]="businessSubscriptionTypes.IndividualOneTime">Single entity one time
        file
      </mat-radio-button>
      <mat-radio-button class="mr-1 ml-1" [value]="businessSubscriptionTypes.IndividualRecurring">Single entity annual
        plan
      </mat-radio-button>
      <mat-radio-button class="mr-1 ml-1" [value]="businessSubscriptionTypes.Enterprise">Professional & enterprise
        firms
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="mt-2" *ngIf="value.type === businessSubscriptionTypes.IndividualOneTime || value.type === businessSubscriptionTypes.IndividualRecurring">
    If you sign up for a one-time file and would like to upgrade to an annual plan, please contact <a href="mailto:info@fincenboireporting.com">info@fincenboireporting.com</a> for a refund.
  </div>
  <div class="mt-4"
       [hidden]="value.type !== businessSubscriptionTypes.IndividualRecurring && value.type !== businessSubscriptionTypes.IndividualOneTime">
    <div #checkoutContainerOneTime id="checkout-one-time">
      <!-- Checkout will insert the payment form here -->
    </div>
    <div #checkoutContainerRecurring id="checkout-recurring">
      <!-- Checkout will insert the payment form here -->
    </div>
  </div>
  <div [hidden]="value.type !== businessSubscriptionTypes.Enterprise">
    <div class="calendly-inline-widget" style="height:700px"></div>
  </div>
</form>
