import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { Router } from '@angular/router';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { BusinessSubscriptionModel } from 'src/app/shared/models/domain/business-subscription.model';
import { BusinessSubscriptionService } from 'src/app/core/services/domain/business-subscription.service';

@Component({
  selector: 'app-business-subscriptions',
  templateUrl: './businesses-subscriptions-page.component.html',
  styleUrls: ['./businesses-subscriptions-page.component.scss']
})
export class BusinessesSubscriptionsPageComponent extends BasePageComponent implements OnInit {
  constructor(private router: Router, private dialogService: DialogService, private businessSubscriptionService: BusinessSubscriptionService) {
    super();
  }

  ngOnInit(): void {
  }

  onRowClicked(event: {data: BusinessSubscriptionModel}): void {
    this.openDialog(event.data.businessSubscriptionKey);
  }

  onAddButtonClicked(): void {
    this.openDialog();
  }

  private openDialog(businessSubscriptionKey: string = null): void {
    let matDialogRef = this.dialogService.openBusinessSubscription(businessSubscriptionKey);
    matDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reloadPage(this.router);
      }
    });
  }
}
