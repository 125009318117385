<div [innerHtml]="dialogData?.text ?? 'Are you sure you want to perform this action?'">
</div>
<div class="mt-2">
  <span class="mr-2">
    <button color="primary" mat-flat-button color="primary" (click)="onOkClicked()">{{dialogData?.okText ?? 'Ok'}}</button>
  </span>
  <span class="mr-2" *ngIf="dialogData?.extraButtonText">
    <button color="primary" mat-flat-button color="primary" (click)="onExtraButtonClicked()">{{dialogData?.extraButtonText}}</button>
  </span>
  <span>
    <button mat-flat-button color="primary" (click)="onCancelClicked()">{{dialogData?.cancelText ?? 'Cancel'}}</button>
  </span>
</div>
