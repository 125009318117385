import { Component, Input } from '@angular/core';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { Observable, of } from 'rxjs';
import { ClientToBusinessAccessModel } from 'src/app/shared/models/domain/client-to-business-access.model';
import { ClientToBusinessAccessService } from 'src/app/core/services/domain/client-to-business-access.service';
import { ClientToBusinessAccessSearchModel } from 'src/app/shared/models/domain/client-to-business-access-search.model';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { DialogService } from 'src/app/core/services/domain/dialog.service';

@Component({
  selector: 'app-business-access-grid',
  templateUrl: './business-access-grid.component.html',
  styleUrls: ['./business-access-grid.component.scss']
})
export class BusinessAccessGridComponent extends BaseGridComponent<ClientToBusinessAccessModel> {
  @Input() isAdmin: boolean = false;
  @Input() businessKey: string;

  constructor(public authClientService: AuthClientService,
              private dialogService: DialogService,
              private clientToBusinessAccessService: ClientToBusinessAccessService,
              private notificationService: NotificationService) {
    super();
    this.gridKey = 'BusinessAccess_v3.1';
  }

  onAddClicked(email: string = null, lastName: string = null, firstName: string = null): void {
    let matDialogRef = this.dialogService.openAddAccessDialog(this.businessKey, email, lastName, firstName);

    matDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshGridData().subscribe();
      }
    });
  }

  protected setColumnDefinitions(): ColDef[] {
    return [
      {
        headerName: '',
        field: this.actionFieldName,
        width: 100,
        cellClass: this.preventRowClickClass,
        cellRenderer: params => {
          const rowData = <ClientToBusinessAccessModel>params.data;

          if (this.authClientService.client.clientKey.toLowerCase() !== rowData.clientKey.toLowerCase()) {
            let html = '<i title="Invite" class="mr-1 cursor-pointer email-reminder fas fa-envelope prevent-row-click"></i>';

            if (rowData.isDeleted) {
              html += '<i title="Add access" class="cursor-pointer fas fa-user-plus prevent-row-click"></i>';
            } else {
              html += '<i title="Remove access" class="cursor-pointer fas fa-user-slash prevent-row-click"></i>';
            }

            return html;
          }

          return '';
        }
      },
      {
        headerName: 'Last Name',
        field: 'clientReference.lastName'
      },
      {
        headerName: 'First Name',
        field: 'clientReference.firstName'
      },
      {
        headerName: 'Email',
        field: 'clientReference.email'
      },
      this.getCreatedByColumn(),
      this.getCreatedByColumn(),
      this.getLastModifiedByColumn(),
      this.getLastModifiedOnColumn(),
    ];
  }

  protected onGridReady(): void {
    this.subscriptions.add(this.cellClick.subscribe((data: {data: ClientToBusinessAccessModel, event: CellClickedEvent }) => {
      if (data.event.colDef.field === this.actionFieldName) {
        if (this.hasCellClass(data.event, 'email-reminder')) {
          this.onAddClicked(data.data.clientReference.email, data.data.clientReference.lastName, data.data.clientReference.firstName);
        } else if (this.hasCellClass(data.event, 'fa-user-plus') || this.hasCellClass(data.event, 'fa-user-slash')) {
          let confirmDialog = this.dialogService.openConfirmationDialog("You about to change access.<br>Do you want to continue?", null, null, '150px');

          confirmDialog.afterClosed().subscribe(result => {
            if (result) {
              if (data.data.isDeleted) {
                this.clientToBusinessAccessService.create(<ClientToBusinessAccessModel>{clientKey: data.data.clientKey, businessKey: this.businessKey}).subscribe(result => {
                  this.notificationService.showSuccessNotification('Access updated and invite sent successfully.');
                  this.refreshGridData().subscribe();
                }, err => {
                  this.notificationService.showErrorNotification('Access failed to be updated.');
                });
              } else {
                this.clientToBusinessAccessService.delete(data.data.clientToBusinessAccessKey).subscribe(_ => {
                  this.notificationService.showSuccessNotification('Access removed successfully.');
                  this.refreshGridData().subscribe();
                }, err => {
                  this.notificationService.showErrorNotification('Access failed to be removed.');
                });
              }
            }
          });
        }
      }
    }));
  }

  protected setRowData(): Observable<ClientToBusinessAccessModel[]> {
    return this.clientToBusinessAccessService.search(<ClientToBusinessAccessSearchModel>{businessKey: this.businessKey, includeDeleted: true});
  }
}
