import { Component, OnInit } from '@angular/core';
import { ReportModel } from 'src/app/shared/models/domain/report.model';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { ReportStatusesEnum } from 'src/app/shared/enums/domain/report-statuses-enum';
import { RoutingKeysUtilities } from 'src/app/routing/routing-keys.utilities';

@Component({
  selector: 'app-reports-page',
  templateUrl: './reports-page.component.html',
  styleUrls: ['./reports-page.component.scss']
})
export class ReportsPageComponent extends BasePageComponent implements OnInit {
  reports: ReportModel[] = [];
  status: ReportStatusesEnum = null;
  businessKey: string = null
  isAdmin: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private businessContextService: BusinessContextService) {
    super();
  }

  ngOnInit(): void {
    let status = this.getQueryParameterFromRoute(RoutingKeysUtilities.queryParams.statusId, this.activatedRoute.snapshot);
    if (status !== null) {
      this.status = parseInt(status, 10);
    }


    this.isAdmin = this.isAdminRoute();
    this.businessKey = this.isAdmin ? null : this.businessContextService.currentBusiness.businessKey;
  }

  onAddButtonClicked(): void {
    this.router.navigate([RouteUtilities.routes.application.reportAdd.getNavigateUrl(this.businessKey)]);
  }

  onRowClicked(event: any): void {
    this.router.navigate([RouteUtilities.routes.application.reportEdit.getNavigateUrl(event.data.reportKey)]);
  }
}
