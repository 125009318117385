import { Component, OnInit } from '@angular/core';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { RoutingKeysUtilities } from 'src/app/routing/routing-keys.utilities';
import { ReportModel } from 'src/app/shared/models/domain/report.model';
import { ReportService } from 'src/app/core/services/domain/report.service';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { RoutingService } from 'src/app/core/services/app/routing.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { ReportTabIndexesEnum } from 'src/app/shared/components/forms/report-form/report-form.component';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { BusinessService } from 'src/app/core/services/domain/business.service';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { CountryService } from 'src/app/core/services/domain/country.service';
import { TribalService } from 'src/app/core/services/domain/tribal.service';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { ReportStatusesEnum } from 'src/app/shared/enums/domain/report-statuses-enum';
import { Location } from '@angular/common';
import { BaseReportEntryPageComponent } from 'src/app/pages/shared/base/base-report-entry-page.component';
import { BusinessSubscriptionService } from 'src/app/core/services/domain/business-subscription.service';

@Component({
  selector: 'app-report-page',
  templateUrl: './report-page.component.html',
  styleUrls: ['./report-page.component.scss']
})
export class ReportPageComponent extends BaseReportEntryPageComponent<ReportModel> implements OnInit {
  hasValidSubscription = false;
  saveText: string = 'Next';
  reportTabIndexes = ReportTabIndexesEnum;
  isAdmin = false;

  statuses = ReportStatusesEnum;
  status: ReportStatusesEnum = ReportStatusesEnum.Draft;

  private reportKey: string;
  private businessKey: string;

  constructor(
    public authClientService: AuthClientService,
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private businessService: BusinessService,
    private businessSubscriptionService: BusinessSubscriptionService,
    private dialogService: DialogService,
    private reportService: ReportService,
    private countryService: CountryService,
    private tribalService: TribalService,
    private businessContextService: BusinessContextService,
    routingService: RoutingService,
    notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    this.reportKey = this.getParameterFromRoute(RoutingKeysUtilities.routeParams.reportKey, this.activatedRoute.snapshot);
    this.businessKey = this.getQueryParameterFromRoute(RoutingKeysUtilities.routeParams.businessKey, this.activatedRoute.snapshot) ?? this.businessContextService.currentBusiness.businessKey;
    this.selectedTabIndex = +this.getQueryParameterFromRoute(this.routingKeysUtilities.queryParams.selectedTabIndex, this.activatedRoute.snapshot) || 0;
    this.isAdmin = this.authClientService.isAdmin;

    this.countryService.list().pipe(mergeMap(_ => {
      return this.tribalService.list();
    })).subscribe(_ => {
      if (this.reportKey) {
        this.reportService.getReport(this.reportKey).subscribe(report => {
          if (!report) {
            this.notificationService.showErrorNotification('Unable to find report.');
            this.router.navigateByUrl(RouteUtilities.routes.application.reports.getNavigateUrl());
          } else {
            this.value = report;
            this.status = report.status;

            if (this.value.business.businessKey !== this.businessContextService.currentBusiness.businessKey) {
              let foundBusiness = this.businessContextService.allBusinesses.find(t => t.businessKey === this.value.business.businessKey) ??
                this.businessContextService.allBusinesses.find(t => t.parentBusinessKey === this.value.business.businessKey);

              if (foundBusiness) {
                this.businessContextService.updateBusinessContext(foundBusiness);
              }
            }
          }
        });
      } else {
        this.businessSubscriptionService.canCreateReport(this.businessKey).subscribe(result => {
          if (result) {
            this.businessService.get(this.businessKey).subscribe(business => {
              if (business) {
                this.value = <ReportModel>{
                  business: {
                    businessKey: business.businessKey,
                    legalName: business.legalName
                  },
                  reportNotification: business.defaultReportNotification
                };

                //DISABLE UNTIL THEY TURN ON
                this.value.reportNotification.isDisabled = true;
              }
            });
          } else {
            this.notificationService.showErrorNotification('Purchase a license to create a new report.');
            this.router.navigateByUrl(this.routeUtilities.routes.application.subscriptions.getNavigateUrl());
          }
        }, err => {
          this.onHttpFailure(this.notificationService, err);
        });
      }
    });
  }

  onSaveClicked(isSubmit: boolean = false): void {
    if (isSubmit && !this.isAdmin) {
      let dialogRef = this.dialogService.openConfirmationDialog('<b>I certify that the information contained is true, correct, and complete.</b><br/><br/><br/><b>COMPLIANCE REMINDER: </b>The willful failure to report complete beneficial ownership information to FinCEN, the willful failure to update beneficial ownership information provided to FinCEN when previously reported information changes, or the willful provision of false or fraudulent beneficial ownership information to FinCEN, may result in civil or criminal penalties. A person may also be subject to civil or criminal penalties for willfully causing a reporting company to report incomplete or false beneficial ownership information to FinCEN.', 'Submit', null, '400px', '450px');
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.onExecuteSave(isSubmit);
        }
      });
    } else {
      this.onExecuteSave(isSubmit);
    }
  }

  onDeleteClicked(): void {
    let dialogRef = this.dialogService.openConfirmationDialog('Are you sure you want to continue?');
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reportService.deleteReport(this.value.reportKey).subscribe(_ => {
          this.notificationService.showSuccessNotification('Report was deleted successfully');
          this.router.navigateByUrl(RouteUtilities.routes.application.reports.getNavigateUrl());
        }, err => {
          this.onHttpFailure(this.notificationService, err);
        });
      }
    });
  }

  onExecuteSave(isSubmit: boolean): void {
    this.saveForm(isSubmit).subscribe(result => {
      if (result.result) {
        this.onSaveSuccess(`Report was ${result.isUpdate ? 'updated' : 'created'} successfully.`);
        if (isSubmit) {
          this.redirectToReportsPage();
        }
      }
    });
  }

  onCancelClicked(): void {
    this.redirectToReportsPage();
  }

  saveForm(isSubmit: boolean): Observable<{ result: boolean, isUpdate: boolean}> {
    this.isSaving = true;
    let isUpdate = true;

    if (isSubmit) {
      this.value.status = ReportStatusesEnum.Submitted;
    } else {
      this.value.status = this.formGroupRef.get('status').value;
    }

    if (!this.value.reportKey) {
      isUpdate = false;
      return this.reportService.createReport(this.value, this.fileList).pipe(map(id => {
        this.formGroupRef.get('reportKey').setValue(id);
        this.postSave();

        this.location.replaceState(RouteUtilities.routes.application.reportEdit.getNavigateUrl(id));

        return {result: true, isUpdate: isUpdate};
      }), catchError(err => {
        this.onSaveFailed(err);
        return of({result: false, isUpdate: isUpdate});
      }));
    } else {
      return this.reportService.updateReport(this.value, this.fileList).pipe(map(_ => {
        this.postSave();

        return {result: true, isUpdate: isUpdate};
      }), catchError(err => {
        this.onSaveFailed(err);
        return of({result: false, isUpdate: isUpdate});
      }));
    }
  }

  private postSave(): void {
    this.formGroupRef.markAsPristine();
    this.formGroupRef.updateValueAndValidity();
    this.fileList = null;

    this.formGroupRef.get('hasUploadedFile').setValue(false);

    let companyApplicantsFormArray = this.formGroupRef.get('companyApplicants') as UntypedFormArray;
    for (let companyApplicantsFormGroup of companyApplicantsFormArray.controls) {
      companyApplicantsFormGroup = companyApplicantsFormGroup as UntypedFormGroup;
      let companyApplicantsIdentificationFormGroup = companyApplicantsFormGroup.get('identification') as UntypedFormGroup;
      companyApplicantsIdentificationFormGroup.get('hasUploadedFile').setValue( false);
    }

    let beneficialOwnerFormArray = this.formGroupRef.get('beneficialOwners') as UntypedFormArray;
    for (let beneficialOwnerFormGroup of beneficialOwnerFormArray.controls) {
      beneficialOwnerFormGroup = beneficialOwnerFormGroup as UntypedFormGroup;
      let companyApplicantsIdentificationFormGroup = beneficialOwnerFormGroup.get('identification') as UntypedFormGroup;
      companyApplicantsIdentificationFormGroup.get('hasUploadedFile').setValue( false);
    }

    this.onSaveSuccess();
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  private redirectToReportsPage() {
    if (this.isAdminRoute()) {
      this.router.navigateByUrl(RouteUtilities.routes.application.admin.reports.getNavigateUrl());
    } else {
      this.router.navigateByUrl(RouteUtilities.routes.application.reports.getNavigateUrl());
    }
  }

  protected readonly ReportStatusesEnum = ReportStatusesEnum;
}

