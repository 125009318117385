<form [formGroup]="formGroupRef">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <div class="flex mt-2 mb-2">
          <div class="flex flex-shrink" style="margin-top:2px">
            <div class="flex flex-shrink" style="margin-top: -2px;">
              <mat-checkbox [disabled]="readonly"
                            formControlName="isDisabled">
              </mat-checkbox>
            </div>
            <div class="flex flex-shrink ml-1">
              Disable Notifications
            </div>
          </div>
          <div class="flex flex-shrink">
            <mat-icon matSuffix
                      matTooltip="Automatic notifications to be sent to identified company applicants and beneficial owners. If you wish to send manually, you can send from the individuals view.">
              help_outline
            </mat-icon>
          </div>
        </div>

        <div [hidden]="value.isDisabled">
          <div class="mt-2">
            <app-datepicker [readonly]="readonly" formControlName="startDate" label="Start Date" helpText="Date to start sending notifications.  Leave blank to send indefinitely."></app-datepicker>
            <app-datepicker [readonly]="readonly" formControlName="endDate" label="End Date" helpText="Date to stop sending notifications.  Leave blank to send indefinitely."></app-datepicker>
          </div>
          <div class="mt-2">
            <mat-form-field class="mat-form-field">
              <mat-label>BCC Email</mat-label>
              <input [readonly]="readonly"
                     matInput formControlName="copyToEmail">
              <mat-icon matSuffix matTooltip="Email address to BCC to.">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="mt-2">
            <mat-form-field class="mat-form-field">
              <mat-label>Days Between Notifications</mat-label>
              <input [readonly]="readonly"
                     matInput type="text" currencyMask formControlName="delayInDays"
                     [options]="{ prefix: '', precision: '0'}" min="0">
              <mat-icon matSuffix matTooltip="The number of days to wait between sending notifications.">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="mt-2">
            <mat-form-field class="mat-form-field">
              <mat-label>Max Notifications</mat-label>
              <input [readonly]="readonly"
                     matInput type="text" currencyMask formControlName="maxNotifications"
                     [options]="{ prefix: '', precision: '0'}" min="0">
              <mat-icon matSuffix
                        matTooltip="The maximum number of notifications to send, once reached, no more notifications will be sent.  Leave blank for no limit.">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="mt-2">
            <mat-form-field class="mat-form-field-double">
              <mat-label>Custom Message</mat-label>
              <textarea
                [readonly]="readonly"
                #autosize="cdkTextareaAutosize"
                matInput
                cdkTextareaAutosize
                cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="5"
                formControlName="message">
              </textarea>
              <mat-icon matSuffix matTooltip="A tailored message to be added to the notification email.">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
