import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '../../base/base.component';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { ReportStatusesEnum } from 'src/app/shared/enums/domain/report-statuses-enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reports-grid-dialog',
  templateUrl: './reports-grid-dialog.component.html',
  styleUrls: ['./reports-grid-dialog.component.scss']
})
export class ReportsGridDialogComponent extends BaseComponent {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<ReportsGridDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: {businessKey: string, status: ReportStatusesEnum}) {
    super();
  }

  onRowClicked(event: any): void {
    this.dialogRef.close();
    this.router.navigate([RouteUtilities.routes.application.reportEdit.getNavigateUrl(event.data.reportKey)]);
  }

  onCancelClicked(): void {
    this.dialogRef.close(false);
  }
}
