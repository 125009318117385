export class RoutingKeysUtilities {
  //NOTE NEEDS TO STAY IN SYNC WITH ROUTE.UTILITIES.TS
  public static routeParams = {
    businessKey: 'businessKey',
    reportKey: 'reportKey',
    businessServiceRequestKey: 'businessServiceRequestKey',
    clientKey: 'clientKey',
    reportNotificationToken: 'report-notification-token'
  }

  public static queryParams = {
    statusId: 'statusId',
    businessKey: 'businessKey',
    selectedTabIndex: 'selected-tab-index',
  }
}

