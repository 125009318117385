import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BusinessSubscriptionModel } from 'src/app/shared/models/domain/business-subscription.model';
import { EnumModel } from 'src/app/shared/models/app/enum.model';
import { EnumUtilities } from 'src/app/core/utilities/app/enum.utilities';
import { BusinessSubscriptionTypesEnum } from 'src/app/shared/enums/domain/business-subscription-types.enum';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { StringUtilities } from 'src/app/core/utilities/app/string.utilities';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';
import { tap } from 'rxjs/operators';
import { BusinessService } from 'src/app/core/services/domain/business.service';

@Component({
  selector: 'app-business-subscription-form',
  templateUrl: './business-subscription-form.component.html',
  styleUrls: ['./business-subscription-form.component.scss']
})
export class BusinessSubscriptionFormComponent extends BaseFormComponent<BusinessSubscriptionModel> implements OnInit {
  private businessTrigger: MatAutocompleteTrigger;
  businessName: string;

  @ViewChild('customerInput', {read: MatAutocompleteTrigger}) set customerContent(content: MatAutocompleteTrigger) {
    if (content && !this.businessTrigger) {
      this.businessTrigger = content;
      this.subscriptions.add(this.businessTrigger.panelClosingActions.subscribe(e => {
        let textValue = this.value.business.businessKey;

        //a text value is already selected don't take the text, in this case the value is a guid.
        if (StringUtilities.isUUID(textValue)) {
          textValue = null;
        }

        if (!(e && e.source)) {
          this.businessKeyControl.setValue(null);
          this.businessTrigger.closePanel();
        } else {
          let customer = this.businesses.find(t => t.businessKey === e.source.value);

          if (!customer) {
            this.businessKeyControl.setValue(null);
            this.businessTrigger.closePanel();
          } else {
            this.businessKeyControl.setValue(e.source.value);
          }
        }
      }));
    }
  }

  businesses: BusinessModel[];
  filteredBusinesses: BusinessModel[] = [];
  businessKeyControl: UntypedFormControl;
  businessSubscriptionTypes: EnumModel[] = [];


  constructor(private businessService: BusinessService) {
    super()
  }

  ngOnInit(): void {
    this.businessSubscriptionTypes = EnumUtilities.convertToSelectModels(BusinessSubscriptionTypesEnum, true);
    this.businessName = this.value?.business?.name;

    if (!this.value.businessSubscriptionKey) {
      this.businessService.search().subscribe(businesses => {
        this.businesses = businesses;
        this.filteredBusinesses = businesses;
      })
    }

    super.ngOnInit();

    this.subscriptions.add(this.businessKeyControl.valueChanges.pipe(
      tap((value: string) => {
        if (!value) {
          this.filteredBusinesses = this.businesses;
        } else {
          this.filteredBusinesses = this.businesses.filter(business => business.name.toLowerCase()
            .indexOf(value.toLowerCase()) !== -1);
        }
      }),
    ).subscribe());
  }

  businessDisplayFn(businessKey: string): string {
    return this.businesses.find(t => t.businessKey === businessKey)?.name ?? '';
  }

  protected getFormGroup(): UntypedFormGroup {
    this.businessKeyControl = new UntypedFormControl(this.value?.business?.businessKey);

    const businessFormGroup = new UntypedFormGroup({
      businessKey: this.businessKeyControl
    });

    return new UntypedFormGroup({
      businessSubscriptionKey: new UntypedFormControl(this.value?.businessSubscriptionKey),
      business: !this.value.businessSubscriptionKey ? businessFormGroup : new UntypedFormControl(this.value.business),
      type: new UntypedFormControl(this.value?.type ?? BusinessSubscriptionTypesEnum.Enterprise),
      isDeleted: new UntypedFormControl(this.value?.isDeleted ?? false),
    })
  };

}
