import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DashboardModel } from 'src/app/shared/models/domain/dashboard.model';

@Injectable()
export class DashboardService {
  private url = environment.apiUrl + '/dashboards/';

  constructor(private http: HttpClient) {
  }

  get(businessKey: string): Observable<DashboardModel> {
    return this.http.get<DashboardModel>(`${this.url}${businessKey ?? ''}`);
  }
}
