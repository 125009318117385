import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ReportNotificationToSendModel } from 'src/app/shared/models/domain/report-notification-to-send.model';
import { ReportNotificationService } from 'src/app/core/services/domain/report-notification.service';
import { ReportModel } from 'src/app/shared/models/domain/report.model';
import { ReportBeneficialOwnerModel } from 'src/app/shared/models/domain/report-beneficial-owner.model';
import { ReportCompanyApplicantModel } from 'src/app/shared/models/domain/report-company-applicant.model';
import { ReportIndividualModel } from 'src/app/shared/models/domain/report-individual.model';

@Component({
  selector: 'app-text-area-dialog',
  templateUrl: './send-notification-dialog.component.html',
  styleUrls: ['./send-notification-dialog.component.scss']
})
export class SendNotificationDialogComponent extends BaseComponent implements OnInit {
  message: string;
  copyToEmail: string;
  formGroupRef: UntypedFormGroup;
  isSending: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<SendNotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { report: ReportModel, reportIndividual: ReportIndividualModel, reportBeneficialOwner: ReportBeneficialOwnerModel, reportCompanyApplicant: ReportCompanyApplicantModel },
    private reportNotificationService: ReportNotificationService,
    protected notificationService: NotificationService) {
    super();
  }

  ngOnInit(): void {
    this.formGroupRef = new UntypedFormGroup({
      message: new UntypedFormControl(null),
      copyToEmail: new UntypedFormControl(null)
    })
  }

  onSendClicked(): void {
    this.isSending = true;
    let model: ReportNotificationToSendModel = {
      report: this.dialogData.report,
      reportBeneficialOwner: this.dialogData.reportBeneficialOwner,
      reportCompanyApplicant: this.dialogData.reportCompanyApplicant,
      copyToEmail: this.formGroupRef.value.copyToEmail,
      message: this.formGroupRef.value.message
    }

    this.reportNotificationService.create(model).subscribe(_ => {
      this.notificationService.showSuccessNotification('Reminder has been sent successfully.');
      this.dialogRef.close(this.formGroupRef.value);
      this.isSending = false;
    }, err => {
      this.isSending = false;
      this.onHttpFailure(this.notificationService, err);
    });
  }

  onCancelClicked(): void {
    this.dialogRef.close(null);
  }
}
