import { Component, Input } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';

@Component({
  selector: 'app-loader',
  template: `
    <div>
      <mat-spinner class="app-loader" [diameter]="50" color="primary"></mat-spinner>
    </div>
    <div style="text-align: center; font-size: larger; font-weight: bold">
        {{text}}
    </div>
    `,
  styles: ['.app-loader {margin:40px;}']
})
export class LoaderComponent extends BaseComponent {
  @Input() text: string = null;

  constructor() {
    super();
  }
}
