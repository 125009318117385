import {
  Component,
  EventEmitter,
  HostListener, Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  GridApi,
  ColumnApi,
  RowClickedEvent,
  CellClickedEvent,
  ValueGetterParams,
  RowNode,
  ICellRendererParams,
  RowClassParams,
  RowStyle,
  CellStyle,
  ITooltipParams,
  ProcessCellForExportParams
} from 'ag-grid-community';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { Observable, Subject } from 'rxjs';
import { debounceTime, first, takeWhile, tap } from 'rxjs/operators';
import { CellClassParams, ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { EnumUtilities } from 'src/app/core/utilities/app/enum.utilities';
import { NumberUtilities } from 'src/app/core/utilities/app/number.utilities';
import { DateUtilities } from 'src/app/core/utilities/app/date.utilities';
import { ObjectUtilities } from 'src/app/core/utilities/app/object.utilities';
import { LocalStorageUtilites } from 'src/app/core/utilities/app/local-storage.utilites';
import {RouteUtilities} from "../../../routing/route.utilities";

@Component({
  template: ''
})
export abstract class BaseGridComponent<T> extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() enableRowClick: boolean = false;
  @Input() gridKey: string = null;
  @Input() columnsToHide: string[] = null;
  @Input() includeDeleted: boolean = false;
  @Input() processExportCallback: (params: ProcessCellForExportParams) => string;
  @Input() getRowClassFunc: (params: RowClassParams) => string[];

  @Output() addButtonClick = new EventEmitter();
  @Output() rowClick = new EventEmitter<{ data: T, event: RowClickedEvent }>();
  @Output() cellClick = new EventEmitter<{ data: T, event: CellClickedEvent }>();
  @Output() dataChange = new EventEmitter<T[]>();
  @Output() dataLoaded = new EventEmitter<T[]>();

  @Input() selectedRows: T[] = [];
  @Output() selectedRowsChange = new EventEmitter<T[]>();

  public routeUtilities = RouteUtilities;

  private selectionChangeTimeout = null;

  readonly actionFieldName = 'action';
  columnDefs: ColDef[] = null;
  rowData: T[];
  filteredRowData: T[];
  gridOptions: GridOptions = {};
  gridApi: GridApi;
  columnApi: ColumnApi;
  domLayout: string = 'autoHeight';

  private readonly gridStateEvents: string[] = [
    'filterChanged',
    'sortChanged',
    'columnVisible',
    'columnMoved',
    'gridColumnsChanged',
    'displayedColumnsChanged',
    'columnResized',
    'expandOrCollapseAll'
  ];
  private readonly gridStateThrottle = 1000;
  private $gridStateSubject: Subject<any> = new Subject<any>();

  readonly preventRowClickClass = 'prevent-row-click'

  ngOnInit(): void {
    this.initGridOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes.selectedRows && changes.selectedRows.isFirstChange()) {
    //
    // }
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.gridApi = null;
    this.gridOptions = null;
    this.columnApi = null;
  }

  @HostListener('window:resize')
  onResize() {
    this.autoSizeAll();
  }

  onAddButtonClicked(): void {
    this.addButtonClick.emit();
  }

  onShowDeletedToggled($event): void {
    this.includeDeleted = $event;
    this.refreshGridData().subscribe();
  }

  onFilterTextChanged($event: string): void {
    if (this.gridApi) {
      this.gridApi.setQuickFilter($event);
    }
  }

  onExportGridClicked(): void {
    let that = this;
    if (this.gridOptions) {
      this.gridApi.exportDataAsCsv({
        processCellCallback(params: ProcessCellForExportParams): string {
          if (that.processExportCallback) {
            return that.processExportCallback(params);
          }

          return params.value;
        }
      });
    }
  }

  onResetGridClicked(): void {
    if (this.gridApi) {
      this.columnApi?.resetColumnState();
      this.gridApi?.setFilterModel({});
      this.gridApi.setQuickFilter('');
      this.gridApi.setFilterModel(null);
      this.gridApi.setSortModel(null);
      this.gridApi.onFilterChanged();
      this.autoSizeAll();
    }
  }

  protected initGridOptions(): void {
    this.columnDefs = this.columnDefs ?? this.setColumnDefinitions() ?? [];

    if (this.columnsToHide?.length) {
      this.columnDefs = this.columnDefs.filter(t => this.columnsToHide.indexOf(t.field) === -1);
    }

    this.gridOptions = this.setBaseGridOptions();
  }

  protected refreshGridData(): Observable<any[]> {
    const that = this;
    let timeout = setTimeout(_ => {
      this.gridApi.showLoadingOverlay();
    }, 1500);

    return this.setRowData().pipe(first(), tap(data => {
      if (that.gridApi) {
        that.gridApi.setRowData(data || []);
        that.autoSizeAll(!!data.length);
        this.dataChange.emit(data);
        clearTimeout(timeout);
        that.gridApi.hideOverlay();
      }
    }));
  }

  protected getDefaultRowStyle(params: RowClassParams): RowStyle {
    let styles = {};

    // if (params.node.rowIndex % 2 == 0) {
    //   styles['background'] = 'red';
    // }

    return styles;
  }

  protected getDefaultRowClasses(params: RowClassParams): string[] {
      let classes = [];

      if (this.isRowDeleted(params.node.data)) {
        classes.push('ag-row-deleted-background');
      }

      return classes;
  }

  protected isRowDeleted(row: T): boolean {
    return row['isDeleted'];
  }

  protected abstract setRowData(): Observable<any[]>;

  protected abstract setColumnDefinitions(): ColDef[];

  protected abstract onGridReady(gridApi: GridApi): void;

  private setBaseGridOptions(): GridOptions {
    const that = this;

    const gridOptions = <GridOptions>{
      rowData: this.rowData ?? null,
      columnDefs: this.columnDefs,
      //domLayout: this.domLayout,
      enableBrowserTooltips: true,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      // pagination: true,
      // paginationPageSize: 20,
      getRowClass: function(params: RowClassParams) {
        let rowClasses = that.getDefaultRowClasses(params);

        if (that.getRowClassFunc) {
          rowClasses = rowClasses.concat(that.getRowClassFunc(params));
        }

        return rowClasses.join(' ');
      },
      getRowStyle: function(params: RowClassParams) {
        return that.getDefaultRowStyle(params);
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
        cellDataType: false,
        width: 150,
        enableTooltip : true,
        tooltipValueGetter: (params) => {
          return params.value;
        },
        comparator: (valueA, valueB) => {
          if (typeof valueA === 'string' && valueA) {
            return valueA.toLowerCase().localeCompare(valueB?.toLowerCase());
          }

          return (valueA > valueB? 1 : (valueA < valueB ? -1 : 0));
        },
        cellStyle: function(params: CellClassParams): CellStyle {
          if (that.rowClick.observers.length || (typeof(params.colDef.cellClass) != 'function' && params.colDef.cellClass && params.colDef.cellClass.indexOf(that.preventRowClickClass) > -1)) {
            return {'cursor': 'pointer'};
          }

          return null;
        }
      },
      onSelectionChanged(): void {
        if (this.selectionChangeTimeout) {
          clearTimeout(this.selectionChangeTimeout);
          this.selectionChangeTimeout = null;
        }

        this.selectionChangeTimeout = setTimeout(() => {
          let rows = [];
          if (that.gridApi) {
            that.gridApi.forEachNodeAfterFilter(node => {
              if (node.isSelected()) {
                rows.push(node.data);
              }
            });
          }

          that.selectedRows = rows;
          that.selectedRowsChange.emit(rows);
        }, 250);
      },
      onFilterChanged(): void {
        that.filteredRowData = [];

        that.gridApi.forEachNodeAfterFilter((rowNode: RowNode, index: number) => {
          that.filteredRowData.push(rowNode.data);
        });

        that.dataChange.emit(that.filteredRowData);
      },
      onGridReady(event: GridReadyEvent) {
        that.gridApi = event.api;
        that.columnApi = event.columnApi;

        that.setRowData().pipe(first()).subscribe(data => {
          if (that.gridApi) {
            that.rowData = data;
            that.filteredRowData = data;
            that.gridApi.setRowData(data || []);

            that.onGridReady(that.gridApi);
            that.autoSizeAll(!!data.length);

            that.dataLoaded.emit(data);

            that.initGridState();

            if (that.selectedRows?.length) {
              that.gridApi.forEachNodeAfterFilter(node => {
                if (that.selectedRows.indexOf(node.data) !== -1) {
                  node.setSelected(true);
                }
              });
            }
          }
        })
      },
      onCellClicked(event: CellClickedEvent) {
        if (event.colDef.cellClass && (<string>event.colDef.cellClass)?.indexOf(that.preventRowClickClass) !== -1) {
          //DISABLE CHECKBOX SELECTION FOR COLUMN CLICKS
          that.cellClick.emit({data: event.data, event: event});
        } else {
          that.rowClick.emit({data: event.data, event: event});
        }
      }
    }

    return gridOptions;
  }

  protected getDefaultColumn(fieldName: string, headerName: string, hide: boolean = false, minWidth: number = 150): ColDef {
    return <ColDef>{
      headerName: headerName,
      field: fieldName,
      hide: hide,
      width: minWidth
    }
  }

  protected getYesNoColumn(fieldName: string, headerName: string, hide: boolean = false, invert: boolean = false): ColDef {
    return <ColDef>{
      headerName: headerName,
      field: fieldName,
      hide: hide,
      width: 100,
      minWidth: 100,
      valueGetter: (params: ValueGetterParams) => {
        const rowData = params.data;
        const value = <number>ObjectUtilities.getValueOfProperty(rowData, fieldName);
        return value? 'Yes' : 'No';
      },
      getQuickFilterText: params => {
        const rowData = <T>params.data;
        const value = <number>ObjectUtilities.getValueOfProperty(rowData, fieldName);

        if (invert) {
          return value ? 'No' : 'Yes';
        }

        return value ? 'Yes' : 'No';
      },
    };
  }

  protected getCreatedByColumn(fieldName: string = 'createdByClient.fullName'): ColDef {
    let that = this;

    return <ColDef>{
      headerName: 'Created By',
      field: fieldName,
    };
  }

  protected getCreatedOnColumn(filedName: string = 'createdDateTime'): ColDef {
    return this.getDateColumn(filedName, 'Created On', false, true);
  }

  protected getLastModifiedByColumn(fieldName: string = 'lastModifiedByClient.fullName'): ColDef {
    let that = this;

    return <ColDef>{
      headerName: 'Last Modified By',
      field: fieldName,
    };
  }

  protected getLastModifiedOnColumn(filedName: string = 'lastModifiedDateTime'): ColDef {
    return this.getDateColumn(filedName, 'Last Modified On', false, true);
  }

  protected getEnumColumn(fieldName: string, headerName: string, enumReference: any, hide: boolean = false): ColDef {
    let that = this;

    return <ColDef>{
      headerName: headerName,
      field: fieldName,
      hide: hide,
      valueGetter: (params: ValueGetterParams) => {
        const rowData = <T>params.data;
        const value = <number>ObjectUtilities.getValueOfProperty(rowData, fieldName);

        return that.isRowDeleted(rowData) ? 'Deleted' : EnumUtilities.getDisplayName(enumReference, value);
      },
      getQuickFilterText: params => {
        const rowData = <T>params.data;
        const value = <number>ObjectUtilities.getValueOfProperty(rowData, fieldName);

        return EnumUtilities.getDisplayName(enumReference, value);
      },
      comparator: (valueA, valueB) => {
        const valueAText = EnumUtilities.getDisplayName(enumReference, valueA);
        const valueBText = EnumUtilities.getDisplayName(enumReference, valueB);

        if (valueAText == valueBText) {
          return 0;
        }

        return (valueAText > valueBText) ? 1 : -1;
      }
    };
  }

  protected getCheckboxColumn(showHeader: boolean = false) {
    return <ColDef>{
      headerName: '',
      field: 'placeholder',
      checkboxSelection: true,
      headerCheckboxSelection: showHeader,
      width: 50,
      minWidth: 50,
      maxWidth: 50
    };
  }

  protected getCurrencyColumn(fieldName: string, headerName: string, cellRenderer: (params: ICellRendererParams) => string = null, useAbsoluteValue: boolean = false, hide: boolean = false, noValueText: string = null): ColDef {
    return <ColDef>{
      headerName: headerName,
      field: fieldName,
      filter: 'agNumberColumnFilter',
      hide: hide,
      width: 115,
      minWidth: 115,
      //IN THIS CASE WE WANT IT TO FILTER BY AN UNFORMATTED NUMBER
      valueFormatter: (params: ValueFormatterParams) => {
        const rowData = <T>params.data;
        const value = <number>ObjectUtilities.getValueOfProperty(rowData, fieldName);

        if (!value && noValueText) {
          return noValueText;
        }

        if (useAbsoluteValue) {
          return NumberUtilities.formatAsCurrency(Math.abs(value));
        }

        return NumberUtilities.formatAsCurrency(value);
      },
      cellRenderer: cellRenderer
    };
  }

  protected getPercentageColumn(fieldName: string, headerName: string): ColDef {
    return <ColDef>{
      headerName: headerName,
      field: fieldName,
      filter: 'agNumberColumnFilter',
      cellRenderer: function(params: ICellRendererParams): string {
        const rowData = <T>params.data;
        const value = ObjectUtilities.getValueOfProperty(rowData, fieldName);

        if (value) {
          return `%${value}`;
        }

        return null;
      }
    };
  }

  protected getNumberColumn(fieldName: string, headerName: string, hide: boolean = false, width: number = null): ColDef {
    return <ColDef>{
      headerName: headerName,
      field: fieldName,
      filter: 'agNumberColumnFilter',
      hide: hide,
      width: width
    };
  }

  protected getLinkColumn(fieldName: string, headerName: string, linkTextFunc: (params: ICellRendererParams) => string = null, hide: boolean = false, disableLink: boolean = false): ColDef {
    return <ColDef>{
      headerName: headerName,
      field: fieldName,
      cellClass: this.preventRowClickClass,
      hide: hide,
      valueGetter: (params: ValueGetterParams) => {
        const rowData = <T>params.data;
        return <string>ObjectUtilities.getValueOfProperty(rowData, fieldName);
      },
      comparator: (valueA, valueB) => {
        if (typeof valueA === 'string' && valueA) {
          return valueA.toLowerCase().localeCompare(valueB?.toLowerCase());
        }

        return (valueA > valueB? 1 : (valueA < valueB ? -1 : 0));
      },
      cellRenderer: function(params: ICellRendererParams): string {
        const rowData = <T>params.data;
        const value = ObjectUtilities.getValueOfProperty(rowData, fieldName);
        let linkText = value;

        if (linkTextFunc) {
          linkText = linkTextFunc(params);
        }

        if (disableLink) {
          return <string>linkText;
        }

        if (linkText ?? (typeof(value) !== 'undefined' && value !== null)) {
          return `<a href="javascript:void(0)">${linkText}</a>`;
        }

        return '';
      }
    };
  }

  protected getDateColumn(fieldName: string, headerName: string, hide: boolean = false, showTime: boolean = false): ColDef {
    return <ColDef>{
      headerName: headerName,
      field: fieldName,
      filter: 'agDateColumnFilter',
      hide: hide,
      minWidth: 120,
      width: 120,
      valueGetter: (params: ValueGetterParams) => {
        const rowData = <T>params.data;
        const value = <string>ObjectUtilities.getValueOfProperty(rowData, fieldName);

        return DateUtilities.format(value, (showTime ? DateUtilities.defaultDateTimeFormat : DateUtilities.defaultDateFormat));
      },
      getQuickFilterText: params => {
        const rowData = <T>params.data;
        const value = <string>ObjectUtilities.getValueOfProperty(rowData, fieldName);
        return DateUtilities.format(value);
      },
      comparator: (valueA, valueB) => {
        const valueADate = DateUtilities.parseDate(valueA);
        const valueBDate = DateUtilities.parseDate(valueB);

        return DateUtilities.dateComparator(valueADate, valueBDate);
      },
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          const dateAsString = cellValue;

          if (dateAsString == null) {
            return 0;
          }

          const cellDate = DateUtilities.parseDate(cellValue);

          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        }
      }
    };
  }

  protected hasCellClass(event: CellClickedEvent, className: string): boolean {
    // @ts-ignore
    return event.event?.target?.parentElement?.classList?.contains(className) || false;
  }

  private autoSizeAll(skipHeader: boolean = false) {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }

    // const allColumnIds: string[] = [];
    // this.columnApi.getAllColumns()!.forEach((column) => {
    //   allColumnIds.push(column.getId());
    // });
    // this.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }

  private initGridState(): void {
    if (this.gridKey) {
      this.gridStateEvents.forEach((type) => {
        if (this.gridApi) {
          this.gridApi.addEventListener(type, () => {
            this.$gridStateSubject.next();
          });
        }
      });

      let gridSettings = LocalStorageUtilites.get(this.gridKey);

      if (gridSettings && this.columnApi) {
        //setTimeout(_ => {
          this.columnApi.applyColumnState({state: gridSettings.columnState, applyOrder: true});
          this.gridApi?.setFilterModel(gridSettings.filterState);
        //}, 2000);
      }

      this.subscriptions.add(this.$gridStateSubject.pipe(debounceTime(this.gridStateThrottle), takeWhile(() => {
        return (!!(this.gridOptions && this.gridOptions.columnApi && this.gridOptions.api));
      }), tap(() => {
        const gridState: any = {
          columnState: this.gridOptions?.columnApi?.getColumnState(),
          filterState: this.gridOptions?.api?.getFilterModel()
        };

        LocalStorageUtilites.set(this.gridKey, gridState);
      })).subscribe());
    }
  }

  protected isAdminRoute(): boolean {
    return window.location.href.indexOf(`${this.routeUtilities.routes.application.base.modulePath}/${this.routeUtilities.routes.application.admin.base.modulePath}/`) !== -1;
  }
}

