import { Component, Input, OnInit } from '@angular/core';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { ReportModel } from 'src/app/shared/models/domain/report.model';
import { ReportService } from 'src/app/core/services/domain/report.service';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { Router } from '@angular/router';
import { ReportFilingTypesEnum } from 'src/app/shared/enums/domain/report-filing-types.enum';
import { ReportStatusesEnum } from 'src/app/shared/enums/domain/report-statuses-enum';
import { EnumUtilities } from 'src/app/core/utilities/app/enum.utilities';
import {
  ReportIndividualViewModel
} from 'src/app/shared/components/grids/report-individuals-grid/report-individuals-grid.component';
import { NotificationService } from 'src/app/core/services/app/notification.service';

@Component({
  selector: 'app-reports-grid',
  templateUrl: './reports-grid.component.html',
  styleUrls: ['./reports-grid.component.scss']
})
export class ReportsGridComponent extends BaseGridComponent<ReportModel> implements OnInit {
  @Input() businessKey: string = null;
  @Input() status: ReportStatusesEnum = null;
  @Input() includeChildBusinesses: boolean = false;
  @Input() isAdmin: boolean = false;
  @Input() showDeleted: boolean = true;
  @Input() showAddButton: boolean = true;
  @Input() isMinimalistView: boolean = false;


  gridTitle: string = 'Reports';

  constructor(public authClientService: AuthClientService,
              private notificationService: NotificationService,
              private reportService: ReportService, private router: Router) {
    super();
    this.gridKey = `reports_${this.status ?? ''}_${this.isMinimalistView}_v8.1`;
  }

  ngOnInit() {
    if (this.status !== null) {
      this.gridTitle = `${EnumUtilities.getDisplayName(ReportStatusesEnum, this.status)} Reports`;
    }

    super.ngOnInit();
  }

  onAddButtonClicked(): void {
    if (this.isAdminRoute()) {
      this.router.navigateByUrl(RouteUtilities.routes.application.admin.reportAdd.getNavigateUrl());
    } else {
      this.router.navigateByUrl(RouteUtilities.routes.application.reportAdd.getNavigateUrl(this.businessKey));
    }
  }

  protected setColumnDefinitions(): ColDef[] {
    return [
      {
        headerName: '',
        field: this.actionFieldName,
        resizable: false,
        width: 20,
        cellClass: this.preventRowClickClass,
        cellRenderer: params => {
          let html = '';
          const rowData = <ReportModel>params.data;

          if (rowData.transcriptionFileKey) {
            html += '<i title="Download Transcription" class="cursor-pointer email-reminder fas fa-download prevent-row-click"></i>';
          }

          return html;
        }
      },
      this.getDefaultColumn('legalName', 'Legal Name'),
      this.getDefaultColumn('business.name', 'Name'),
      this.getEnumColumn('status', 'Status', ReportStatusesEnum, this.isMinimalistView || this.status !== null),
      this.getDateColumn('completedDateTime', 'Completed On', this.status === null || this.status !== ReportStatusesEnum.Completed),
      this.getEnumColumn('filingType', 'Filing Type', ReportFilingTypesEnum),
      this.getDateColumn('createdDateTime', 'Created On', false, true),
      this.getDateColumn('lastModifiedDateTime', 'Modified On', false, true),
    ];
  }

  protected onGridReady(): void {
    this.subscriptions.add(this.cellClick.subscribe((cellClick: { data: ReportModel, event: CellClickedEvent }) => {
      if (cellClick.event.colDef.field === this.actionFieldName) {
        this.showBlockingLoader = true;
        this.reportService.downloadReportTranscription(cellClick.data.reportKey, cellClick.data.transcriptionFileName).subscribe(_ => {
          this.showBlockingLoader = false;
        }, err => {
          this.onHttpFailure(this.notificationService, err);
          this.showBlockingLoader = false;
        })
      }
    }));
  }

  protected isRowDeleted(data: ReportModel): boolean {
    return data.status === ReportStatusesEnum.Deleted;
  }

  protected setRowData(): Observable<any[]> {
    let businessKeys = [];
    let statuses = [];
    if (this.businessKey) {
      businessKeys.push(this.businessKey);
    }

    if (this.status) {
      statuses.push(this.status);
    }

    return this.reportService.searchReports({
      businessKeys: businessKeys,
      includeChildBusinesses: this.includeChildBusinesses,
      includeDeleted: this.includeDeleted,
      isAdmin: this.isAdmin,
      statuses: statuses
    });
  }
}
