<app-base-entry-page-template entryTitle="Licenses & Subscriptions" [value]="value"
                              [formGroupRef]="formGroupRef" cancelText="Go to Dashboard"
                              [isSaving]="isSaving" [showSave]="false"
                              [showFooter]="hasValidSubscription"
                              [forceCancelEnabled]="true"
                              (cancelClick)="onCancelClicked()"
                              [saveText]="hasValidSubscription ? 'Update Services' : 'Submit & Subscribe'">
  <app-base-entry-page-content-template>
    <div class="mt-1 mb-2">
      <div *ngIf="hasValidSubscription">
        You have a valid subscription.  Looking for billing information, contact <a href="mailto:info@fincenboireporting.com">info@fincenboireporting.com</a>.
      </div>
      <div *ngIf="!hasValidSubscription">
        <app-business-subscription-payment-form
          *ngIf="value" [(formGroupRef)]="formGroupRef"
          [hideTypeSelection]="hideTypeSelection"
          [type]="type"
          [(value)]="value">
        </app-business-subscription-payment-form>
      </div>
    </div>
  </app-base-entry-page-content-template>
  <app-base-entry-page-footer-template>
  </app-base-entry-page-footer-template>
</app-base-entry-page-template>
