import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/shared/components/base/base.component';

@Component({
  selector: 'app-dashboard-hero-number',
  templateUrl: './dashboard-hero-number.component.html',
  styleUrls: ['./dashboard-hero-number.component.scss', '../shared/dashboard.component.scss']
})
export class DashboardHeroNumberComponent extends BaseComponent implements OnInit {
  @Input() title: string;
  @Input() value: string | number;
  @Input() icon: string;
  @Input() class: string = '';

  @Output() tileClick = new EventEmitter();
  @Output() cardClick = new EventEmitter();

  constructor(private router: Router) {
    super();
  }

  ngOnInit(): void {

  }

  onCardClicked(): void {
    this.cardClick.emit();
  }
}
