<app-base-page-template>
  <app-base-page-content-template>
    <ng-container *ngIf="dashboard && !isLoading">
      <div class="main-container full-height">
        <div class="mt-2">
          <div class="flex">
            <div class="flex-start flex-grow" *ngIf="!businessKey">
              <div class="form-section-header">Welcome to BOI Reporting</div>
            </div>
            <div class="flex-start flex-grow" *ngIf="businessName">
              <div class="form-section-header">{{businessName}}</div>
            </div>
            <div class="flex-end flex-shrink">
              <button mat-mini-fab color="primary" title="Create Report" (click)="onAddReportButtonClicked()">
                <mat-icon>add</mat-icon>
              </button>
            </div>

          </div>
        </div>
        <div class="dashboard-container mt-1">
          <div class="dashboard-row">
            <div (click)="onNavigateToReportClicked()">
              <app-dashboard-hero-number class="font-size-32" title="# of Reports"
                                         [value]="dashboard.numberOfReports"></app-dashboard-hero-number>
            </div>
            <div (click)="onNavigateToReportClicked(reportStatuses.Draft)">
              <app-dashboard-hero-number class="font-size-32" title="# of Drafts"
                                         [value]="dashboard.numberOfDraftReports"></app-dashboard-hero-number>
            </div>
            <div (click)="onNavigateToReportClicked(reportStatuses.Submitted)">
              <app-dashboard-hero-number class="font-size-32" title="# of Submitted"
                                         [value]="dashboard.numberOfSubmittedReports"></app-dashboard-hero-number>
            </div>
            <div (click)="onNavigateToReportClicked(reportStatuses.Incomplete)">
              <app-dashboard-hero-number class="font-size-32" title="# of Incomplete"
                                         [value]="dashboard.numberOfIncompleteReports"></app-dashboard-hero-number>
            </div>
            <div (click)="onNavigateToReportClicked(reportStatuses.Completed)">
              <app-dashboard-hero-number class="font-size-32" title="# of Completed"
                                         [value]="dashboard.numberOfCompletedReports"></app-dashboard-hero-number>
            </div>
          </div>
<!--          <div class="dashboard-row mt-2">-->
<!--            <div class="flex flex-full-auto">-->
<!--              <fieldset class="full-width notification-height">-->
<!--                <legend>-->
<!--                  Reminders Past 30 Days-->
<!--                </legend>-->
<!--                <ng-container *ngIf="dashboard.reportsWithRemindersPast30Days?.length">-->
<!--                  <div class="flex-full-auto text-center">-->
<!--                    <table class="full-width">-->
<!--                      <thead>-->
<!--                      <th style="width:20px"></th>-->
<!--                      <th>Legal Name</th>-->
<!--                      <th>Last Reminder Sent On</th>-->
<!--                      </thead>-->
<!--                      <tbody>-->
<!--                      <ng-container *ngFor="let report of dashboard.reportsWithRemindersPast30Days">-->
<!--                        <tr>-->
<!--                          <td style="width: 20px;vertical-align: middle">-->
<!--                            <mat-icon class="cursor-pointer" title="Send Reminder" (click)="onSendReminderClicked()">-->
<!--                              mail-->
<!--                            </mat-icon>-->
<!--                          </td>-->
<!--                          <td>-->
<!--                            {{report.legalName}}-->
<!--                          </td>-->
<!--                          <td>-->
<!--                            {{report.lastReminderSentDateTime | dateFormatter}}-->
<!--                          </td>-->
<!--                        </tr>-->
<!--                      </ng-container>-->
<!--                      </tbody>-->
<!--                    </table>-->
<!--                  </div>-->
<!--                </ng-container>-->
<!--                <ng-container *ngIf="!dashboard.reportsWithRemindersPast30Days.length">-->
<!--                  <div class="text-center" style="margin-top:120px">-->
<!--                    No matching reports found.-->
<!--                  </div>-->
<!--                </ng-container>-->
<!--              </fieldset>-->
<!--            </div>-->
<!--            <div class="flex flex-full-auto">-->
<!--              <fieldset class="full-width notification-height">-->
<!--                <legend>-->
<!--                  Reminders Past 60+ Days-->
<!--                </legend>-->
<!--                <ng-container *ngIf="dashboard.reportsWithRemindersPast60Days?.length">-->
<!--                  <div class="flex-full-auto text-center">-->
<!--                    <table class="full-width">-->
<!--                      <thead>-->
<!--                      <th style="width:20px"></th>-->
<!--                      <th>Legal Name</th>-->
<!--                      <th>Last Reminder Sent On</th>-->
<!--                      </thead>-->
<!--                      <tbody>-->
<!--                      <ng-container *ngFor="let report of dashboard.reportsWithRemindersPast60Days">-->
<!--                        <tr>-->
<!--                          <td style="width: 20px;vertical-align: middle">-->
<!--                            <mat-icon class="cursor-pointer" title="Send Reminder" (click)="onSendReminderClicked()">-->
<!--                              mail-->
<!--                            </mat-icon>-->
<!--                          </td>-->
<!--                          <td>-->
<!--                            {{report.legalName}}-->
<!--                          </td>-->
<!--                          <td>-->
<!--                            {{report.lastReminderSentDateTime | dateFormatter}}-->
<!--                          </td>-->
<!--                        </tr>-->
<!--                      </ng-container>-->
<!--                      </tbody>-->
<!--                    </table>-->
<!--                  </div>-->
<!--                </ng-container>-->
<!--                <ng-container *ngIf="!dashboard.reportsWithRemindersPast60Days.length">-->
<!--                  <div class="text-center" style="margin-top:120px">-->
<!--                    No matching reports found.-->
<!--                  </div>-->
<!--                </ng-container>-->
<!--              </fieldset>-->
<!--            </div>-->
<!--            <div class="flex flex-full-auto">-->
<!--              <fieldset class="full-width notification-height">-->
<!--                <legend>-->
<!--                  Report Submission Activity-->
<!--                </legend>-->
<!--                <ng-container *ngIf="dashboard.reportsWithSystemNotes?.length">-->
<!--                  <div class="flex-full-auto text-center">-->
<!--                    <table class="full-width">-->
<!--                      <thead>-->
<!--                      <th style="width:20px"></th>-->
<!--                      <th style="width: 250px">Legal Name</th>-->
<!--                      <th>Activity</th>-->
<!--                      </thead>-->
<!--                      <tbody>-->
<!--                      <ng-container *ngFor="let report of dashboard.reportsWithSystemNotes">-->
<!--                        <tr>-->
<!--                          <td style="width: 20px;vertical-align: middle">-->
<!--                            <mat-icon class="cursor-pointer" title="Send Reminder" (click)="onContactClicked()">campaign-->
<!--                            </mat-icon>-->
<!--                          </td>-->
<!--                          <td style="width:250px">-->
<!--                            {{report.legalName}}-->
<!--                          </td>-->
<!--                          <td class="text-left">-->
<!--                            {{report.systemNotes}}-->
<!--                          </td>-->
<!--                        </tr>-->
<!--                      </ng-container>-->
<!--                      </tbody>-->
<!--                    </table>-->
<!--                  </div>-->
<!--                </ng-container>-->
<!--                <ng-container *ngIf="!dashboard.reportsWithSystemNotes.length">-->
<!--                  <div class="text-center" style="margin-top:120px">-->
<!--                    No matching reports found.-->
<!--                  </div>-->
<!--                </ng-container>-->
<!--              </fieldset>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
      <div style="height:30px">&nbsp;</div>
      <div class="flex flex-end subtle-text bold" *ngIf="!businessKey">
        Data includes reports across all businesses.
      </div>
    </ng-container>
    <div class="main-loading-spinner-container">
      <app-loader *ngIf="isLoading" text="Loading Dashboard . . ."></app-loader>
    </div>
  </app-base-page-content-template>
</app-base-page-template>

