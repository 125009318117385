import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ReportSearchModel } from 'src/app/shared/models/domain/report-search.model';
import { ReportModel } from 'src/app/shared/models/domain/report.model';
import { tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { ReportNotificationTokenModel } from 'src/app/shared/models/domain/report-notification-token.model';

@Injectable()
export class ReportNotificationTokenService {
  private url = environment.apiUrl + '/report-notification-tokens/';

  constructor(private http: HttpClient) {
  }

  getReport(token: string): Observable<ReportNotificationTokenModel> {
    return this.http.get<ReportNotificationTokenModel>(`${this.url}${token}`);
  }

  updateReport(model: ReportNotificationTokenModel, fileList: FileList): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}`, this.buildFormData(model, fileList))
  }

  private buildFormData(model: ReportNotificationTokenModel, fileList: FileList): FormData {
    const formData = new FormData();
    formData.append('model', JSON.stringify(model));

    if (fileList != null && fileList.length) {
      for (let fileListKey in fileList) {
        formData.append(fileList[fileListKey].name, fileList[fileListKey]);
      }
    }

    return formData;
  }
}
