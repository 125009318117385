<form [formGroup]="formGroupRef">
  <mat-tab-group #matTabGroup [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onSelectedTabChanged()">
    <mat-tab label="Company Applicant" *ngIf="hasCompanyApplicant">
      <div class="form-section-header-title mt-2">
        Company Applicant Identification
        <mat-icon matSuffix
                  matTooltip="An individual who directly files the document that creates or registers the company to do business. No company or legal entities may be listed as a company applicant. No more than two company applicants should be reported.">
          help_outline
        </mat-icon>
      </div>
      <ng-container
        *ngFor="let companyApplicantFormGroup of getFormArrayByProperty('companyApplicants').controls; let i = index">
        <div class="field-row mt-2">
          <div class="form-section-header">Legal Name and Date of Birth</div>
          <app-report-individual-sub-form
            [readonly]="readonly"
            [formGroupRef]="getFormGroupFromFormGroupByProperty(companyApplicantFormGroup, 'individual')"></app-report-individual-sub-form>
          <div class="flex">
            <div class="form-section-header mt-2" style="margin-top:2px">
              Current Address
            </div>
            <div class="flex flex-shrink">
              <mat-icon matSuffix
                        matTooltip="Select business address if you are an applicant who forms the entity in the course of business, such as a paralegal and enter in business address. Otherwise, the individual’s residential address must be reported.">
                help_outline
              </mat-icon>
            </div>
          </div>
          <app-report-address-sub-form
            [readonly]="readonly"
            [showAddressType]="true"
            [formGroupRef]="getFormGroupFromFormGroupByProperty(companyApplicantFormGroup, 'address')"></app-report-address-sub-form>
          <div class="flex">
            <div class="form-section-header mt-2" style="margin-top:2px">
              Form of Identification and Issuing Jurisdiction
            </div>
            <div class="flex flex-shrink">
              <mat-icon matSuffix
                        matTooltip="Select a non-expired passport, non-expired driver’s license, or non-expired State/local/tribe-issued identification document.  If you do not have any of the above, select a non-expired passport.">
                help_outline
              </mat-icon>
            </div>
          </div>
          <app-report-identification-sub-form
            [readonly]="readonly"
            [enableDownload]="false"
            (downloadIdentificationClick)="onDownloadCompanyApplicantIdentificationClicked(companyApplicantFormGroup.value.identification.documentFileKey, companyApplicantFormGroup.value.identification.documentFileName)"
            (fileListChange)="onFilesChanged($event)"
            [formGroupRef]="getFormGroupFromFormGroupByProperty(companyApplicantFormGroup, 'identification')"></app-report-identification-sub-form>
        </div>
<!--        <div class="flex">-->
<!--          <div class="flex flex-end mt-2 mb-2">-->
<!--            <app-save-button [isDisabled]="readonly" class="mr-2" *ngIf="hasBeneficialOwner"-->
<!--                             text="Copy to Beneficial Owner"-->
<!--                             (click)="onCopyToBeneficialOwnerClicked(companyApplicantFormGroup)"></app-save-button>-->
<!--          </div>-->
<!--        </div>-->
      </ng-container>
    </mat-tab>

    <mat-tab label="Beneficial Owner" *ngIf="hasBeneficialOwner">
      <div class="form-section-header-title mt-2">
        Beneficial Owner Identification
        <mat-icon matSuffix
                  matTooltip="A person who owns directly or indirectly 25% or more of a reporting entity or exercises substantial control over a reporting company.">
          help_outline
        </mat-icon>
      </div>
      <ng-container
        *ngFor="let beneficialOwnerFormGroup of getFormArrayByProperty('beneficialOwners').controls; let i = index">
        <div class="field-row mt-2">
          <div class="form-section-header">Exempt Entity</div>
          <div class="flex">
            <div class="flex flex-shrink" style="margin-top:2px">
              <div class="flex flex-shrink" style="margin-top: -2px;">
                <mat-checkbox
                  [disabled]="readonly"
                  [formControl]="getFormGroupControlFromFormGroupByProperty(beneficialOwnerFormGroup, 'isExempt')">
                </mat-checkbox>
              </div>
              <div class="flex flex-shrink ml-1">
                Exempt Entity
              </div>
            </div>
            <div class="flex flex-shrink">
              <mat-icon matSuffix
                        matTooltip="If beneficial owner holds ownership exclusively through one or more exempt entities, and the names of that exempt entity are being reported instead of beneficial owner information.">
                help_outline
              </mat-icon>
            </div>
          </div>
        </div>
        <ng-container *ngIf="!beneficialOwnerFormGroup.value.isExempt">
          <div class="field-row mt-2">
            <div class="flex">
              <div class="flex flex-shrink" style="margin-top:2px">
                <div class="flex flex-shrink" style="margin-top: -2px;">
                  <mat-checkbox
                    [disabled]="readonly"
                    [formControl]="getFormGroupControlFromFormGroupByProperty(beneficialOwnerFormGroup, 'isParentGuardian')">
                    Parent/Guardian information instead of minor child
                  </mat-checkbox>
                </div>
              </div>
              <div class="flex flex-shrink">
                <mat-icon matSuffix
                          matTooltip="Check if the Beneficial Owner is a minor child and the parent/guardian information is provided instead.">
                  help_outline
                </mat-icon>
              </div>
            </div>
          </div>
          <div class="form-section-header mt-2">Legal Name and Date of Birth</div>
          <app-report-individual-sub-form
            [readonly]="readonly"
            [formGroupRef]="getFormGroupFromFormGroupByProperty(beneficialOwnerFormGroup, 'individual')"></app-report-individual-sub-form>
          <div class="form-section-header mt-2">Residential Address</div>
          <app-report-address-sub-form
            [readonly]="readonly"
            [showAddressType]="false"
            [formGroupRef]="getFormGroupFromFormGroupByProperty(beneficialOwnerFormGroup, 'address')"></app-report-address-sub-form>
          <div class="flex">
            <div class="form-section-header mt-2" style="margin-top:2px">
              Form of Identification and Issuing Jurisdiction
            </div>
            <div class="flex flex-shrink">
              <mat-icon matSuffix
                        matTooltip="Select a non-expired passport, non-expired driver’s license, or non-expired State/local/tribe-issued identification document.  If you do not have any of the above, select a non-expired passport.">
                help_outline
              </mat-icon>
            </div>
          </div>
          <app-report-identification-sub-form
            [readonly]="readonly"
            (downloadIdentificationClick)="onDownloadBeneficialOwnerIdentificationClicked(beneficialOwnerFormGroup.value.identification.documentFileKey, beneficialOwnerFormGroup.value.identification.documentFileName)"
            (fileListChange)="onFilesChanged($event)"
            [formGroupRef]="getFormGroupFromFormGroupByProperty(beneficialOwnerFormGroup, 'identification')"></app-report-identification-sub-form>
        </ng-container>
        <ng-container *ngIf="beneficialOwnerFormGroup.value.isExempt">
          <div class="form-section-header mt-2">Legal Name</div>
          <mat-form-field class="mr-2 mat-form-field-double">
            <mat-label>Individual's Last Name or Entity's Legal Name</mat-label>
            <input [readonly]="readonly" matInput type="text"
                   [formControl]="getFormGroupControlFromFormGroupByProperty(beneficialOwnerFormGroup, 'exemptLegalEntity')">
          </mat-form-field>
        </ng-container>
      </ng-container>
    </mat-tab>
    <mat-tab label="Submit">
      <div class="form-section-header-title mt-2">Submit Identification</div>
      <ng-container>
        <div class="field-row mt-2">
          <b>I certify that the information contained is true, correct, and complete.</b>
        </div>
        <div class="mt-1 full-height">
          <b>COMPLIANCE REMINDER: </b>The willful failure to report complete beneficial ownership information to FinCEN,
          the willful failure to update beneficial ownership information provided to FinCEN when previously reported
          information changes, or the willful provision of false or fraudulent beneficial ownership information to
          FinCEN, may result in civil or criminal penalties. A person may also be subject to civil or criminal penalties
          for willfully causing a reporting company to report incomplete or false beneficial ownership information to
          FinCEN.
        </div>
      </ng-container>
    </mat-tab>
  </mat-tab-group>
</form>
