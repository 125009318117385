<div class="base-entry-template">
  <div class="base-entry-header flex">
    <div class="flex grid-header-left-container">
      <h1 *ngIf="entryTitle" class="entry-title-container">{{entryTitle}}</h1>
      <div class="flex ml-2">
        <ng-content select="app-base-entry-page-header-template"></ng-content>
      </div>
    </div>
  </div>
  <div class="base-entry-template-content">
    <mat-card>
      <mat-card-content>
        <ng-container *ngIf="!value">
          <app-loader></app-loader>
        </ng-container>
        <ng-container *ngIf="value">
          <ng-content select="app-base-entry-page-content-template"></ng-content>
        </ng-container>
        <ng-container *ngIf="showFooter">
          <div class="base-entry-template-footer">
            <ng-container *ngIf="value && !isSaving">
              <div class="entry-footer-actions-container">
                <div class="inline-block mr-2 mt-1" *ngIf="showSave">
                  <app-save-button (click)="onSaveClicked()" [text]="saveText" [processingText]="savingText"
                                   [isDisabled]="!formGroupRef.dirty || isSaving || isDisabled"
                                   [isSaving]="isSaving"></app-save-button>
                </div>
                <div class="inline-block mr-2 mt-1" *ngIf="showCancel">
                  <button mat-flat-button color="primary" (click)="onCancelClicked()"
                          [disabled]="!forceCancelEnabled && (isSaving || isDisabled)">{{cancelText}}</button>
                </div>
                <ng-content select="app-base-entry-page-footer-template"></ng-content>
              </div>
            </ng-container>
            <ng-container *ngIf="isSaving">
              <div class="mt-2">
                <div class="inline-block"><i class="loader fas fa-spinner fa-spin mr-2"></i></div>
                <div class="inline-block bold">Processing please wait . . .</div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>
</div>
