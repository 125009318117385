import { ReportTaxIdentificationTypesEnum } from 'src/app/shared/enums/domain/report-tax-identification-types.enum';
import { EnumUtilities } from 'src/app/core/utilities/app/enum.utilities';
import { ReportDocumentTypesEnum } from 'src/app/shared/enums/domain/report-document-types.enum';

export class ReportUtilities {
  static getTaxIdentifierDisplayName(value: number): string {
    if (!value) {
      return null;
    }

    if (value === ReportTaxIdentificationTypesEnum.SSNITIN) {
      return 'SSN/ITIN';
    }

    if (value === ReportTaxIdentificationTypesEnum.EIN) {
      return 'EIN';
    }

    return EnumUtilities.getDisplayName(ReportTaxIdentificationTypesEnum, value);
  }

  static getDocumentTypeDisplayName(value: number): string {
    if (!value) {
      return null;
    }

    if (value === ReportDocumentTypesEnum.StateIssuedDriverLicense) {
      return 'State Issued Driver\'s License';
    }

    if (value === ReportDocumentTypesEnum.StateLocalTribeIssuedIdentification) {
      return 'State/Local/Tribe-Issued ID';
    }

    if (value === ReportDocumentTypesEnum.USPassport) {
      return 'U.S. Passport';
    }

    return EnumUtilities.getDisplayName(ReportDocumentTypesEnum, value);
  }
}
