import { Component, Input } from '@angular/core';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';
import { BusinessService } from 'src/app/core/services/domain/business.service';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { BusinessSubscriptionService } from 'src/app/core/services/domain/business-subscription.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-businesses-grid',
  templateUrl: './businesses-grid.component.html',
  styleUrls: ['./businesses-grid.component.scss']
})
export class BusinessesGridComponent extends BaseGridComponent<BusinessModel> {
  @Input() isAdmin: boolean = false
  constructor(public authClientService: AuthClientService,
              private businessService: BusinessService,
              private notificationService: NotificationService,
              private businessSubscriptionService: BusinessSubscriptionService,
              private dialogService: DialogService) {
    super();
    this.gridKey = 'Businesses_v3';
  }

  protected setColumnDefinitions(): ColDef[] {
    return [
      // {
      //   headerName: '',
      //   field: this.actionFieldName,
      //   width: 20,
      //   cellClass: this.preventRowClickClass,
      // },
      this.getDefaultColumn('legalName', 'Legal Name'),
      this.getDefaultColumn('name', 'Name'),
      this.getCreatedOnColumn()
    ];
  }

  protected onGridReady(): void {
    this.subscriptions.add(this.cellClick.subscribe((cellClick: {data: BusinessModel, event: CellClickedEvent }) => {
      if (cellClick.event.colDef.field === this.actionFieldName) {
        if (this.hasCellClass(cellClick.event, 'cancel-subscription')) {
          let matDialogRef = this.dialogService.openConfirmationDialog('You are about to cancel a subscription?');

          this.subscriptions.add(matDialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.businessSubscriptionService.delete(cellClick.data.businessKey).subscribe(_ => {
                this.notificationService.showSuccessNotification('Subscription has been cancelled successfully.');
                this.refreshGridData().subscribe();
              }, err => {
                this.onHttpFailure(this.notificationService, err);
              });
            }
          }));
        }
      }
    }));
  }

  protected setRowData(): Observable<any[]> {
    //let businessKey = this.isAdmin ? null : this.businessContextService.currentBusiness.businessKey;

    return this.businessService.search({businessKey: null, includeDeleted: this.includeDeleted, includeChildBusinesses: true}).pipe(map(data => {
      if (this.isAdmin) {
        return data;
      }

      return data;
      //return data.filter(t => t.parentBusinessKey === businessKey);
    }));
  }
}
