import { Component, OnInit } from '@angular/core';
import { ReportModel } from 'src/app/shared/models/domain/report.model';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { ReportStatusesEnum } from 'src/app/shared/enums/domain/report-statuses-enum';

@Component({
  selector: 'app-report-activities-page',
  templateUrl: './report-activities-page.component.html',
  styleUrls: ['./report-activities-page.component.scss']
})
export class ReportActivitiesPageComponent extends BasePageComponent implements OnInit {
  reports: ReportModel[] = [];
  status: ReportStatusesEnum = null;
  businessKey: string = null
  isAdmin: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private businessContextService: BusinessContextService) {
    super();
  }

  ngOnInit(): void {
    this.isAdmin = this.isAdminRoute();
    this.businessKey = this.isAdmin ? null : this.businessContextService.currentBusiness.businessKey;
  }

  onRowClicked(event: any): void {
    if (this.isAdminRoute()) {
      this.router.navigate([RouteUtilities.routes.application.admin.reportEdit.getNavigateUrl(event.data.reportKey)]);
    } else {
      this.router.navigate([RouteUtilities.routes.application.reportEdit.getNavigateUrl(event.data.reportKey)]);
    }
  }
}
