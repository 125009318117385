import { Routes } from '@angular/router';
import { SandboxPageComponent } from 'src/app/pages/application/admin/sandbox-page/sandbox-page.component';
import { StaticPageComponent } from 'src/app/pages/static/static-page.component';
import { ApplicationPageComponent } from 'src/app/pages/application/application-page.component';
import { DashboardPageComponent } from 'src/app/pages/application/dashboard-page/dashboard-page.component';
import { BusinessesPageComponent } from 'src/app/pages/application/shared/businesses-page/businesses-page.component';
import {
  AdminDashboardPageComponent
} from 'src/app/pages/application/admin/admin-dashboard-page/admin-dashboard-page.component';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { PrivacyPolicyPageComponent } from 'src/app/pages/shared/privacy-policy-page/privacy-policy-page.component';
import {
  TermsConditionsPageComponent
} from 'src/app/pages/shared/terms-conditions-page/terms-conditions-page.component';
import { ProfilePageComponent } from 'src/app/pages/application/profile-page/profile-page.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import {
  BusinessesAdminPageComponent
} from 'src/app/pages/application/admin/businesses-admin-page/businesses-admin-page.component';
import { BusinessPageComponent } from 'src/app/pages/application/shared/businesses-page/business-page/business-page.component';
import { ClientsPageComponent } from 'src/app/pages/application/admin/clients-page/clients-page.component';
import { ProfileSetupPageComponent } from 'src/app/pages/application/profile-setup-page/profile-setup-page.component';
import { RedirectPageComponent } from 'src/app/pages/application/redirect-page/redirect-page.component';
import { AuthAdminGuard } from 'src/app/core/guards/auth-admin.guard';
import {
  ClientPageComponent
} from 'src/app/pages/application/admin/clients-page/client-page/client-page.component';
import { PendingChangesGuard } from 'src/app/core/guards/pending-changes.guard';
import { LoginComponent } from 'src/app/pages/shared/login/login.component';
import { BusinessSubscriptionsPageComponent } from 'src/app/pages/application/subscriptions/business-subscriptions-page.component';
import { SubscriptionGuard } from 'src/app/core/guards/subscription-guard.service';
import { PricingPageComponent } from 'src/app/pages/shared/pricing-page/pricing-page.component';
import { ReportsPageComponent } from 'src/app/pages/application/shared/reports-page/reports-page.component';
import { ReportPageComponent } from 'src/app/pages/application/shared/reports-page/report-page/report-page.component';
import { BusinessGuard } from 'src/app/core/guards/business.guard';
import {
  ReportActivitiesPageComponent
} from 'src/app/pages/application/shared/report-activities-page/report-activities-page.component';
import {
  ReportNotificationTokenPageComponent
} from 'src/app/pages/static/report-notification-token-page/report-notification-token-page.component';
import {
  ReportIndividualsPageComponent
} from 'src/app/pages/application/shared/report-individuals-page/report-individuals-page.component';
import {
  BusinessesSubscriptionsPageComponent
} from 'src/app/pages/application/admin/businesses-subscriptions-page/businesses-subscriptions-page.component';

export const routes: Routes = [
  {
    path: RouteUtilities.routes.application.base.modulePath,
    canActivate: [AuthGuard],
    component: ApplicationPageComponent,
    children: [
      {
        path: '',
        redirectTo: RouteUtilities.routes.application.dashboard.modulePath,
        pathMatch: 'full'
      },
      {
        path: RouteUtilities.routes.application.redirect.modulePath,
        component: RedirectPageComponent
      },
      {
        path: RouteUtilities.routes.application.dashboard.modulePath,
        canActivate: [AuthGuard, SubscriptionGuard],
        component: DashboardPageComponent
      },
      {
        path: RouteUtilities.routes.application.businesses.modulePath,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: BusinessesPageComponent,
          },
          {
            path: RouteUtilities.routes.application.businessEdit.modulePath,
            children: [
              {
                path: '',
                canDeactivate: [PendingChangesGuard],
                component: BusinessPageComponent,
              }
            ]
          },
          {
            path: RouteUtilities.routes.application.businessAdd.modulePath,
            canActivate: [SubscriptionGuard],
            canDeactivate: [PendingChangesGuard],
            component: BusinessPageComponent,
          }
        ]
      },
      {
        path: RouteUtilities.routes.application.reports.modulePath,
        canActivate: [AuthGuard, BusinessGuard, SubscriptionGuard],
        children: [
          {
            path: '',
            component: ReportsPageComponent,
          },
          {
            path: RouteUtilities.routes.application.reportEdit.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: ReportPageComponent,
          },
          {
            path: RouteUtilities.routes.application.reportAdd.modulePath,
            canDeactivate: [PendingChangesGuard],
            component: ReportPageComponent,
          }
        ]
      },
      {
        path: RouteUtilities.routes.application.reportActivities.modulePath,
        canActivate: [AuthGuard, BusinessGuard, SubscriptionGuard],
        children: [
          {
            path: '',
            component: ReportActivitiesPageComponent,
          }
        ]
      },
      {
        path: RouteUtilities.routes.application.reportIndividuals.modulePath,
        canActivate: [AuthGuard, BusinessGuard, SubscriptionGuard],
        children: [
          {
            path: '',
            component: ReportIndividualsPageComponent,
          }
        ]
      },
      {
        path: RouteUtilities.routes.application.admin.base.modulePath,
        canActivate: [AuthAdminGuard],
        children: [
          {
            path: RouteUtilities.routes.application.admin.dashboard.modulePath,
            component: AdminDashboardPageComponent
          },
          {
            path: RouteUtilities.routes.application.admin.clients.modulePath,
            children: [
              {
                path: '',
                component: ClientsPageComponent,
              },
              {
                path: RouteUtilities.routes.application.admin.clientAdd.modulePath,
                canDeactivate: [PendingChangesGuard],
                component: ClientPageComponent
              },
              {
                path: RouteUtilities.routes.application.admin.clientEdit.modulePath,
                canDeactivate: [PendingChangesGuard],
                component: ClientPageComponent
              }
            ]
          },
          {
            path: RouteUtilities.routes.application.admin.businesses.modulePath,
            children: [
              {
                path: '',
                component: BusinessesAdminPageComponent,
              },
              {
                path: RouteUtilities.routes.application.admin.businessEdit.modulePath,
                children: [
                  {
                    path: '',
                    canDeactivate: [PendingChangesGuard],
                    component: BusinessPageComponent,
                  }
                ]
              },
              {
                path: RouteUtilities.routes.application.admin.businessAdd.modulePath,
                canDeactivate: [PendingChangesGuard],
                component: BusinessPageComponent,
              }
            ]
          },
          {
            path: RouteUtilities.routes.application.admin.subscriptions.modulePath,
            children: [
              {
                path: '',
                component: BusinessesSubscriptionsPageComponent,
              },
            ]
          },
          {
            path: RouteUtilities.routes.application.admin.reports.modulePath,
            canActivate: [AuthGuard, BusinessGuard, SubscriptionGuard],
            children: [
              {
                path: '',
                component: ReportsPageComponent,
              },
              {
                path: RouteUtilities.routes.application.admin.reportEdit.modulePath,
                canDeactivate: [PendingChangesGuard],
                component: ReportPageComponent,
              },
              {
                path: RouteUtilities.routes.application.admin.reportAdd.modulePath,
                canDeactivate: [PendingChangesGuard],
                component: ReportPageComponent,
              }
            ]
          },
          {
            path: RouteUtilities.routes.application.admin.reportActivities.modulePath,
            canActivate: [AuthGuard, BusinessGuard, SubscriptionGuard],
            children: [
              {
                path: '',
                component: ReportActivitiesPageComponent,
              }
            ]
          },
          {
            path: RouteUtilities.routes.application.admin.reportIndividuals.modulePath,
            canActivate: [AuthGuard, BusinessGuard, SubscriptionGuard],
            children: [
              {
                path: '',
                component: ReportIndividualsPageComponent,
              }
            ]
          },
          {
            path: RouteUtilities.routes.application.admin.sandbox.modulePath,
            component: SandboxPageComponent
          }
        ]
      },
      {
        path: RouteUtilities.routes.application.profile.modulePath,
        canActivate: [AuthGuard],
        component: ProfilePageComponent
      },
      {
        path: RouteUtilities.routes.application.profileSetup.modulePath,
        component: ProfileSetupPageComponent
      },
      {
        path: RouteUtilities.routes.application.subscriptions.modulePath,
        component: BusinessSubscriptionsPageComponent
      },
      {
        path: RouteUtilities.routes.application.pricing.modulePath,
        component: PricingPageComponent
      },
      {
        path: RouteUtilities.routes.application.privacyPolicy.modulePath,
        component: PrivacyPolicyPageComponent
      },
      {
        path: RouteUtilities.routes.application.terms.modulePath,
        component: TermsConditionsPageComponent
      }
    ]
  },
  {
    path: '',
    component: StaticPageComponent,
    children: [
      {
        path: '',
        redirectTo: RouteUtilities.routes.static.base.modulePath,
        pathMatch: 'full'
      },
      {
        path: RouteUtilities.routes.static.login.modulePath,
        component: LoginComponent
      },
      {
        path: RouteUtilities.routes.static.reportNotificationTokens.modulePath,
        component: ReportNotificationTokenPageComponent
      },
      {
        path: RouteUtilities.routes.static.pricing.modulePath,
        component: PricingPageComponent
      },
      {
        path: RouteUtilities.routes.static.privacyPolicy.modulePath,
        component: PrivacyPolicyPageComponent
      },
      {
        path: RouteUtilities.routes.static.terms.modulePath,
        component: TermsConditionsPageComponent
      },
    ]
  },
  {
    path: '**',
    redirectTo: '/'
  }
]
