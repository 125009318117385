import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ReportSearchModel } from 'src/app/shared/models/domain/report-search.model';
import { ReportModel } from 'src/app/shared/models/domain/report.model';
import { tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { ReportStatusesEnum } from 'src/app/shared/enums/domain/report-statuses-enum';

@Injectable()
export class ReportService {
  private url = environment.apiUrl + '/reports/';

  constructor(private http: HttpClient) {
  }

  getReport(key: string): Observable<ReportModel> {
    return this.http.get<ReportModel>(`${this.url}${key}`);
  }

  getReportCopy(businessKey: string): Observable<ReportModel> {
    return this.http.get<ReportModel>(`${this.url}business/${businessKey}/copy`);
  }

  canEdit(report: ReportModel): boolean {
    return report.status !== ReportStatusesEnum.Completed && report.status !== ReportStatusesEnum.Submitted && report.status !== ReportStatusesEnum.Deleted;
  }

  downloadReportTranscription(reportKey: string, fileName: string): Observable<void> {
    return this.http.get(`${this.url}${reportKey}/download`, { observe: 'response', responseType: 'blob' }).pipe(tap((data: any) => {
      if (data.type === HttpEventType.Response) {
        saveAs(data.body, fileName);
      }
    }));
  }

  downloadReportIdentification(reportKey: string, fileKey: string, fileName: string): Observable<void> {
    return this.http.get(`${this.url}${reportKey}/identification/${fileKey}/download`, { observe: 'response', responseType: 'blob' }).pipe(tap((data: any) => {
      if (data.type === HttpEventType.Response) {
        saveAs(data.body, fileName);
      }
    }));
  }


  // downloadReportCompanyApplicantIdentification(reportCompanyApplicantKey: string, fileName: string): Observable<void> {
  //   return this.http.get(`${this.url}company-applicants/${reportCompanyApplicantKey}/download`, { observe: 'response', responseType: 'blob' }).pipe(tap((data: any) => {
  //     if (data.type === HttpEventType.Response) {
  //       saveAs(data.body, fileName);
  //     }
  //   }));
  // }
  //
  // downloadReportBeneficialOwnerIdentification(reportBeneficialOwnerKey: string, fileName: string): Observable<void> {
  //   return this.http.get(`${this.url}beneficial-owners/${reportBeneficialOwnerKey}/download`, { observe: 'response', responseType: 'blob' }).pipe(tap((data: any) => {
  //     if (data.type === HttpEventType.Response) {
  //       saveAs(data.body, fileName);
  //     }
  //   }));
  // }

  searchReports(model: ReportSearchModel = {}): Observable<ReportModel[]> {
    return this.http.post<ReportModel[]>(`${this.url}search`, model);
  }

  createReport(model: ReportModel, fileList: FileList): Observable<string> {
    return this.http.post<string>(`${this.url}`, this.buildFormData(model, fileList))
  }

  updateReport(model: ReportModel, fileList: FileList): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}`, this.buildFormData(model, fileList))
  }

  deleteReport(reportKey: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.url}${reportKey}`);
  }

  private buildFormData(model: ReportModel, fileList: FileList): FormData {
    const formData = new FormData();
    formData.append('model', JSON.stringify(model));

    if (fileList != null && fileList.length) {
      for (let fileListKey in fileList) {
        formData.append(fileList[fileListKey].name, fileList[fileListKey]);
      }
    }

    return formData;
  }
}
