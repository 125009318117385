<app-reports-grid
  [isMinimalistView]="true"
  [showAddButton]="false"
  [showDeleted]="false"
  [status]="dialogData.status"
  (rowClick)="onRowClicked($event)"
  [includeChildBusinesses]="true"
  [businessKey]="dialogData.businessKey">
</app-reports-grid>

