import {Component, Input, OnInit} from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { UntypedFormGroup } from '@angular/forms';
import { CountryModel } from 'src/app/shared/models/domain/country.model';
import { StateModel } from 'src/app/shared/models/domain/state.model';
import { CountryService } from 'src/app/core/services/domain/country.service';
import { StateService } from 'src/app/core/services/domain/state.service';
import { ReportAddressModel } from 'src/app/shared/models/domain/report-address.model';
import { ReportFilingTypesEnum } from 'src/app/shared/enums/domain/report-filing-types.enum';
import { ReportAddressTypesEnum } from 'src/app/shared/enums/domain/report-address-types.enum';

@Component({
  selector: 'app-report-address-sub-form',
  templateUrl: './report-address-sub-form.component.html',
  styleUrls: ['./report-address-sub-form.component.scss']
})
export class ReportAddressSubFormComponent extends BaseFormComponent<ReportAddressModel> implements OnInit {
  @Input() readonly = false;
  @Input() usOnly: boolean = false;
  @Input() showAddressType: boolean = false;
  @Input() addressTypeLabel: string = 'Address Type';
  @Input() addressLabel: string = 'Address (Number, Street, and Apt. or Suite No.)';
  @Input() cityLabel: string = 'City';
  @Input() countryLabel: string = 'Country';
  @Input() stateLabel: string = 'State';
  @Input() postalCodeLabel: string = 'Postal Code';

  reportAddressTypes = ReportAddressTypesEnum;

  countryOptions: CountryModel[];
  stateOptions: StateModel[];

  constructor(private countryService: CountryService, private stateService: StateService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.usOnly) {
      this.countryService.listUSCountries().subscribe(countries => {
        this.countryOptions = countries;
        this.countryOptions.unshift({code: null, name: ''});
        this.onCountryChanged(true);
      });
    } else {
      this.countryService.list().subscribe(countries => {
        this.countryOptions = countries;
        this.countryOptions.unshift({code: null, name: ''});
        this.onCountryChanged(true);
      });
    }

    this.subscriptions.add(this.formGroupRef.get('countryCode').valueChanges.subscribe(_ => {
      this.onCountryChanged();
    }));
  }

  onCountryChanged(isInitialLoad = false): void {
    if (!isInitialLoad) {
      this.formGroupRef.get('stateCode').setValue(null);
    }

    if (this.formGroupRef.get('countryCode').value) {
      this.stateService.listByCountryCode(this.formGroupRef.get('countryCode').value).subscribe(states => {
        this.stateOptions = states;
        this.stateOptions.unshift({code: null, name: '', countryCode: null});
      });
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    return this.formGroupRef;
  }

  protected readonly ReportFilingTypesEnum = ReportFilingTypesEnum;
}
