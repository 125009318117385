import { Component, Input } from '@angular/core';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';
import { BusinessSubscriptionService } from 'src/app/core/services/domain/business-subscription.service';
import { BusinessSubscriptionTypesEnum } from 'src/app/shared/enums/domain/business-subscription-types.enum';
import { BusinessSubscriptionModel } from 'src/app/shared/models/domain/business-subscription.model';

@Component({
  selector: 'app-business-subscriptions-grid',
  templateUrl: './business-subscriptions-grid.component.html',
  styleUrls: ['./business-subscriptions-grid.component.scss']
})
export class BusinessSubscriptionsGridComponent extends BaseGridComponent<BusinessSubscriptionModel> {
  @Input() isAdmin: boolean = false;

  constructor(public authClientService: AuthClientService,
              private businessSubscriptionService: BusinessSubscriptionService) {
    super();
    this.gridKey = 'Businesses_v4';
  }

  protected setColumnDefinitions(): ColDef[] {
    return [
      this.getDefaultColumn('business.legalName', 'Legal Name'),
      this.getDefaultColumn('business.name', 'Name'),
      this.getEnumColumn('type', 'Type', BusinessSubscriptionTypesEnum),
      this.getCreatedByColumn(),
      this.getCreatedOnColumn()
    ];
  }

  protected onGridReady(): void {
    this.subscriptions.add(this.cellClick.subscribe((cellClick: {data: BusinessModel, event: CellClickedEvent }) => {

    }));
  }

  protected setRowData(): Observable<any[]> {
    return this.businessSubscriptionService.search({includeDeleted: this.includeDeleted});
  }
}
