import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';

@Component({
  selector: 'app-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.scss']
})
export class StaticPageComponent extends BasePageComponent implements OnInit {
  constructor(
    private router: Router) {
    super();
  }

  ngOnInit(): void {
    //this.router.navigateByUrl(RouteUtilities.routes.static.services.getNavigateUrl());
  }
}
