export enum ReportActivityTypesEnum {
  Unknown = 0,
  ViewReport = 1,
  CreateReport = 2,
  UpdateReport = 3,
  SubmitReport = 4,
  DownloadTranscription = 5,
  DownloadCompanyApplicantIdentification = 6,
  DownloadBeneficialOwnerIdentification = 7,
  AutomatedIndividualReminder = 8,
  ManualIndividualReminder = 9,
  IndividualAccessFailed = 10,
  IndividualViewReport = 11,
  IndividualUpdateReport = 12,
  SubmitReportFailed = 13,
  IndividualUpdateReportFailed = 14,
  DeleteReport = 15
}
