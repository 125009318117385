import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { ReportService } from 'src/app/core/services/domain/report.service';
import { BaseReportFormComponent } from 'src/app/shared/components/forms/base/base-report-form.component';
import { ReportNotificationTokenModel } from 'src/app/shared/models/domain/report-notification-token.model';

@Component({
  selector: 'app-report-notification-token-form',
  templateUrl: './report-notification-token-form.component.html',
  styleUrls: ['./report-notification-token-form.component.scss']
})
export class ReportNotificationTokenFormComponent extends BaseReportFormComponent<ReportNotificationTokenModel> implements OnInit {
  @Input() readonly: boolean = false;

  hasCompanyApplicant: boolean = false;
  hasBeneficialOwner: boolean = false;

  constructor(private reportService: ReportService,
              private notificationService: NotificationService) {
    super()
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected getFormGroup(): UntypedFormGroup {
    let companyApplicantFormArray = new UntypedFormArray([]);
    if (this.value.reportCompanyApplicant?.reportCompanyApplicantKey) {
      companyApplicantFormArray.push(this.getCompanyApplicantFormGroup(this.value.reportCompanyApplicant));
      this.hasCompanyApplicant = true;
    }

    let beneficialOwnerFormArray = new UntypedFormArray([]);
    if (this.value.reportBeneficialOwner?.reportBeneficialOwnerKey) {
        beneficialOwnerFormArray.push(this.getBeneficialOwnerFormGroup(this.value.reportBeneficialOwner));
        this.hasBeneficialOwner = true;
    }

    return new UntypedFormGroup({
      reportNotificationTokenKey: new UntypedFormControl(this.value.reportNotificationTokenKey),
      report: new UntypedFormControl(this.value.report),

      companyApplicants: companyApplicantFormArray,
      beneficialOwners: beneficialOwnerFormArray,

      token: new UntypedFormControl(this.value.token),

      issuedOnDateTime: new UntypedFormControl(this.value.issuedOnDateTime),
      accessedDateTime: new UntypedFormControl(this.value.accessedDateTime)
    });
  };

  onDownloadCompanyApplicantIdentificationClicked(fileKey: string, fileName: string): void {
    this.reportService.downloadReportIdentification(this.value.report.reportKey, fileKey, fileName).subscribe(_ => {
      this.notificationService.showSuccessNotification('File downloaded successfully.');
    }, err => {
      this.onHttpFailure(this.notificationService, err);
    })
  }

  onDownloadBeneficialOwnerIdentificationClicked(fileKey: string, fileName: string): void {
    this.reportService.downloadReportIdentification(this.value.report.reportKey, fileKey, fileName).subscribe(_ => {
      this.notificationService.showSuccessNotification('File downloaded successfully.');
    }, err => {
      this.onHttpFailure(this.notificationService, err);
    })
  }

  onFilesChanged(files: FileList): void {
    if (this.fileList != null) {
      this.fileList = this.mergeFileLists(this.fileList, files);
    } else {
      this.fileList = files;
    }

    this.fileListChange.emit(this.fileList);
  }

  protected mergeFileLists(fileListA: FileList, fileListB: FileList): FileList {
    const dataTransfer = new DataTransfer();

    for (let i = 0; i < fileListA.length; i++) {
      dataTransfer.items.add(fileListA[i]);
    }

    for (let i = 0; i < fileListB.length; i++) {
      dataTransfer.items.add(fileListB[i]);
    }

    return dataTransfer.files;
  }
}
