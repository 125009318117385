import { Component, OnInit } from '@angular/core';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { filter, pairwise } from 'rxjs/operators';
import { RoutingService } from 'src/app/core/services/app/routing.service';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { BusinessSubscriptionService } from 'src/app/core/services/domain/business-subscription.service';
import { AuthService } from '@auth0/auth0-angular';
import { BusinessSubscriptionTypesEnum } from 'src/app/shared/enums/domain/business-subscription-types.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BasePageComponent implements OnInit {
  constructor(
    private router: Router,
    private dialogService: DialogService,
    private pendingChangesService: PendingChangesService,
    private businessContextService: BusinessContextService,
    private authService: AuthService,
    private authClientService: AuthClientService,
    private subscriptionService: BusinessSubscriptionService,
    private routingService: RoutingService) {
    super();

    document.getElementsByClassName('main-loading-spinner-container')[0].remove();

    let tokenRouterPart = this.routeUtilities.routes.static.reportNotificationTokens.modulePath.split('/')[0];

    if (window.location.href.indexOf(tokenRouterPart) !== -1) {
      let urlParts = window.location.href.split('/');
      let token = urlParts[urlParts.length - 1];
      this.router.navigateByUrl(this.routeUtilities.routes.static.reportNotificationTokens.getNavigateUrl(token));
    } else if (this.authClientService.isAuthenticated) {
      if (!this.authClientService.isSetupCompleted() || !this.businessContextService.currentBusiness) {
        this.router.navigateByUrl(this.routeUtilities.routes.application.profileSetup.getNavigateUrl());
      } else if (this.authClientService.client && this.isApplicationRoute()) {
        this.subscriptionService.hasValidSubscription(this.businessContextService.currentBusiness.businessKey).subscribe(result => {
          if (!result) {
            this.router.navigateByUrl(this.routeUtilities.routes.application.subscriptions.getNavigateUrl(BusinessSubscriptionTypesEnum.IndividualRecurring));
          }
        });
      } else {
        this.router.navigateByUrl(this.routeUtilities.routes.application.base.getNavigateUrl());
      }
    } else {
      //ALSO IN LOGIN COMPONENT
      if (window.location.href.indexOf('?signup=1') !== -1) {
        //http://localhost:4200/login?email=hello@world.com
        //let email = decodeURI(queryStringParts[1]);
        //this.authService.loginWithRedirect({screen_hint: 'signup', login_hint: email });
        this.authService.loginWithRedirect({screen_hint: 'signup' });
        return;
      } else {
        this.authService.loginWithRedirect();
      }
    }
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.routingService.previousRoutes$.next(events[0].urlAfterRedirects);
      });

    this.subscriptions.add(this.pendingChangesService.showPendingChanges$.subscribe(_ => {
      let matDialogRef = this.dialogService.openConfirmationDialog('You have unsaved changes, do you want to continue?', 'Yes', 'No', '130px', '400px');
      matDialogRef.afterClosed().subscribe(result => {
        this.pendingChangesService.pendingChangesResult$.next(result);
      });
    }));
  }
}
