import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MapGeocoder } from '@angular/google-maps';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AddressModel } from 'src/app/shared/models/app/address.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LocationService {
  isApiLoaded: boolean = false;

  constructor(private httpClient: HttpClient, private mapGeocoder: MapGeocoder) {
  }

  loadApi(): Observable<boolean> {
    if (this.isApiLoaded) {
      return of(true);
    }

    return this.httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.googleMapApiKey}`, 'callback')
      .pipe(
        map(() => {
          this.isApiLoaded = true;
          return true;
        }),
        catchError(() => of(false)),
      );
  }

  getCurrentLocation(): Observable<{ latitude: number, longitude: number }> {
    return Observable.create(observer => {
      if (window.navigator && window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            observer.next({latitude: position.coords.latitude, longitude: position.coords.longitude});
            observer.complete();
          },
          (error) => observer.error(error)
        );
      } else {
        observer.error('Unsupported browser to determine GPS position.');
      }
    });
  }

  searchLocationsByAddress(address: string): Observable<any[]> {
    if (!address || typeof address !== 'string') {
      return of([]);
    }

    return this.loadApi().pipe(mergeMap(_ => this.mapGeocoder.geocode({
      address: address,
    }).pipe(map(({results}) => {
      const translatedResults: AddressModel[] = [];

      for (let result of results) {
        translatedResults.push(this.mapAddress(result));
      }

      return translatedResults;
    }))));
  }

  private mapAddress(addressResult): AddressModel {
    let address: AddressModel = {
      addressLineOne: null,
      addressLineTwo: null,
      city: null,
      country: null,
      fullAddress: null,
      postalCode: null,
      state: null,

      addressLineOneMailing: null,
      addressLineTwoMailing: null,
      cityMailing: null,
      countryMailing: null,
      fullAddressMailing: null,
      postalCodeMailing: null,
      stateMailing: null,
      isMailingAddressSame: false,
    };

    let address1 = '';
    let postcode = '';

    for (const component of addressResult.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          address1 = component.long_name + ' ' + address1;
          break;
        }

        case 'route': {
          address1 += component.short_name;
          break;
        }

        case 'postal_code': {
          postcode = component.long_name + postcode;
          break;
        }

        case 'postal_code_suffix': {
          postcode = postcode + '-' + component.long_name;
          break;
        }
        case 'locality':
          address.city = component.long_name;
          break;
        case 'administrative_area_level_1': {
          address.state = component.short_name;
          break;
        }
        case 'country':
          address.country = component.long_name;
          break;
      }
    }

    address.fullAddress = addressResult.formatted_address;
    address.addressLineOne = address1;
    address.postalCode = postcode;
    address.latitude = addressResult.geometry?.location?.lat();
    address.longitude = addressResult.geometry?.location?.lng();

    return address;
  }
}
