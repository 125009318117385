import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { StateModel } from 'src/app/shared/models/domain/state.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class StateService {
  private http: HttpClient;
  private url = environment.staticDataUrl + '/';

  constructor(httpBackend: HttpBackend) {
    this.http = new HttpClient(httpBackend);
  }

  listByCountryCode(countryCode: string): Observable<StateModel[]> {
    //https://github.com/lukes/ISO-3166-Countries-with-Regional-Codes/blob/master/all/all.json
    return this.http.get<StateModel[]>(`${this.url}states.json`).pipe(map(t => {
      return t.filter(x => x.countryCode === countryCode);
    }));
  }
}
