<form [formGroup]="formGroupRef">
  <div class="form-section">
    <div class="form-section-header">Subscription Information</div>
    <div class="form-section-content">
      <div class="mt-2" *ngIf="value?.businessSubscriptionKey">
        <b>Business name: {{businessName}}</b>
      </div>
      <div class="mt-2" *ngIf="!value?.businessSubscriptionKey" formGroupName="business">
        <mat-form-field class="mat-form-field-double" *ngIf="businesses">
          <mat-label>Business</mat-label>
          <input type="text"
                 #businessInput
                 matInput
                 placeholder="Search for business . . ."
                 formControlName="businessKey"
                 [matAutocomplete]="auto">
          <mat-autocomplete [displayWith]="businessDisplayFn.bind(this)" autoActiveFirstOption
                            #auto="matAutocomplete">
            <mat-option *ngFor="let business of filteredBusinesses" [value]="business.businessKey">
              {{business.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="mt-2">
        <mat-form-field class="mr-2">
          <mat-label>Type</mat-label>
          <mat-select [disabled]="true" formControlName="type">
            <mat-option *ngFor="let businessSubscriptionType of businessSubscriptionTypes"
                        [value]="businessSubscriptionType.value">{{businessSubscriptionType.label}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="mt-2" *ngIf="value?.businessSubscriptionKey">
        <mat-slide-toggle [disabled]="value.isDeleted" formControlName="isDeleted">Deleted</mat-slide-toggle>
      </div>
    </div>
  </div>
</form>
