import { Injectable } from '@angular/core';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';
import { Subject } from 'rxjs';
import { LocalStorageUtilites } from 'src/app/core/utilities/app/local-storage.utilites';
import { RoutingUtilities } from 'src/app/routing/routing.utilities';
import { Router } from '@angular/router';

@Injectable()
export class BusinessContextService {
  currentBusiness: BusinessModel;
  currentBusiness$: Subject<BusinessModel> = new Subject<BusinessModel>();
  allBusinesses: BusinessModel[] = [];

  static readonly localStorageKey: string = 'default-business';

  constructor(
  ) {
  }

  initBusinessContext(businesses: BusinessModel[]): void {
    this.currentBusiness = this.getDefaultBusiness();

    this.allBusinesses = businesses || [];

    if (this.currentBusiness && !this.allBusinesses.find(t => t.businessKey === this.currentBusiness.businessKey)) {
      this.currentBusiness = null;
    }

    if (!this.currentBusiness && this.allBusinesses.length) {
      this.currentBusiness = this.allBusinesses[0];
      this.setDefaultBusiness(this.currentBusiness);
    }

    this.updateBusinessContext(this.currentBusiness);
  }

  updateBusinessContext(business: BusinessModel, router: Router = null): void {
    if (business == null) {
      localStorage.removeItem(BusinessContextService.localStorageKey);
      this.currentBusiness = null;
      this.currentBusiness$.next(this.currentBusiness);
    } else if (business.businessKey !== this.currentBusiness?.businessKey) {
      this.currentBusiness = Object.assign({}, business);
      this.currentBusiness$.next(this.currentBusiness);
      this.setDefaultBusiness(this.currentBusiness);
    }

    if (router) {
      this.updateComponent(router);
    }
  }

  updateComponent(router: Router): void {
    RoutingUtilities.reloadPage(router);
  }

  getDefaultBusiness(): BusinessModel {
    if (LocalStorageUtilites.check(BusinessContextService.localStorageKey)) {
      return LocalStorageUtilites.get(BusinessContextService.localStorageKey);
    }
    return null;
  }

  setDefaultBusiness(business: BusinessModel): void {
    LocalStorageUtilites.set(BusinessContextService.localStorageKey, business);
  }
}
