import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { CountryModel } from 'src/app/shared/models/domain/country.model';
import { StateModel } from 'src/app/shared/models/domain/state.model';
import { CountryService } from 'src/app/core/services/domain/country.service';
import { StateService } from 'src/app/core/services/domain/state.service';
import { ReportIdentificationModel } from 'src/app/shared/models/domain/report-identification.model';
import { EnumUtilities } from 'src/app/core/utilities/app/enum.utilities';
import { ReportUtilities } from 'src/app/core/utilities/domain/report-utilities';
import { EnumModel } from 'src/app/shared/models/app/enum.model';
import { ReportDocumentTypesEnum } from 'src/app/shared/enums/domain/report-document-types.enum';
import { TribalModel } from 'src/app/shared/models/domain/tribal.model';
import { TribalService } from 'src/app/core/services/domain/tribal.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';

@Component({
  selector: 'app-report-identification-sub-form',
  templateUrl: './report-identification-sub-form.component.html',
  styleUrls: ['./report-identification-sub-form.component.scss']
})
export class ReportIdentificationSubFormComponent extends BaseFormComponent<ReportIdentificationModel> implements OnInit {
  @Input() readonly: boolean = false;
  @Input() enableDownload: boolean = true;
  @Input() fileList: FileList;
  @Input() identifyingDocumentTypeNumber: string | number = '';
  @Input() identifyingDocumentNumberNumber: string | number = '';
  @Input() countryJurisdictionNumber: string | number = '';
  @Input() stateNumber: string | number = '';
  @Input() localTribeNumber: string | number = '';
  @Input() otherTribeNumber: string | number = '';
  @Input() identifyingDocumentImageNumber: string | number = '';

  @Output() fileListChange: EventEmitter<FileList> = new EventEmitter<FileList>();

  @Output() downloadIdentificationClick = new EventEmitter<string>();

  documentTypes = ReportDocumentTypesEnum;
  documentTypeOptions: EnumModel[] = [];

  countryOptions: CountryModel[];
  stateOptions: StateModel[];
  tribalOptions: TribalModel[];
  otherTribalCode = TribalService.otherCode;
  showModificationWarning = false;

  constructor(private countryService: CountryService, private stateService: StateService, private tribalService: TribalService, private notificationService: NotificationService) {
    super();
  }

  ngOnInit(): void {
    this.documentTypeOptions = EnumUtilities.convertToSelectModels(ReportDocumentTypesEnum, false, ReportUtilities.getDocumentTypeDisplayName);

    super.ngOnInit();

    this.initData();

    this.subscriptions.add(this.formGroupRef.get('countryCode').valueChanges.subscribe(_ => {
      this.onCountryChanged();
    }));
  }

  onFilesUploaded(fileList: FileList): void {
    let validFileTypes = ['image/jpg','image/jpeg','application/pdf','image/png'];

    if (fileList.length) {
      const mimeType = fileList[0].type;
      if (validFileTypes.indexOf(mimeType?.toLowerCase()) === -1) {
        this.notificationService.showErrorNotification('Only jpg, jpeg, pdf, and png file types are supported.');
        return;
      }

      const size = fileList[0].size;
      if (size > 4000000) {
        this.notificationService.showErrorNotification('Only 4mb or less sized files are supported.');
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(fileList[0]);
      // reader.onload = (_event) => {
      //   this.previewFileUrl = <string>reader.result;
      // }

      this.fileList = fileList;
      this.fileListChange.emit(this.fileList);

      this.showModificationWarning = true;

      this.formGroupRef.markAsDirty();
      this.formGroupRef.get('documentFileKey').setValue(null);
      this.formGroupRef.get('documentFileName').setValue(this.fileList[0].name);
      this.formGroupRef.get('hasUploadedFile').setValue(true);

      this.value = this.formGroupRef.value;
      this.valueChange.emit(this.value);
    }
  }

  onDownloadIdentificationClicked(): void {
    this.downloadIdentificationClick.emit();
  }

  onDeleteIdentificationClicked(): void {
    this.formGroupRef.get('documentFileKey').setValue(null);
    this.formGroupRef.get('documentFileName').setValue(null);
    this.formGroupRef.markAsDirty();
    this.showModificationWarning = true;
  }

  onDocumentTypeChanged(): void {
    if (this.value.documentType !== ReportDocumentTypesEnum.StateLocalTribeIssuedIdentification) {
      this.formGroupRef.get('tribalCode').setValue(null);
      this.formGroupRef.get('tribalOther').setValue(null);
    }
  }

  onCountryChanged(isInitialChange: boolean = false): void {
    if (!isInitialChange) {
      this.formGroupRef.get('stateCode').setValue(null);
      this.formGroupRef.get('tribalCode').setValue(null);
    }

    if (this.value.countryCode) {
      this.stateService.listByCountryCode(this.value.countryCode).subscribe(states => {
        this.stateOptions = states;
        this.stateOptions.unshift({code: null, name: '', countryCode: null});
      });
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    return this.formGroupRef;
  }

  private initData() {
    this.countryService.list().subscribe(countries => {
      this.countryOptions = countries;
      this.countryOptions.unshift({code: null, name: ''});
      this.onCountryChanged(true);
    });

    this.tribalService.list().subscribe(tribes => {
      this.tribalOptions = tribes;
      this.tribalOptions.unshift({code: null, name: ''});
    });
  }
}
