<app-base-entry-page-template entryTitle="Manage Report" [value]="value" [formGroupRef]="formGroupRef" [showSave]="false"
                              [isSaving]="isSaving" (saveClick)="onSaveClicked()" [saveText]="saveText">
  <app-base-entry-page-header-template>
    <div *ngIf="isSaving">
      <mat-spinner class="mr-2" [diameter]="30" color="primary"></mat-spinner>
    </div>
  </app-base-entry-page-header-template>
  <app-base-entry-page-content-template>
    <app-report-form *ngIf="value"
                       [isAdmin]="isAdmin"
                       [readonly]="status === ReportStatusesEnum.Completed || status === ReportStatusesEnum.Submitted"
                       [(selectedTabIndex)]="selectedTabIndex"
                       [(formGroupRef)]="formGroupRef"
                       [(fileList)]="fileList"
                       (beforeTabChange)="onBeforeTabChanged($event)"
                       [(value)]="value">
    </app-report-form>
  </app-base-entry-page-content-template>
  <app-base-entry-page-footer-template>
    <ng-container *ngIf="value">
      <app-save-button class="nav-btn mr-2" [isDisabled]="selectedTabIndex === 0" text="<< Previous" (click)="onSelectedTabChanged(-1)"></app-save-button>
      <app-save-button class="nav-btn mr-2" [isDisabled]="(selectedTabIndex === reportTabIndexes.Submit && !isAdmin) || (selectedTabIndex === reportTabIndexes.Admin && isAdmin)" text="Next >>" (click)="onSelectedTabChanged(1)"></app-save-button>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <app-save-button [isDisabled]="status === ReportStatusesEnum.Completed || status === ReportStatusesEnum.Submitted || status === ReportStatusesEnum.Deleted" class="mr-2" text="Save" [isSaving]="isSaving" (click)="onSaveClicked()"></app-save-button>
      <app-save-button [isDisabled]="!isAdmin && (status === ReportStatusesEnum.Completed || status === ReportStatusesEnum.Submitted || status === ReportStatusesEnum.Deleted)" class="mr-2" *ngIf="(selectedTabIndex === reportTabIndexes.Submit && !isAdmin) || (selectedTabIndex === reportTabIndexes.Admin && isAdmin)" [text]="isAdmin ? 'Update' : 'Submit'" [isSaving]="isSaving" (click)="onSaveClicked(true)"></app-save-button>
      <app-save-button [isDisabled]="status !== ReportStatusesEnum.Draft" class="mr-2" text="Delete" *ngIf="value?.reportKey" [isSaving]="isSaving" (click)="onDeleteClicked()"></app-save-button>
      <app-save-button text="Cancel" (click)="onCancelClicked()"></app-save-button>
    </ng-container>
  </app-base-entry-page-footer-template>
</app-base-entry-page-template>
