<form [formGroup]="formGroupRef">
  <div class="mt-2" *ngIf="showAddressType">
    <div>
      <span class="mat-label required">{{addressTypeLabel}}</span>
    </div>
    <div>
      <mat-radio-group
        [disabled]="readonly"
        formControlName="addressType">
        <mat-radio-button class="mr-2" [value]="reportAddressTypes.BusinessAddress">Business Address</mat-radio-button>
        <mat-radio-button [value]="reportAddressTypes.ResidentialAddress">Residential Address</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="mt-2">
    <mat-form-field class="mat-form-field-double">
      <mat-label class="required">{{addressLabel}}</mat-label>
      <input [readonly]="readonly" matInput
             type="text" formControlName="address">
    </mat-form-field>
  </div>
  <div class="mt-2">
    <mat-form-field class="mat-form-field-double">
      <mat-label class="required">{{cityLabel}}</mat-label>
      <input [readonly]="readonly" matInput type="text" formControlName="city">
    </mat-form-field>
  </div>
  <div class="mt-2">
    <mat-form-field class="mat-form-field-double">
      <mat-label class="required">{{countryLabel}}</mat-label>
      <mat-select [disabled]="readonly"
                  formControlName="countryCode">
        <ng-container *ngFor="let option of countryOptions">
          <mat-option [value]="option.code">{{option.name}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="mt-2">
    <mat-form-field class="mat-form-field-double">
      <mat-label class="required">{{stateLabel}}</mat-label>
      <mat-select [disabled]="readonly || !stateOptions?.length"
                  formControlName="stateCode">
        <ng-container *ngFor="let option of stateOptions">
          <mat-option [value]="option.code">{{option.name}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="mt-2">
    <mat-form-field class="mat-form-field-double">
      <mat-label class="required">{{postalCodeLabel}}</mat-label>
      <input [readonly]="readonly" matInput placeholder="e.g.: 20500" type="text" formControlName="postalCode">
    </mat-form-field>
  </div>
</form>
