export enum ReportStatusesEnum {
  Draft = 0,
  Incomplete = 5,
  Submitted = 10,
  InReview = 15,
  Completed = 20,

  UploadFailed = 50,
  Deleted = 100
}
