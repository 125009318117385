import { AfterViewInit, Component, EventEmitter, forwardRef, Input, Output, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { DateUtilities } from 'src/app/core/utilities/app/date.utilities';
import { DatePickerAdapter } from 'src/app/shared/components/controls/date-picker/date-picker.adapter';

@Component({
  selector: 'app-datepicker',
  templateUrl: './date-picker.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: DatePickerAdapter,
    }, {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    }
  ]
})
export class DatepickerComponent implements ControlValueAccessor, AfterViewInit {
  @Input('value') _value: any;
  @Input() required: boolean = false;
  @Input() canShowValidation: boolean;
  @Input() disabled: boolean;
  @Input() focus: boolean = false;
  @Input() format;
  @Input() label: string = '';
  @Input() isLocaleAware: boolean = false;
  @Input() readonly: boolean;
  @Input() minDate: Date = null;
  @Input() maxDate: Date = null;
  @Input() suppressInitialChangeEvent = false;
  @Input() isSkinnyMatFormField: boolean = false;
  @Output() dateChanged: EventEmitter<Date> = new EventEmitter();
  @Input() helpText: string = null;

  formControl: UntypedFormControl;

  get value(): Date {
    return this._value;
  }

  set value(val: Date) {
    let outDate = moment(val).isValid() ? moment(val).toDate() : null;

    if (val && !this.isLocaleAware) {
      val = DateUtilities.convertToUtcDate(val);
      outDate = DateUtilities.convertToZeroDate(val);
    } else {
      val = moment(val).toDate();
    }

    this._value = val;

    // do not fire value when initially set to null
    if (!this.suppressInitialChangeEvent) {
      this.onChange(outDate);
      this.dateChanged.emit(outDate);
      this.onTouched();
    }
    this.suppressInitialChangeEvent = false;
  }

  constructor(private renderer: Renderer2, private dateAdapter: DateAdapter<DatePickerAdapter>) {
  }

  onChange: Function = () => { };
  onTouched: Function = () => { };

  ngAfterViewInit() {
    if (this.focus) {
      this.setFocus();
    }

    if (this.format) {
      this.dateAdapter['formatString'] = this.format;
    }
  }

  onBlur(): void {
    this.onTouched();
  }

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  validate(formControl: UntypedFormControl) {
    this.formControl = formControl;
  }

  writeValue(value): void {
    if (value && moment(value).isValid()) {
      const convertedDate = DateUtilities.convertToUtcDate(value);
      if (!this.isLocaleAware && this.value !== convertedDate) {
        this.value = convertedDate;
      } else {
        const momentDate = moment(value).toDate();
        if (this.value !== momentDate) {
          this.value = momentDate;
        }
      }
    } else if (!value && this._value) {
      this.value = null;
    }
  }

  setFocus(): void {
    this.renderer.selectRootElement('input').focus();
  }
}


