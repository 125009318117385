import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReportNotificationToSendModel } from 'src/app/shared/models/domain/report-notification-to-send.model';


@Injectable()
export class ReportNotificationService {
  private url = environment.apiUrl + '/report-notifications/';

  constructor(private http: HttpClient) {
  }

  create(model: ReportNotificationToSendModel): Observable<boolean> {
    return this.http.post<boolean>(this.url, model);
  }
}
