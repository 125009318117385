import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ReportActivityModel } from 'src/app/shared/models/domain/report-activity.model';
import { ReportActivitySearchModel } from 'src/app/shared/models/domain/report-activity-search.model';

@Injectable()
export class ReportActivityService {
  private url = environment.apiUrl + '/report-activities/';

  constructor(private http: HttpClient) {
  }
  search(model: ReportActivitySearchModel = {}): Observable<ReportActivityModel[]> {
    return this.http.post<ReportActivityModel[]>(`${this.url}search`, model);
  }

  createReport(model: ReportActivityModel): Observable<string> {
    return this.http.post<string>(`${this.url}`, model)
  }
}
