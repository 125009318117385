import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from '@auth0/auth0-angular';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { Router } from '@angular/router';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { BusinessSubscriptionService } from 'src/app/core/services/domain/business-subscription.service';

@Component({
  selector: 'app-application-page',
  templateUrl: './application-page.component.html',
  styleUrls: ['./application-page.component.scss']
})
export class ApplicationPageComponent extends BasePageComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;

  year = new Date().getFullYear();
  hasValidSubscription = false;

  constructor(
    public authClientService: AuthClientService,
    public businessContextService: BusinessContextService,
    private businessSubscriptionService: BusinessSubscriptionService,
    private authService: AuthService,
    private router: Router) {
    super();
  }

  ngOnInit(): void {
    if (!this.authClientService.client.isEmailVerified) {
      this.router.navigate([this.routeUtilities.routes.application.profileSetup.getNavigateUrl()]);
    } else {
      this.subscriptions.add(this.businessContextService.currentBusiness$.subscribe(t => {
        this.businessSubscriptionService.hasValidSubscription(this.businessContextService.currentBusiness.businessKey).subscribe(result => {
          this.hasValidSubscription = result;
        });
      }));
    }

    if (this.businessContextService.currentBusiness?.businessKey) {
      this.businessSubscriptionService.hasValidSubscription(this.businessContextService.currentBusiness.businessKey).subscribe(result => {
        this.hasValidSubscription = result;
      });
    }

    this.subscriptions.add(this.businessSubscriptionService.subscriptionChanged$.subscribe(_ => {
      this.businessSubscriptionService.hasValidSubscription(this.businessContextService.currentBusiness.businessKey).subscribe(result => {
        this.hasValidSubscription = result;
      });
    }));
  }

  onDashboardClicked(businessKey: string = null): void {
    this.router.navigateByUrl(RouteUtilities.routes.application.dashboard.getNavigateUrl(businessKey));
  }

  onChangeBusinessClicked(business: BusinessModel): void {
    this.businessContextService.updateBusinessContext(business);

    this.reloadPage(this.router, RouteUtilities.routes.application.dashboard.getNavigateUrl());
  }

  onNavigateClicked(route: string): void {
    this.router.navigateByUrl(route);
  }

  onLogoutClicked(): void {
    this.authClientService.client = null;
    this.authService.logout({ returnTo: document.location.origin })
  }
}
