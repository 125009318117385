import { Component, Input, OnInit } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { UntypedFormGroup } from '@angular/forms';
import { ReportNotificationModel } from 'src/app/shared/models/domain/report-notification-model';

@Component({
  selector: 'app-report-notification-sub-form',
  templateUrl: './report-notification-sub-form.component.html',
  styleUrls: ['./report-notification-sub-form.component.scss']
})
export class ReportNotificationSubFormComponent extends BaseFormComponent<ReportNotificationModel> implements OnInit {
  @Input() readonly = false;

  constructor() {
    super();
  }

  protected getFormGroup(): UntypedFormGroup {
    return this.formGroupRef;
  }
}
