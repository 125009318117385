<app-base-grid-template gridTitle="Access" (addButtonClick)="onAddClicked()">
  <app-base-grid-content-template>
    <ag-grid-angular *ngIf="gridOptions"
                     class="ag-theme-alpine"
                     [gridOptions]="gridOptions">
    </ag-grid-angular>
  </app-base-grid-content-template>
</app-base-grid-template>



