import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { BusinessSubscriptionModel } from 'src/app/shared/models/domain/business-subscription.model';
import { BusinessSubscriptionService } from 'src/app/core/services/domain/business-subscription.service';
import { UntypedFormGroup } from '@angular/forms';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { RoutingService } from 'src/app/core/services/app/routing.service';

@Component({
  selector: 'app-business-subscription-dialog',
  templateUrl: './business-subscription-dialog.component.html',
  styleUrls: ['./business-subscription-dialog.component.scss']
})
export class BusinessSubscriptionDialogComponent extends BaseEntryPageComponent<BusinessSubscriptionModel> implements OnInit {
  value: BusinessSubscriptionModel;
  formGroupRef: UntypedFormGroup = new UntypedFormGroup({});

  constructor(
    private dialogRef: MatDialogRef<BusinessSubscriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { businessSubscriptionKey: string },
    private businessSubscriptionService: BusinessSubscriptionService,
    routingService: RoutingService,
    notificationService: NotificationService,
    pendingChangesService: PendingChangesService) {
      super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    if (this.dialogData.businessSubscriptionKey) {
      this.businessSubscriptionService.get(this.dialogData.businessSubscriptionKey).subscribe(businessSubscription => {
        this.value = businessSubscription;
      });
    } else {
      this.value = <BusinessSubscriptionModel>{};
    }
  }

  onSaveClicked(): void {
    if (!this.value.businessSubscriptionKey) {
      this.businessSubscriptionService.create(this.value).subscribe(result => {
        this.notificationService.showSuccessNotification('Subscription created successfully.');
        this.dialogRef.close(true);
      }, err => {
        this.onHttpFailure(this.notificationService, err);
      });
    } else {
      this.businessSubscriptionService.update(this.value).subscribe(result => {
        this.notificationService.showSuccessNotification('Subscription updated successfully.');
        this.dialogRef.close(true);
      }, err => {
        this.onHttpFailure(this.notificationService, err);
      });
    }

  }

  onCancelClicked(): void {
    this.dialogRef.close(false);
  }
}
