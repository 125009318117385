import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';

@Component({
  selector: 'app-sandbox-page',
  templateUrl: './sandbox-page.component.html',
  styleUrls: ['./sandbox-page.component.scss']
})
export class SandboxPageComponent extends BasePageComponent implements OnInit {
  data: any[] = [];
  progress: any;

  business: BusinessModel;

  constructor(

  ) {
    super();
  }

  ngOnInit(): void {

  }

  onRefreshClicked(): void {

  }
}
