import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ConfirmationDialogComponent
} from 'src/app/shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {
  PdfViewerDialogComponent
} from 'src/app/shared/components/dialogs/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { FileModel } from 'src/app/shared/models/domain/file.model';
import { VideoDialogComponent } from 'src/app/shared/components/dialogs/video-dialog/video-dialog.component';
import { ReportStatusesEnum } from 'src/app/shared/enums/domain/report-statuses-enum';
import {
  ReportsGridDialogComponent
} from 'src/app/shared/components/dialogs/reports-grid-dialog/reports-grid-dialog.component';
import {
  BusinessSubscriptionDialogComponent
} from 'src/app/shared/components/dialogs/business-subscription-dialog/business-subscription-dialog.component';
import { BusinessSubscriptionModel } from 'src/app/shared/models/domain/business-subscription.model';
import {
  SendNotificationDialogComponent
} from 'src/app/shared/components/dialogs/send-notification-dialog/send-notification-dialog.component';
import { ReportModel } from 'src/app/shared/models/domain/report.model';
import { ReportBeneficialOwnerModel } from 'src/app/shared/models/domain/report-beneficial-owner.model';
import { ReportCompanyApplicantModel } from 'src/app/shared/models/domain/report-company-applicant.model';
import { ReportIndividualModel } from 'src/app/shared/models/domain/report-individual.model';
import {
  AddAccessDialogComponent
} from 'src/app/shared/components/dialogs/add-access-dialog/add-access-dialog.component';

@Injectable()
export class DialogService {
  constructor(private matDialog: MatDialog) {
  }

  openConfirmationDialog(text: string = null, okText: string = null, cancelText: string = null, height: string = '130px', width: string = '350px', extraButtonText: string = null): MatDialogRef<ConfirmationDialogComponent> {
    return this.matDialog.open(ConfirmationDialogComponent, {
      height: height,
      width: width,
      data: {
        text,
        okText,
        cancelText,
        extraButtonText
      }
    });
  }

  openSendNotificationDialog(report: ReportModel, reportIndividual: ReportIndividualModel, reportBeneficialOwner: ReportBeneficialOwnerModel, reportCompanyApplicant: ReportCompanyApplicantModel): MatDialogRef<SendNotificationDialogComponent> {
    return this.matDialog.open(SendNotificationDialogComponent, {
      height: '400px',
      width: '480px',
      data: {
        report,
        reportIndividual,
        reportBeneficialOwner,
        reportCompanyApplicant
      }
    });
  }

  openAddAccessDialog(businessKey: string, email: string, lastName: string, firstName: string): MatDialogRef<AddAccessDialogComponent> {
    return this.matDialog.open(AddAccessDialogComponent, {
      height: '600px',
      width: '480px',
      data: {
       businessKey,
       email,
        lastName,
        firstName
      }
    });
  }

  openPdfViewer(file: FileModel): MatDialogRef<PdfViewerDialogComponent> {
    return this.matDialog.open(PdfViewerDialogComponent, {
      height: '800px',
      width: '1000px',
      data: {
        file,
      }
    });
  }

  openVideo(data: {src: string, title: string, description: string}): MatDialogRef<VideoDialogComponent> {
    return this.matDialog.open(VideoDialogComponent, {
      height: '520px',
      width: '650px',
      data: {
        video: data
      }
    });
  }

  openReportsGrid(data: {businessKey: string, status: ReportStatusesEnum}): MatDialogRef<ReportsGridDialogComponent> {
    return this.matDialog.open(ReportsGridDialogComponent, {
      height: '600px',
      width: '1024px',
      data
    });
  }

  openBusinessSubscription(businessSubscriptionKey: string = null): MatDialogRef<BusinessSubscriptionDialogComponent> {
    return this.matDialog.open(BusinessSubscriptionDialogComponent, {
      height: '400px',
      width: '500px',
      data: {
        businessSubscriptionKey
      }
    });
  }
}
