<div>
  You are about to send an identification request reminder.
</div>
<div class="mt-1">
  Recipient: <b>{{dialogData.reportIndividual.email}}</b>
</div>
<div>
  Business: <b>{{dialogData.report.legalName}}</b>
</div>
<form [formGroup]="formGroupRef">
  <div class="field-row mt-2">
    <mat-form-field class="mat-form-field-double">
      <mat-label>Additional Message</mat-label>
      <textarea
        #autosize="cdkTextareaAutosize"
        matInput
        cdkTextareaAutosize
        cdkAutosizeMinRows="5"
        cdkAutosizeMaxRows="5"
        formControlName="message">
      </textarea>
    </mat-form-field>
  </div>
  <div class="field-row mt-2">
    <mat-form-field class="mat-form-field-double">
      <mat-label>BCC Email</mat-label>
      <input matInput type="text" formControlName="copyToEmail">
    </mat-form-field>
  </div>
</form>
<div class="mt-2">
  <span class="mr-2">
    <button color="primary" [disabled]="isSending" mat-flat-button color="primary" (click)="onSendClicked()">Send</button>
  </span>
  <span>
    <button mat-flat-button color="primary" (click)="onCancelClicked()">Cancel</button>
  </span>
</div>
