import { RoutingKeysUtilities } from 'src/app/routing/routing-keys.utilities';
import { ReportStatusesEnum } from 'src/app/shared/enums/domain/report-statuses-enum';
import { BusinessSubscriptionTypesEnum } from 'src/app/shared/enums/domain/business-subscription-types.enum';

export class RouteUtilities {
  static routes = {
    application: {
      // @ts-ignore
      base: {
        modulePath: 'application',
        getNavigateUrl: () => {
          return '/application';
        }
      },
      redirect: {
        modulePath: 'redirect',
        getNavigateUrl: () => {
          return '/application/redirect';
        }
      },
      dashboard: {
        modulePath: 'dashboard',
        getNavigateUrl: (businessKey: string = null) => {
          if (businessKey) {
            return `/application/dashboard?businessKey=${businessKey}`;
          }

          return '/application/dashboard';
        }
      },
      businesses: {
        modulePath: 'businesses',
        getNavigateUrl: () => {
          return '/application/businesses';
        }
      },
      businessAdd: {
        modulePath: 'business',
        getNavigateUrl: () => {
          return '/application/businesses/business';
        }
      },
      businessEdit: {
        modulePath: `business/:${RoutingKeysUtilities.routeParams.businessKey}`,
        getNavigateUrl: (businessKey: string, tabIndex: number = null) => {
          let url = `/application/businesses/business/${businessKey}`;

          if (tabIndex) {
            url += '?' + RoutingKeysUtilities.queryParams.selectedTabIndex + '=' + tabIndex;
          }

          return url;
        }
      },
      businessAccess: {
        modulePath: `access`,
        getNavigateUrl: (businessKey: string) => {
          return `/application/businesses/business/${businessKey}/access`;
        }
      },
      profile: {
        modulePath: 'profile',
        getNavigateUrl: () => {
          return '/application/profile';
        }
      },
      profileSetup: {
        modulePath: 'profile-setup',
        getNavigateUrl: () => {
          return '/application/profile-setup';
        }
      },
      reports: {
        modulePath: 'reports',
        getNavigateUrl: (status: ReportStatusesEnum = null) => {
          if (status !== null) {
            return `/application/reports?statusId=${status}`
          }

          return '/application/reports';
        }
      },
      reportAdd: {
        modulePath: 'report',
        getNavigateUrl: (businessKey: string) => {
          if (businessKey) {
            return `/application/reports/report?businessKey=${businessKey}`;
          }

          return '/application/reports/report';
        }
      },
      reportEdit: {
        modulePath: `report/:${RoutingKeysUtilities.routeParams.reportKey}`,
        getNavigateUrl: (reportKey: string) => {
          return `/application/reports/report/${reportKey}`;
        }
      },
      reportActivities: {
        modulePath: 'report-activities',
        getNavigateUrl: () => {
          return '/application/report-activities';
        }
      },
      reportIndividuals: {
        modulePath: 'report-individuals',
        getNavigateUrl: () => {
          return '/application/report-individuals';
        }
      },
      admin: {
        base: {
          modulePath: 'admin',
          getNavigateUrl: () => {
            return '/application/admin';
          }
        },
        dashboard: {
          modulePath: 'dashboard',
          getNavigateUrl: () => {
            return '/application/admin/dashboard';
          }
        },
        clients: {
          modulePath: 'clients',
          getNavigateUrl: () => {
            return '/application/admin/clients';
          }
        },
        clientAdd: {
          modulePath: 'client',
          getNavigateUrl: () => {
            return '/application/admin/clients/client';
          }
        },
        clientEdit: {
          modulePath: `client/:${RoutingKeysUtilities.routeParams.clientKey}`,
          getNavigateUrl: (clientKey: string) => {
            return `/application/admin/clients/client/${clientKey}`;
          }
        },
        businesses: {
          modulePath: 'businesses',
          getNavigateUrl: () => {
            return '/application/admin/businesses';
          }
        },
        subscriptions: {
          modulePath: 'business-subscriptions',
          getNavigateUrl: () => {
            return '/application/admin/business-subscriptions';
          }
        },
        businessAdd: {
          modulePath: 'business',
          getNavigateUrl: () => {
            return '/application/admin/businesses/business';
          }
        },
        businessEdit: {
          modulePath: `business/:${RoutingKeysUtilities.routeParams.businessKey}`,
          getNavigateUrl: (businessKey: string, tabIndex: number = null) => {
            let url = `/application/admin/businesses/business/${businessKey}`;

            if (tabIndex) {
              url += '?' + RoutingKeysUtilities.queryParams.selectedTabIndex + '=' + tabIndex;
            }

            return url;
          }
        },
        businessAccess: {
          modulePath: `access`,
          getNavigateUrl: (businessKey: string) => {
            return `/application/admin/businesses/business/${businessKey}/access`;
          }
        },
        reports: {
          modulePath: 'reports',
          getNavigateUrl: (status: ReportStatusesEnum = null) => {
            if (status !== null) {
              return `/application/admin/reports?statusId=${status}`
            }

            return '/application/admin/reports';
          }
        },
        reportAdd: {
          modulePath: 'reports',
          getNavigateUrl: () => {
            return '/application/admin/reports/report';
          }
        },
        reportEdit: {
          modulePath: `reports/:${RoutingKeysUtilities.routeParams.reportKey}`,
          getNavigateUrl: (reportKey: string) => {
            return `/application/admin/reports/report/${reportKey}`;
          }
        },
        reportActivities: {
          modulePath: 'report-activities',
          getNavigateUrl: () => {
            return '/application/admin/report-activities';
          }
        },
        reportIndividuals: {
          modulePath: 'report-individuals',
          getNavigateUrl: () => {
            return '/application/admin/report-individuals';
          }
        },
        sandbox: {
          modulePath: 'sandbox',
          getNavigateUrl: () => {
            return '/application/admin/sandbox';
          }
        }
      },
      privacyPolicy: {
        modulePath: 'privacy-policy',
        getNavigateUrl: () => {
          return '/application/privacy-policy';
        }
      },
      subscriptions: {
        modulePath: 'subscriptions',
        getNavigateUrl: (type: BusinessSubscriptionTypesEnum = null) => {
          if (type) {
            return '/application/subscriptions?type=' + (type ?? BusinessSubscriptionTypesEnum.IndividualRecurring);
          }

          return '/application/subscriptions';
        }
      },
      pricing: {
        modulePath: 'pricing',
        getNavigateUrl: () => {
          return '/application/pricing';
        }
      },
      terms: {
        modulePath: 'terms-conditions',
        getNavigateUrl: () => {
          return '/application/terms-conditions';
        }
      }
    },
    static: {
      base: {
        modulePath: '',
        getNavigateUrl: () => {
          return '#home';
        }
      },
      login: {
        modulePath: 'login',
        getNavigateUrl: () => {
          return '/login';
        }
      },
      reportNotificationTokens: {
        modulePath: `report-notification-tokens/:${RoutingKeysUtilities.routeParams.reportNotificationToken}`,
        getNavigateUrl: (token: string) => {
          return `/report-notification-tokens/${token}`;
        }
      },
      pricing: {
        modulePath: `pricing`,
        getNavigateUrl: () => {
          return `/pricing`;
        }
      },
      privacyPolicy: {
        modulePath: 'privacy-policy',
        getNavigateUrl: () => {
          return '/privacy-policy';
        }
      },
      terms: {
        modulePath: 'terms-conditions',
        getNavigateUrl: () => {
          return '/terms-conditions';
        }
      }
    }
  }
}
