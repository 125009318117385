import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { BusinessSubscriptionService } from 'src/app/core/services/domain/business-subscription.service';
import { environment } from 'src/environments/environment';
import { BusinessSubscriptionTypesEnum } from 'src/app/shared/enums/domain/business-subscription-types.enum';
import { RoutingUtilities } from 'src/app/routing/routing.utilities';
import { Router } from '@angular/router';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { BusinessSubscriptionModel } from 'src/app/shared/models/domain/business-subscription.model';

@Component({
  selector: 'app-business-subscription-payment-form',
  templateUrl: './business-subscription-payment-form.component.html',
  styleUrls: ['./business-subscription-payment-form.component.scss']
})
export class BusinessSubscriptionPaymentFormComponent extends BaseFormComponent<BusinessSubscriptionModel> implements OnInit, OnDestroy {
  @Input() type: BusinessSubscriptionTypesEnum;
  @Input() hideTypeSelection = false;

  @ViewChild('checkoutContainerOneTime') private checkoutContainerOneTime: ElementRef;
  @ViewChild('checkoutContainerRecurring') private checkoutContainerRecurring: ElementRef;

  stripe: any;
  checkout: any;
  businessSubscriptionTypes = BusinessSubscriptionTypesEnum;

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private businessSubscriptionService: BusinessSubscriptionService) {
    super()
  }

  ngOnInit(): void {
    this.stripe = window['Stripe'](environment.stripe.publicApiKey);

    super.ngOnInit();

    this.initCheckout();
  }

  ngOnDestroy() {
    if (this.checkout) {
      this.checkout.embeddedCheckout.destroy();
    }

    if (this.checkoutContainerOneTime) {
      this.checkoutContainerOneTime.nativeElement.remove();
    }

    if (this.checkoutContainerRecurring) {
      this.checkoutContainerRecurring.nativeElement.remove();
    }

    super.ngOnDestroy();
  }

  onTypeChanged(): void {
    if (this.value.type === BusinessSubscriptionTypesEnum.Enterprise) {
      window['Calendly'].initInlineWidget({
        url: environment.calendyUrl,
        parentElement: document.querySelector('.calendly-inline-widget'),
      });
    } else {
      RoutingUtilities.reloadPage(this.router, RouteUtilities.routes.application.subscriptions.getNavigateUrl(this.value.type));
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      business: new UntypedFormControl(this.value?.business),
      type: new UntypedFormControl(this.type),
      //hasAcceptedTerms: new UntypedFormControl(false),
      sessionId: new UntypedFormControl(this.value?.sessionId),
      isActive: new UntypedFormControl(true),
      isDeleted: new UntypedFormControl(false)
    });
  };

  private initCheckout(): void {
    if (!this.value.type) {
      return;
    }

    if (this.checkout) {
      this.checkout.embeddedCheckout.destroy();
    }

    this.businessSubscriptionService.createPaymentSession(this.value).subscribe(businessSubscription => {
      this.stripe['initEmbeddedCheckout']({
        clientSecret: businessSubscription.sessionId
      }).then(checkout => {
        if (this.type === BusinessSubscriptionTypesEnum.IndividualRecurring) {
          checkout.mount('#checkout-recurring');
        } else {
          checkout.mount('#checkout-one-time');
        }

          this.checkout = checkout;
      }, err => {
        this.notificationService.showErrorNotification('There was a problem loading the checkout page.');
        throw err;
      });
    });
  }
}
