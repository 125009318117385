<div style="padding: 5px;">
  <div class="text-center mb-1 mt-1">
    <img style="width:90%; max-width:500px;" src="https://fincenboireportingprod.blob.core.windows.net/public/logo.png">
    <hr>
  </div>
  <app-blocking-loader *ngIf="!isLoaded"></app-blocking-loader>
  <ng-container *ngIf="isLoaded && errorMessage">
    <div *ngIf="errorMessage" class="full-width text-center font-size-22 error">
      <div>
        {{errorMessage}}
      </div>
      <div class="mt-2">
        Please contact your BOI Reporting representative.
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isLoaded && !errorMessage">
    <app-base-entry-page-template [value]="value" [formGroupRef]="formGroupRef" [showSave]="false"
                                  [isSaving]="isSaving" (saveClick)="onSaveClicked()" [saveText]="saveText">
      <app-base-entry-page-content-template>
        <div class="font-size-18 bold">
          <div>
            {{businessName}}
          </div>
          <div>
            Identification Submission
          </div>
        </div>

        <div>
          <app-report-notification-token-form *ngIf="value"
                                              [(selectedTabIndex)]="selectedTabIndex"
                                              [(formGroupRef)]="formGroupRef"
                                              [(fileList)]="fileList"
                                              (beforeTabChange)="onBeforeTabChanged($event, true)"
                                              [(value)]="value">
          </app-report-notification-token-form>
        </div>
      </app-base-entry-page-content-template>
      <app-base-entry-page-footer-template>
        <div>
          <app-save-button class="nav-btn mr-2" [isDisabled]="selectedTabIndex === 0" text="<< Previous"
                           (click)="onSelectedTabChanged(-1, true)"></app-save-button>
          <app-save-button class="nav-btn mr-2" [isDisabled]="selectedTabIndex === submitTabIndex" text="Next >>"
                           (click)="onSelectedTabChanged(1, true)"></app-save-button>
          <app-save-button class="mr-2" *ngIf="selectedTabIndex === submitTabIndex" text="Submit"
                           [isSaving]="isSaving"
                           (click)="onSaveClicked(true)"></app-save-button>
        </div>
      </app-base-entry-page-footer-template>
    </app-base-entry-page-template>
  </ng-container>
</div>
<!--<app-blocking-loader *ngIf="isSaving"></app-blocking-loader>-->
