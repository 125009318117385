import { Observable, of, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { BusinessSubscriptionModel } from 'src/app/shared/models/domain/business-subscription.model';
import { BusinessSubscriptionSearchModel } from 'src/app/shared/models/domain/business-subscription-search.model';

@Injectable()
export class BusinessSubscriptionService {
  cachedSubscriptions = {};
  private url = environment.apiUrl + '/business-subscriptions/';

  subscriptionChanged$: Subject<void> = new Subject();

  constructor(private http: HttpClient, private authClientService: AuthClientService) {
  }

  get(businessSubscriptionKey: string): Observable<BusinessSubscriptionModel> {
    return this.http.get<BusinessSubscriptionModel>(`${this.url}${businessSubscriptionKey}`);
  }

  canCreateReport(businessKey: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.url}${businessKey}/can-create`);
  }

  createPaymentSession(model: BusinessSubscriptionModel): Observable<BusinessSubscriptionModel> {
    return this.http.post<any>(`${this.url}session`, model);
  }

  hasValidSubscription(businessKey: string): Observable<boolean> {
    if (this.cachedSubscriptions[businessKey]) {
      return of(this.cachedSubscriptions[businessKey]);
    }

    //NO BUSINESS KEY WHEN RUNNING THE CREATE ROUTE
    if (businessKey === null || this.authClientService.isAdmin) {
      return of(true);
    }

    return this.http.get<boolean>(`${this.url}${businessKey}/status`).pipe(tap(result => {
      this.cachedSubscriptions[businessKey] = result;
    }));
  }

  search(model: BusinessSubscriptionSearchModel): Observable<BusinessSubscriptionModel[]> {
    return this.http.post<BusinessSubscriptionModel[]>(`${this.url}search`, model);
  }

  createFromSessionId(sessionId: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.url}session/${sessionId}`, null);
  }

  create(model: BusinessSubscriptionModel): Observable<string> {
    return this.http.post<string>(`${this.url}`, model);
  }

  update(model: BusinessSubscriptionModel): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}`, model);
  }

  delete(businessKey: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.url}${businessKey}`);
  }

  clearCache(): void {
    this.cachedSubscriptions = {};
    this.subscriptionChanged$.next();
  }
}
