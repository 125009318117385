<form [formGroup]="formGroupRef" autocomplete="off">
  <ng-container *ngIf="isProfileSetup">
    <div class="flex flex-wrap">
      <div class="flex">
        <div class="form-section">
          <div class="form-section-header">
            <span>Business Profile</span>
          </div>
          <div class="mt-2">
            <ng-container *ngIf="value?.businessKey">
              <b>Legal Name: {{value?.legalName}}</b>
            </ng-container>
            <ng-container *ngIf="!value?.businessKey">
              <mat-form-field class="mr-2 mat-form-field-double">
                <mat-label>Legal Name</mat-label>
                <input (blur)="onLegalNameBlurred()" [readonly]="value.businessKey" matInput type="text" formControlName="legalName">
                <mat-icon matSuffix matTooltip="Business name to be used on Beneficial Ownership Information report.">
                  help_outline
                </mat-icon>
              </mat-form-field>
            </ng-container>
          </div>
          <div class="mt-2">
            <mat-form-field class="mr-2  mat-form-field-double">
              <mat-label>Name</mat-label>
              <input matInput type="text" formControlName="name">
              <mat-icon matSuffix matTooltip="Business name as you want to refer to it for display purposes only.">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
<!--          <div class="mt-2">-->
<!--            <mat-form-field class="mr-2 mat-form-field-double">-->
<!--              <mat-label>Phone</mat-label>-->
<!--              <input matInput type="text" formControlName="phoneNumber">-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--          <div class="mt-2">-->
<!--            <mat-form-field class="mr-2 mat-form-field-double">-->
<!--              <mat-label>Email</mat-label>-->
<!--              <input matInput type="text" formControlName="email">-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--          <div class="mt-2">-->
<!--            <mat-form-field class="mr-2 mat-form-field-double">-->
<!--              <mat-label>Website</mat-label>-->
<!--              <input matInput type="text" (blur)="onWebsiteBlurred()" formControlName="website"-->
<!--                     placeholder="https://google.com">-->
<!--            </mat-form-field>-->
<!--          </div>-->
        </div>
      </div>
<!--      <div class="flex">-->
<!--        <div>-->
<!--          <div class="form-section-header">-->
<!--            <span>Business Address</span>-->
<!--          </div>-->
<!--          <app-address-sub-form-->
<!--            [showGPS]="false"-->
<!--            [showSecondaryAddress]="!isProfileSetup"-->
<!--            [(value)]="value.address"-->
<!--            [formGroupRef]="getFormGroupFromAbstractControl(formGroupRef.get('address'))">-->
<!--          </app-address-sub-form>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </ng-container>
  <ng-container *ngIf="!isProfileSetup">
    <mat-tab-group #matTabGroup [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onSelectedTabChanged()">
      <mat-tab label="My Business">
        <div class="form-section mt-2">
          <div class="mt-2">
            <mat-form-field class="mr-2 mat-form-field-double">
              <mat-label>Legal Name</mat-label>
              <input (blur)="onLegalNameBlurred()" [readonly]="value.businessKey" matInput type="text" formControlName="legalName">
              <mat-icon matSuffix matTooltip="Business name to be used on Beneficial Ownership Information report.">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
          <div class="mt-2">
            <mat-form-field class="mr-2  mat-form-field-double">
              <mat-label>Name</mat-label>
              <input matInput type="text" formControlName="name">
              <mat-icon matSuffix matTooltip="Business name as you want to refer to it for display purposes only.">
                help_outline
              </mat-icon>
            </mat-form-field>
          </div>
<!--          <div class="mt-2">-->
<!--            <mat-form-field class="mr-2 mat-form-field-double">-->
<!--              <mat-label>Phone</mat-label>-->
<!--              <input matInput type="text" formControlName="phoneNumber">-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--          <div class="mt-2">-->
<!--            <mat-form-field class="mr-2 mat-form-field-double">-->
<!--              <mat-label>Email</mat-label>-->
<!--              <input matInput type="text" formControlName="email">-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--          <div class="mt-2">-->
<!--            <mat-form-field class="mr-2 mat-form-field-double">-->
<!--              <mat-label>Website</mat-label>-->
<!--              <input matInput type="text" (blur)="onWebsiteBlurred()" formControlName="website" placeholder="https://google.com">-->
<!--            </mat-form-field>-->
<!--          </div>-->
        </div>
      </mat-tab>
<!--      <mat-tab label="Addresses">-->
<!--        <div class="form-section mt-2" formGroupName="address">-->
<!--          <app-address-sub-form-->
<!--            [showGPS]="false"-->
<!--            [showSecondaryAddress]="false"-->
<!--            [(value)]="value.address"-->
<!--            [formGroupRef]="getFormGroupFromAbstractControl(formGroupRef.get('address'))">-->
<!--          </app-address-sub-form>-->
<!--        </div>-->
<!--      </mat-tab>-->
      <mat-tab label="Branding" *ngIf="!isProfileSetup && value?.hasEnterpriseSubscription">
        <div class="form-section" style="margin-top:20px">
          <div class="mt-2" *ngIf="previewFileUrl">
            <img alt="Business logo" [src]="previewFileUrl | safeUrl">
          </div>
          <div class="mt-2" *ngIf="!previewFileUrl && value?.file?.uri && !fileList?.length">
            <img alt="Business logo" [src]="value.file?.uri">
          </div>
          <div>
          <span class="d-flex align-items-center mt-2">
            <app-upload class="me-1" [label]="fileList?.length || value.file?.fileKey ? 'Replace Logo' : 'Upload Logo'"
                        accept="image/*"
                        (fileListChange)="onFilesUploaded($event)"></app-upload>
          </span>
            <span>
            <button mat-flat-button *ngIf="value.file?.fileKey && !previewFileUrl"
                    (click)="onDownloadLogoClicked()">Download {{value.file?.name}}</button>
          </span>
          </div>
        </div>
<!--        <div class="form-section mt-2" style="height: 400px;">-->
<!--          <div>-->
<!--            <mat-form-field class="mr-2 mat-form-field">-->
<!--              <mat-label>Primary Color</mat-label>-->
<!--              <input matInput type="text" placeholder="#ffffff" formControlName="primaryColor">-->
<!--            </mat-form-field>-->
<!--            <span class="mr-1" *ngIf="colors.primaryColor" [style.background]="colors.primaryColor">&nbsp;&nbsp;&nbsp;&nbsp;</span>-->
<!--            <div class="inline-block">-->
<!--              <a href="javascript:void(0)"-->
<!--                 [cpPosition]="'right'"-->
<!--                 [cpPositionOffset]="'50%'"-->
<!--                 [cpPositionRelativeToArrow]="true"-->
<!--                 [(colorPicker)]="colors.primaryColor"-->
<!--                 (colorPickerChange)="onColorChanged('primaryColor', $event)">-->
<!--                Need help finding your primary color? </a>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="mt-2">-->
<!--            <mat-form-field class="mr-2 mat-form-field">-->
<!--              <mat-label>Secondary Color</mat-label>-->
<!--              <input matInput type="text" placeholder="#ffffff" formControlName="secondaryColor">-->
<!--            </mat-form-field>-->
<!--            <span class="mr-1" *ngIf="colors.secondaryColor" [style.background]="colors.secondaryColor">&nbsp;&nbsp;&nbsp;&nbsp;</span>-->
<!--            <div class="inline-block">-->
<!--              <a href="javascript:void(0)"-->
<!--                 [cpPosition]="'right'"-->
<!--                 [cpPositionOffset]="'50%'"-->
<!--                 [cpPositionRelativeToArrow]="true"-->
<!--                 [(colorPicker)]="colors.secondaryColor"-->
<!--                 (colorPickerChange)="onColorChanged('secondaryColor', $event)">-->
<!--                Need help finding your secondary color? </a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </mat-tab>
      <mat-tab label="Notification Defaults" *ngIf="!isProfileSetup">
        <div class="mt-2">
          <app-report-notification-sub-form [formGroupRef]="getFormGroupFromAbstractControl(formGroupRef.get('defaultReportNotification'))">
          </app-report-notification-sub-form>
        </div>
      </mat-tab>
      <mat-tab label="Access" *ngIf="!isProfileSetup && value.businessKey">
        <div class="mt-2">
          <app-business-access-grid
            [businessKey]="value.businessKey">
          </app-business-access-grid>
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</form>
<app-blocking-loader *ngIf="showBlockingLoader"></app-blocking-loader>
