import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { DashboardService } from 'src/app/core/services/domain/dashboard.service';
import { RoutingKeysUtilities } from 'src/app/routing/routing-keys.utilities';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardModel } from 'src/app/shared/models/domain/dashboard.model';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { ReportStatusesEnum } from 'src/app/shared/enums/domain/report-statuses-enum';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { tap } from 'rxjs/operators';
import { RouteUtilities } from 'src/app/routing/route.utilities';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
})
export class DashboardPageComponent extends BasePageComponent implements OnInit, AfterViewInit {
  businessKey: string = null;
  businessName: string = null;
  isLoading: boolean = false;
  formGroupRef: UntypedFormGroup;
  dashboard: DashboardModel = null;
  reportStatuses = ReportStatusesEnum;

  constructor(
    private dashboardService: DashboardService,
    private authClientService: AuthClientService,
    private businessContextService: BusinessContextService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private router: Router
  ) {
    super()
  }

  ngOnInit(): void {
    this.businessKey = this.getQueryParameterFromRoute(RoutingKeysUtilities.queryParams.businessKey, this.activatedRoute.snapshot);

    this.initFormGroup();
    //setTimeout(() => {
      this.initData();
      // this.subscriptions.add(this.formGroupRef.valueChanges.subscribe(_ => {
      //   this.initData();
      // }));
    //}, 1000);

    // if (!LocalStorageUtilities.get('intro_video')) {
    //   const dialogRef = this.dialogService.openVideo({
    //     title: 'Welcome to FinCEN BOI Reporting',
    //     description: 'This video will provide you with a brief introduction of the platform.',
    //     src: '/assets/videos/intro-video.mp4'
    //   });
    //   dialogRef.afterClosed().subscribe((result) => {
    //     localStorage.setItem('intro_video', 'true');
    //   });
    // }
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(this.activatedRoute.queryParams.subscribe(queryParams => {
      if ((this.businessKey || null) !== (queryParams.businessKey || null)) {
        this.reloadPage(this.router);
      }
    }));
  }

  onNavigateToReportClicked(reportStatus: ReportStatusesEnum = null): void {
    this.dialogService.openReportsGrid({businessKey: this.businessKey, status: reportStatus})

    // if (this.businessKey == null && this.authClientService.isAdmin) {
    //   this.router.navigateByUrl(RouteUtilities.routes.application.admin.reports.getNavigateUrl(reportStatus));
    // } else {
    //   this.router.navigateByUrl(RouteUtilities.routes.application.reports.getNavigateUrl(reportStatus));
    // }
  }

  onAddReportButtonClicked(): void {
    this.router.navigateByUrl(RouteUtilities.routes.application.reportAdd.getNavigateUrl(this.businessContextService.currentBusiness.businessKey));
  }

  onSendReminderClicked(): void {
    alert('send that reminder!');
  }

  onContactClicked(): void {
    alert('contact a client');
  }

  private initData(): void {
    let timeout = setTimeout(_ => {
      this.isLoading = true;
    }, 1500);

    if (this.businessKey) {
      this.businessName = this.businessContextService.currentBusiness.name;
    }

    this.dashboardService.get(this.businessKey).pipe(tap(_ => {
      if (timeout) {
        clearTimeout(timeout);
      }

      this.isLoading = false;
    })).subscribe(data => {
      this.dashboard = data;

    }, err => {
      this.onHttpFailure(this.notificationService, err);
      if (timeout) {
        clearTimeout(timeout);
      }

      this.isLoading = false;
    });

  }

  private initFormGroup(): void {
    this.formGroupRef = new UntypedFormGroup({
      businessKey: new FormControl(this.businessContextService.currentBusiness.businessKey)
    })
  }
}

