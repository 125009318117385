import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { BusinessSubscriptionService } from 'src/app/core/services/domain/business-subscription.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { AuthService } from '@auth0/auth0-angular';
import { BusinessSubscriptionTypesEnum } from 'src/app/shared/enums/domain/business-subscription-types.enum';

@Injectable()
export class SubscriptionGuard implements CanActivate {
  constructor(
    private businessSubscriptionService: BusinessSubscriptionService,
    private businessContextService: BusinessContextService,
    private authClientService: AuthClientService,
    private authService: AuthService,
    private router: Router) {
  }

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): boolean | Observable<boolean> {
    if (this.businessContextService.currentBusiness) {
      return this.businessSubscriptionService.hasValidSubscription(this.businessContextService.currentBusiness.businessKey).pipe(tap(result => {
        if (result) {
          return true;
        }

        if (this.authClientService.isAuthenticated) {
          if (this.businessContextService.currentBusiness) {
            this.businessSubscriptionService.hasValidSubscription(this.businessContextService.currentBusiness.businessKey).subscribe(hasValidSubscription => {
              if (hasValidSubscription) {
                this.router.navigateByUrl(RouteUtilities.routes.application.dashboard.getNavigateUrl());
              } else {
                this.router.navigateByUrl(RouteUtilities.routes.application.subscriptions.getNavigateUrl(BusinessSubscriptionTypesEnum.IndividualRecurring));
              }
            });
          }
        } else {
          this.authService.loginWithRedirect();
        }
        return false;
      }));
    }

    return of(true);
  }
}
