import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ReportModel } from 'src/app/shared/models/domain/report.model';
import { ReportFilingTypesEnum } from 'src/app/shared/enums/domain/report-filing-types.enum';
import { ReportTaxIdentificationTypesEnum } from 'src/app/shared/enums/domain/report-tax-identification-types.enum';
import { EnumModel } from 'src/app/shared/models/app/enum.model';
import { EnumUtilities } from 'src/app/core/utilities/app/enum.utilities';
import { CountryService } from 'src/app/core/services/domain/country.service';
import { CountryModel } from 'src/app/shared/models/domain/country.model';
import { ReportUtilities } from 'src/app/core/utilities/domain/report-utilities';
import { StateModel } from 'src/app/shared/models/domain/state.model';
import { StateService } from 'src/app/core/services/domain/state.service';
import { TribalService } from 'src/app/core/services/domain/tribal.service';
import { TribalModel } from 'src/app/shared/models/domain/tribal.model';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { ReportService } from 'src/app/core/services/domain/report.service';
import { ReportStatusesEnum } from 'src/app/shared/enums/domain/report-statuses-enum';
import { ReportUploadStatusesEnum } from 'src/app/shared/enums/domain/report-upload-statuses.enum';
import { BaseReportFormComponent } from 'src/app/shared/components/forms/base/base-report-form.component';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { DateUtilities } from 'src/app/core/utilities/app/date.utilities';

@Component({
  selector: 'app-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss']
})
export class ReportFormComponent extends BaseReportFormComponent<ReportModel> implements OnInit {
  @Input() readonly: boolean = false;

  @Input() isAdmin: boolean = false;

  filingTypes = ReportFilingTypesEnum;
  taxIdentificationTypes = ReportTaxIdentificationTypesEnum;
  usCountryCode = CountryService.usCountryCode;
  otherTribalCode = TribalService.otherCode;

  taxIdentificationTypeOptions: EnumModel[] = [];
  reportStatusOptions: EnumModel[] = [];
  reportStatusUploadOptions: EnumModel[] = [];
  countryOptions: CountryModel[];
  tribalOptions: TribalModel[];
  firstRegistrationStateOptions: StateModel[];
  showModificationWarning = false;
  previousFilingType = null;
  showBlockingLoader = false;

  constructor(private countryService: CountryService,
              private stateService: StateService,
              private tribalService: TribalService,
              private dialogService: DialogService,
              private reportService: ReportService,
              private notificationService: NotificationService) {
    super()
  }

  ngOnInit(): void {
    this.taxIdentificationTypeOptions = EnumUtilities.convertToSelectModels(ReportTaxIdentificationTypesEnum, false, ReportUtilities.getTaxIdentifierDisplayName);
    this.reportStatusOptions = EnumUtilities.convertToSelectModels(ReportStatusesEnum, true);
    this.reportStatusUploadOptions = EnumUtilities.convertToSelectModels(ReportUploadStatusesEnum, true);

    super.ngOnInit();

    this.initData();
  }

  onFilingTypeChanged(): void {
    if (this.value.filingType !== ReportFilingTypesEnum.InitialReport) {
      this.showBlockingLoader = true;
      this.reportService.getReportCopy(this.value.business.businessKey).subscribe(reportCopy => {
        let dialogRef = this.dialogService.openConfirmationDialog('By selecting the correct, update prior report, or newly exempt entity filing type, we are going to discard your current changes and apply your latest submitted report if available.  Do you wish to continue?', 'Yes', 'Cancel', '230px');

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            if (reportCopy) {
              reportCopy.filingType = this.value.filingType;
              this.previousFilingType = this.value.filingType;

              this.formGroupRef.patchValue(reportCopy);
              this.formGroupRef.updateValueAndValidity();
            }

            if (this.value.filingType === ReportFilingTypesEnum.NewlyExemptEntity) {
              let caFormArray = this.getFormArrayByProperty('companyApplicants');
              caFormArray.clear();

              let boFormArray = this.getFormArrayByProperty('beneficialOwners');
              boFormArray.clear();
            }
          } else {
            this.formGroupRef.get('filingType').setValue(this.previousFilingType);
          }
        });

        this.showBlockingLoader = false;
      }, err => {
        this.showBlockingLoader = false;
      });
    }
  }

  onTaxIdentificationTypeChanged(): void {
    if (this.value.taxIdentificationType !== ReportTaxIdentificationTypesEnum.Foreign) {
      this.formGroupRef.get('taxIdentifierForeignCountryCode').setValue(null);
    }
  }

  onFirstRegistrationCountryChanged(isInitialLoad = false): void {
    if (!isInitialLoad) {
      this.formGroupRef.get('firstRegistrationStateCode').setValue(null);
    }

    if (this.value.firstRegistrationCountryCode) {
      this.stateService.listByCountryCode('US').subscribe(states => {
        this.firstRegistrationStateOptions = states;
        this.firstRegistrationStateOptions.unshift({code: null, name: '', countryCode: null});
      });
    }
  }

  onExistingReportingCompanyChanged(): void {
    let companyApplicantFormArray = (this.formGroupRef.get('companyApplicants') as UntypedFormArray);

    if (this.value.existingReportingCompany) {
      companyApplicantFormArray.clear();
    } else {
      companyApplicantFormArray.push(this.getCompanyApplicantFormGroup());
      this.formGroupRef.get('companyApplicants').setValue(companyApplicantFormArray.value);
    }

    this.formGroupRef.markAsDirty();
    this.formGroupRef.updateValueAndValidity();
  }

  onForeignPoolInvestmentVehicleChanged(): void {
    let caFormArray = this.getFormArrayByProperty('companyApplicants');
    let boFormArray = this.getFormArrayByProperty('beneficialOwners');

    if (this.value.isForeignPoolInvestmentVehicle) {
      let dialogRef = this.dialogService.openConfirmationDialog('When foreign pooled investment vehicle is indicated, all Company Applicant fields and all Beneficial Owners (except the first Beneficial Owner) will be cleared/disabled. Do you wish to continue?', 'Yes', 'Cancel', '220px');

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          caFormArray.clear();
          boFormArray.clear();
          this.onAddBeneficialOwnerClicked();
        } else {
          this.formGroupRef.get('isForeignPoolInvestmentVehicle').setValue(false);
        }
      });
    } else {
      if (caFormArray.length === 0) {
        this.onAddCompanyApplicantClicked();
      }

      if (boFormArray.length === 0) {
        this.onAddBeneficialOwnerClicked();
      }
    }
  }

  onAddCompanyApplicantClicked(): void {
    let formArray = this.getFormArrayByProperty('companyApplicants');
    formArray.push(this.getCompanyApplicantFormGroup());
    formArray.markAsDirty()
    formArray.updateValueAndValidity();
  }

  onRemoveCompanyApplicantClicked(index: number): void {
    let formArray = this.getFormArrayByProperty('companyApplicants');
    formArray.removeAt(index);

    if (formArray.length === 0) {
      formArray.insert(0, this.getCompanyApplicantFormGroup());
    }

    formArray.markAsDirty()
    formArray.updateValueAndValidity();
  }

  onAddBeneficialOwnerClicked(): void {
    let formArray = this.getFormArrayByProperty('beneficialOwners');
    formArray.push(this.getBeneficialOwnerFormGroup());
    formArray.markAsDirty()
    formArray.updateValueAndValidity();
  }

  onRemoveBeneficialOwnerClicked(index: number): void {
    let formArray = this.getFormArrayByProperty('beneficialOwners');
    formArray.removeAt(index);

    if (formArray.length === 0) {
      formArray.insert(0, this.getBeneficialOwnerFormGroup());
    }

    formArray.markAsDirty()
    formArray.updateValueAndValidity();
  }

  onTranscriptionFilesUploaded(fileList: FileList): void {
    let validFileTypes = ['application/pdf'];

    if (fileList.length) {
      const mimeType = fileList[0].type;
      if (validFileTypes.indexOf(mimeType?.toLowerCase()) === -1) {
        this.notificationService.showErrorNotification('Only pdf is supported.');
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(fileList[0]);
      // reader.onload = (_event) => {
      //   this.previewFileUrl = <string>reader.result;
      // }

      this.onFilesChanged(fileList);

      this.showModificationWarning = true;

      this.formGroupRef.markAsDirty();
      this.formGroupRef.get('transcriptionFileKey').setValue(null);
      this.formGroupRef.get('transcriptionFileName').setValue(this.fileList[0].name);
      this.formGroupRef.get('hasUploadedFile').setValue(true);

      this.value = this.formGroupRef.value;
      this.valueChange.emit(this.value);
    }
  }

  onDownloadTranscriptionClicked(): void {
    this.reportService.downloadReportTranscription(this.value.reportKey, this.value.transcriptionFileName).subscribe(_ => {
      this.notificationService.showSuccessNotification('File downloaded successfully.');
    }, err => {
      this.onHttpFailure(this.notificationService, err);
    })
  }

  onDeleteTranscriptionClicked(): void {
    this.formGroupRef.get('transcriptionFileKey').setValue(null);
    this.formGroupRef.get('transcriptionFileName').setValue(null);
    this.formGroupRef.markAsDirty();
    this.showModificationWarning = true;
  }

  protected getFormGroup(): UntypedFormGroup {
    let companyApplicantFormArray = new UntypedFormArray([]);
    if (this.value.companyApplicants?.length) {
      for (let companyApplicant of this.value.companyApplicants) {
        companyApplicantFormArray.push(this.getCompanyApplicantFormGroup(companyApplicant));
      }
    } else if (!this.value.existingReportingCompany && !this.value.isForeignPoolInvestmentVehicle) {
      companyApplicantFormArray.push(this.getCompanyApplicantFormGroup());
    }

    let beneficialOwnerFormArray = new UntypedFormArray([]);
    if (this.value.beneficialOwners?.length) {
      for (let beneficialOwner of this.value.beneficialOwners) {
        beneficialOwnerFormArray.push(this.getBeneficialOwnerFormGroup(beneficialOwner));
      }
    } else if (!this.value.isForeignPoolInvestmentVehicle) {
      beneficialOwnerFormArray.push(this.getBeneficialOwnerFormGroup());
    }

    this.previousFilingType = this.value.filingType ?? ReportFilingTypesEnum.Unknown;
    let now = new Date();

    return new UntypedFormGroup({
      reportKey: new UntypedFormControl(this.value.reportKey),
      business: new UntypedFormControl(this.value.business),

      status: new UntypedFormControl(this.value.status ?? ReportStatusesEnum.Draft),
      uploadStatus: new UntypedFormControl(this.value.uploadStatus ?? ReportUploadStatusesEnum.NotUploaded),
      completedDateTime: new UntypedFormControl(this.value.completedDateTime),

      hasUploadedFile: new UntypedFormControl(false),
      transcriptionFileName: new UntypedFormControl(this.value?.transcriptionFileName),
      transcriptionFileKey: new UntypedFormControl(this.value.transcriptionFileKey),

      clientNotes: new UntypedFormControl(this.value.clientNotes),
      systemNotes: new UntypedFormControl(this.value.systemNotes),
      internalNotes: new UntypedFormControl(this.value.internalNotes),

      filingType: new UntypedFormControl(this.value.filingType ?? ReportFilingTypesEnum.Unknown),

      isForeignPoolInvestmentVehicle: new UntypedFormControl(this.value.isForeignPoolInvestmentVehicle ?? false),

      legalName: new UntypedFormControl(this.value.legalName ?? this.value.business?.legalName),
      alternateName: new UntypedFormControl(this.value.alternateName),
      taxIdentificationType: new UntypedFormControl(this.value.taxIdentificationType ?? ReportTaxIdentificationTypesEnum.Unknown),
      taxIdentifier: new UntypedFormControl(this.value.taxIdentifier),
      taxIdentifierForeignCountryCode: new UntypedFormControl(this.value.taxIdentifierForeignCountryCode),
      firstRegistrationCountryCode: new UntypedFormControl(this.value.firstRegistrationCountryCode),
      firstRegistrationStateCode: new UntypedFormControl(this.value.firstRegistrationStateCode),
      firstRegistrationTribalCode: new UntypedFormControl(this.value.firstRegistrationTribalCode),
      firstRegistrationTribalOther: new UntypedFormControl(this.value.firstRegistrationTribalOther),
      companyAddress: new UntypedFormGroup({
        address: new UntypedFormControl(this.value?.companyAddress?.address),
        city: new UntypedFormControl(this.value?.companyAddress?.city),
        stateCode: new UntypedFormControl(this.value?.companyAddress?.stateCode),
        countryCode: new UntypedFormControl(this.value?.companyAddress?.countryCode),
        postalCode: new UntypedFormControl(this.value?.companyAddress?.postalCode),
      }),
      existingReportingCompany: new UntypedFormControl(this.value?.existingReportingCompany ?? false),
      companyApplicants: companyApplicantFormArray,
      beneficialOwners: beneficialOwnerFormArray,
      submitterEmail: new UntypedFormControl(this.value.submitterEmail),
      submitterFirstName: new UntypedFormControl(this.value.submitterFirstName),
      submitterLastName: new UntypedFormControl(this.value.submitterLastName),
      submittedDateTime: new UntypedFormControl(this.value.submittedDateTime),

      reportNotification: new UntypedFormGroup({
        isDisabled: new UntypedFormControl(this.value?.reportNotification?.isDisabled ?? true),
        startDate: new UntypedFormControl(this.value?.reportNotification?.startDate),
        endDate: new UntypedFormControl(this.value?.reportNotification?.endDate),
        delayInDays: new UntypedFormControl(this.value?.reportNotification?.delayInDays ?? 30),
        copyToEmail: new UntypedFormControl(this.value?.reportNotification?.copyToEmail),
        maxNotifications: new UntypedFormControl(this.value?.reportNotification?.maxNotifications),
        message: new UntypedFormControl(this.value?.reportNotification?.message)
      }),

      lastReminderSentDateTime: new UntypedFormControl(this.value.lastReminderSentDateTime),
      createdDateTime: new UntypedFormControl( DateUtilities.convertToZeroMomentDate(this.value.createdDateTime) ?? DateUtilities.convertToZeroMomentDate(new Date())),
      remindersSent: new UntypedFormControl(this.value.remindersSent)
    });
  };

  private initData() {
    this.countryService.list().subscribe(countries => {
      this.countryOptions = countries;
      this.countryOptions.unshift({code: null, name: ''});

      this.onFirstRegistrationCountryChanged(true);
    });

    this.tribalService.list().subscribe(tribes => {
      this.tribalOptions = tribes;
      this.tribalOptions.unshift({code: null, name: ''});
    });
  }

  onDownloadCompanyApplicantIdentificationClicked(fileKey: string, fileName: string): void {
    this.reportService.downloadReportIdentification(this.value.reportKey, fileKey, fileName).subscribe(_ => {
      this.notificationService.showSuccessNotification('File downloaded successfully.');
    }, err => {
      this.onHttpFailure(this.notificationService, err);
    })
  }

  onDownloadBeneficialOwnerIdentificationClicked(fileKey: string, fileName: string): void {
    this.reportService.downloadReportIdentification(this.value.reportKey, fileKey, fileName).subscribe(_ => {
      this.notificationService.showSuccessNotification('File downloaded successfully.');
    }, err => {
      this.onHttpFailure(this.notificationService, err);
    })
  }

  protected readonly ReportFilingTypesEnum = ReportFilingTypesEnum;
}

export enum ReportTabIndexesEnum {
  FilingInformation = 0,
  ReportingCompany = 1,
  CompanyApplicants = 2,
  BeneficialOwners,
  Notifications,
  Submit = 5,
  Admin = 6
}


