import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { AddressModel } from 'src/app/shared/models/app/address.model';
import { Subject } from 'rxjs';
import { LocationService } from 'src/app/core/services/app/location.service';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss']
})
export class LocationSearchComponent extends BaseComponent implements OnInit {
  @Input() placeholderText = 'Search . . . ';
  @Input() cssClass: string = 'mat-form-field-double';
  @Output() locationSelect = new EventEmitter<AddressModel>();

  locationSearchTerm: string = null;
  locationSearchResults: AddressModel[] = null;
  isLoadingLocations: boolean = false;

  locationSearchChanged: Subject<string> = new Subject<string>();

  constructor(private locationService: LocationService) {
    super();
  }

  ngOnInit(): void {
    this.locationSearchChanged.pipe(debounceTime(1000),  distinctUntilChanged(), tap(_ => {
      this.isLoadingLocations = true;
    }))
      .subscribe(searchTerm => {
        this.locationService.searchLocationsByAddress(searchTerm).subscribe(results => {
          this.locationSearchResults = results;
          this.isLoadingLocations = false;
        })
      });
  }


  onLocationSearchChanged(text: string): void {
    this.locationSearchChanged.next(text);
  }

  onOptionSelected($event): void {
    this.locationSearchTerm = null;
    this.locationSearchResults = null;
    this.locationSelect.emit($event.option.value);
  }
}
