import { AfterViewInit, Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[dynamicMatTab]'
})
export class DynamicMatTabDirective implements AfterViewInit, OnChanges {
  @Input() gtnDynamicMatTab: number[] = [];

  constructor(private elementRef: ElementRef) {

  }

  ngOnChanges(changes: any) {
    if (changes.gtnDynamicMatTab && !changes.gtnDynamicMatTab.isFirstChange()) {
      this.setTabs();
    }
  }

  ngAfterViewInit(): void {
    this.setTabs();
  }

  private setTabs(): void {
    if (this.elementRef?.nativeElement) {
      const matTabs = this.elementRef.nativeElement.querySelector('.mat-tab-labels').children;
      for (let i = 0; i < matTabs.length; i++) {
        if (this.gtnDynamicMatTab.indexOf(i) !== -1) {
          matTabs[i].style.display = 'none';
        } else {
          matTabs[i].style.display = 'flex';
        }
      }
    }
  }
}
