import {Component, Input, OnInit} from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { AddressModel } from 'src/app/shared/models/app/address.model';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-address-sub-form',
  templateUrl: './address-sub-form.component.html',
  styleUrls: ['./address-sub-form.component.scss']
})
export class AddressSubFormComponent extends BaseFormComponent<AddressModel> implements OnInit {
  @Input() readonly = false;
  @Input() showSecondaryAddress = true;
  @Input() showGPS = false;

  constructor() {
    super();
  }

  onMailingLocationSelected(location: AddressModel): void {
    this.formGroupRef.patchValue({
      fullAddressMailing: location.fullAddress,
      addressLineOneMailing: location.addressLineOne,
      addressLineTwoMailing: location.addressLineTwo,
      cityMailing: location.city,
      stateMailing: location.state,
      postalCodeMailing: location.postalCode,
      countryMailing: location.country,
      latitudeMailing: location.latitude,
      longitudeMailing: location.longitude,
      distanceMailing: location.distance
    });
    this.formGroupRef.markAsDirty();
  }

  protected getFormGroup(): UntypedFormGroup {
    return this.formGroupRef;
  }
}
