import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { AuthClientModel } from 'src/app/shared/models/domain/auth-client.model';

@Injectable()
export class LoggingService {
  appInsights: ApplicationInsights;

  constructor(private authClientService: AuthClientService) {
    if (environment.appInsights.instrumentationKey) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: environment.appInsights.instrumentationKey,
          enableAutoRouteTracking: true // option to log all route changes
        }
      });

      this.authClientService.clientContextChange$.subscribe((client: AuthClientModel) => {
          this.appInsights.setAuthenticatedUserContext(client?.clientKey, null, true);
      });

      this.appInsights.loadAppInsights();
      this.appInsights.trackPageView();

      this.appInsights.addTelemetryInitializer((telemetryItem: ITelemetryItem) => {
        if (telemetryItem.baseType === 'ExceptionData') {
          let exceptions = telemetryItem?.baseData?.exceptions ?? [];

          //IGNORE SILENT AUTH FAILURE IN APP INSIGHTS
          if (exceptions.find(t => t?.message === 'Login Required' || t?.message === 'Failed Silent Auth')) {
            return false;
          }
        }

        // add custom log properties across all Telemetry types here
        telemetryItem.data['FINCENBOI_AppName'] = `UIFinCENBOIReporting`;

        if (this.authClientService.client?.clientKey) {
          telemetryItem.data['FINCENBOI_ClientKey'] = this.authClientService.client.clientKey;
        }

        if (this.authClientService.client?.authUserId) {
          telemetryItem.data['FINCENBOI_Auth0UserId'] = this.authClientService.client.authUserId;
        }

        return true;
      });
    }
  }

  logPageView(name?: string, url?: string) { // option to call manually
    if (this.appInsights) {
      this.appInsights.trackPageView({
        name: name,
        uri: url
      });
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (this.appInsights) {
      this.appInsights.trackEvent({name: name}, properties);
    }
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if (this.appInsights) {
      this.appInsights.trackMetric({name: name, average: average}, properties);
    }
  }

  logException(exception: Error, severityLevel?: number) {
    if (this.appInsights) {
      this.appInsights.trackException({exception: exception, severityLevel: severityLevel});
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (this.appInsights) {
      this.appInsights.trackTrace({message: message}, properties);
    }
  }
}
