import { Component, Input, OnInit } from '@angular/core';
import { BaseGridComponent } from 'src/app/shared/components/base/base-grid.component';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { ReportService } from 'src/app/core/services/domain/report.service';
import { map } from 'rxjs/operators';
import { ReportModel } from 'src/app/shared/models/domain/report.model';
import { ReportIndividualModel } from 'src/app/shared/models/domain/report-individual.model';
import { ArrayUtilities } from 'src/app/core/utilities/app/array.utilities';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { ReportBeneficialOwnerModel } from 'src/app/shared/models/domain/report-beneficial-owner.model';
import { ReportCompanyApplicantModel } from 'src/app/shared/models/domain/report-company-applicant.model';
import { ReportFilingTypesEnum } from 'src/app/shared/enums/domain/report-filing-types.enum';
import { ReportStatusesEnum } from 'src/app/shared/enums/domain/report-statuses-enum';

@Component({
  selector: 'app-report-individuals-grid',
  templateUrl: './report-individuals-grid.component.html',
  styleUrls: ['./report-individuals-grid.component.scss']
})
export class ReportIndividualsGridComponent extends BaseGridComponent<ReportIndividualViewModel> implements OnInit {
  @Input() businessKey: string = null;
  @Input() includeChildBusinesses: boolean = false;
  @Input() isAdmin: boolean = false;

  gridTitle: string = 'Individuals';

  constructor(public authClientService: AuthClientService,
              private reportService: ReportService,
              private dialogService: DialogService) {
    super();
    this.gridKey = `report_individuals_${this.isAdmin}_v3.1`;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected setColumnDefinitions(): ColDef[] {
    return [
      {
        headerName: '',
        field: this.actionFieldName,
        width: 30,
        resizable: false,
        cellClass: this.preventRowClickClass,
        cellRenderer: params => {
          let html = '';
          const rowData = <ReportIndividualViewModel>params.data;

          if (this.reportService.canEdit(rowData.report)) {
            html += '<i title="Email Reminder" class="cursor-pointer email-reminder fas fa-envelope prevent-row-click"></i>';
          }

          return html;
        }
      },
      this.getDefaultColumn('report.business.name', 'Business Name'),
      this.getEnumColumn('report.status', 'Status', ReportStatusesEnum),
      this.getEnumColumn('report.filingType', 'Filing Type', ReportFilingTypesEnum),
      this.getCreatedOnColumn('report.createdDateTime'),
      this.getDefaultColumn('individual.email', 'Email'),
      this.getDefaultColumn('individual.fullName', 'Name'),
      this.getYesNoColumn('isCompanyApplicant', 'Company Applicant'),
      this.getYesNoColumn('isBeneficialOwner', 'Beneficial Owner'),
      this.getDateColumn('lastReminderSentDateTime', 'Last Reminder Sent', false, true),
    ];
  }

  protected onGridReady(): void {
    this.subscriptions.add(this.cellClick.subscribe((cellClick: { data: ReportIndividualViewModel, event: CellClickedEvent }) => {
      if (cellClick.event.colDef.field === this.actionFieldName) {
        if (this.hasCellClass(cellClick.event, 'email-reminder')) {
          let matDialogRef = this.dialogService.openSendNotificationDialog(cellClick.data.report, cellClick.data.individual, cellClick.data.reportBeneficialOwner, cellClick.data.reportCompanyApplicant);

          this.subscriptions.add(matDialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.refreshGridData().subscribe();
            }
          }));
        }
      }
    }));
  }

  protected setRowData(): Observable<any[]> {
    let businessKeys = [];
    if (this.businessKey) {
      businessKeys.push(this.businessKey);
    }

    return this.reportService.searchReports({
      businessKeys: businessKeys,
      includeChildBusinesses: this.includeChildBusinesses,
      includeDependencyGraph: true,
      isAdmin: this.isAdmin,
    }).pipe(map(reports => {
      let reportsByIndividual: ReportIndividualViewModel[] = [];

      for (let report of reports) {
        let allIndividualsForReport : ReportIndividualModel[] = [];
        allIndividualsForReport = allIndividualsForReport.concat(report.companyApplicants.filter(t => t.individual?.email).map(t => t.individual) ?? []);
        allIndividualsForReport = allIndividualsForReport.concat(report.beneficialOwners.filter(t => t.individual?.email).map(t => t.individual) ?? []);

        let uniqueIndividuals: ReportIndividualModel[] = ArrayUtilities.getUniqueItemsByProperty(allIndividualsForReport, 'email');
        for (let uniqueIndividual of uniqueIndividuals) {

          let reportBeneficialOwner = report.beneficialOwners?.find(t => t.individual?.email === uniqueIndividual.email);
          let reportCompanyApplicant = report.companyApplicants?.find(t => t.individual?.email === uniqueIndividual.email);

          uniqueIndividual.firstName = reportBeneficialOwner?.individual?.firstName ?? reportCompanyApplicant?.individual?.firstName;
          uniqueIndividual.lastName = reportBeneficialOwner?.individual?.lastName ?? reportCompanyApplicant?.individual?.lastName;
          uniqueIndividual.fullName = uniqueIndividual.lastName && uniqueIndividual.firstName ? `${uniqueIndividual.lastName}, ${uniqueIndividual.firstName}` : uniqueIndividual.lastName ?? uniqueIndividual.firstName;

          let lastReminderSentDateTime = reportBeneficialOwner?.lastReminderSentDateTime ?? reportCompanyApplicant?.lastReminderSentDateTime;
          if (reportBeneficialOwner?.lastReminderSentDateTime && reportCompanyApplicant?.lastReminderSentDateTime) {
            if (reportBeneficialOwner?.lastReminderSentDateTime > reportCompanyApplicant?.lastReminderSentDateTime) {
              lastReminderSentDateTime = reportBeneficialOwner.lastReminderSentDateTime
            } else {
              lastReminderSentDateTime = reportCompanyApplicant.lastReminderSentDateTime
            }
          }

          reportsByIndividual.push({
            report: report,
            individual: uniqueIndividual,
            reportBeneficialOwner: reportBeneficialOwner,
            reportCompanyApplicant: reportCompanyApplicant,
            isCompanyApplicant: !!reportCompanyApplicant?.reportCompanyApplicantKey,
            isBeneficialOwner: !!reportBeneficialOwner?.reportBeneficialOwnerKey,
            lastReminderSentDateTime: lastReminderSentDateTime
          });
        }
      }

      return reportsByIndividual;
    }));
  }
}

export class ReportIndividualViewModel {
  report: ReportModel;
  reportBeneficialOwner: ReportBeneficialOwnerModel;
  reportCompanyApplicant: ReportCompanyApplicantModel;
  individual: ReportIndividualModel;
  isCompanyApplicant: boolean;
  isBeneficialOwner: boolean;
  lastReminderSentDateTime: Date | string;
}
