<mat-sidenav-container class="sidenav-container">
  <mat-sidenav-content class="sidenav-content">
    <mat-toolbar class="top-nav" color="primary">
      <div class="header-logo-container cursor-pointer"
           [routerLink]="routeUtilities.routes.application.dashboard.getNavigateUrl()">
        <img alt="Logo" height="48" src="../assets/images/logo-transparent.png"/>
      </div>
      <ng-container *ngIf="hasValidSubscription">
        <button mat-button (click)="onDashboardClicked()"><div>Dashboard</div>
        </button>
        <button mat-button [matMenuTriggerFor]="business"><div>Businesses</div>
        </button>
        <mat-menu #business="matMenu">
          <div mat-menu-item *ngIf="businessContextService.currentBusiness?.businessKey"
               [routerLink]="[routeUtilities.routes.application.businessEdit.getNavigateUrl(businessContextService.currentBusiness.businessKey)]">Manage {{businessContextService.currentBusiness.name}}
          </div>
          <div mat-menu-item (click)="onDashboardClicked(businessContextService.currentBusiness.businessKey)">{{businessContextService.currentBusiness.name}} Dashboard
          </div>
          <div mat-menu-item *ngIf="!businessContextService.currentBusiness.parentBusinessKey"
               [routerLink]="[routeUtilities.routes.application.businesses.getNavigateUrl()]">{{businessContextService.currentBusiness.hasEnterpriseSubscription ? 'Managed Businesses' : 'All Businesses'}}
          </div>
        </mat-menu>
        <button mat-button [routerLink]="[routeUtilities.routes.application.reports.getNavigateUrl()]"><div>Reports</div>
        </button>
        <button mat-button
                (click)="onNavigateClicked(routeUtilities.routes.application.reportIndividuals.getNavigateUrl())">
          <div>Individuals</div>
        </button>
        <button mat-button
                (click)="onNavigateClicked(routeUtilities.routes.application.reportActivities.getNavigateUrl())">
          <div>Activities</div>
        </button>
      </ng-container>
      <button mat-button
              *ngIf="authClientService.hasAuthorization(authorization.admin) || authClientService.hasAuthorization(authorization.superAdmin)"
              [matMenuTriggerFor]="admin">
        Admin
      </button>
      <mat-menu #admin="matMenu">
        <div mat-menu-item [routerLink]="[routeUtilities.routes.application.admin.dashboard.getNavigateUrl()]">Dashboard
        </div>
        <div mat-menu-item [routerLink]="[routeUtilities.routes.application.admin.clients.getNavigateUrl()]">Clients
        </div>
        <div mat-menu-item [routerLink]="[routeUtilities.routes.application.admin.businesses.getNavigateUrl()]">
          Businesses
        </div>
        <div mat-menu-item [routerLink]="[routeUtilities.routes.application.admin.subscriptions.getNavigateUrl()]">
          Subscriptions
        </div>
        <div mat-menu-item [routerLink]="[routeUtilities.routes.application.admin.reports.getNavigateUrl()]">
          Reports
        </div>
        <div mat-menu-item [routerLink]="[routeUtilities.routes.application.admin.reportIndividuals.getNavigateUrl()]">
          Individuals
        </div>
        <div mat-menu-item [routerLink]="[routeUtilities.routes.application.admin.reportActivities.getNavigateUrl()]">
          Activities
        </div>
        <div *ngIf="authClientService.hasAuthorization(authorization.superAdmin)" mat-menu-item
             [routerLink]="[routeUtilities.routes.application.admin.sandbox.getNavigateUrl()]">Sandbox
        </div>
      </mat-menu>

      <span class="spacer"></span>
      <ng-container *ngIf="businessContextService.allBusinesses?.length > 1">
        <div *ngIf="businessContextService.currentBusiness" class="current-business cursor-pointer"
             [mat-menu-trigger-for]="companies">{{businessContextService.currentBusiness.name}} <span><i
          class="fa fa-chevron-down"></i></span>
        </div>
        <mat-menu #companies="matMenu" [hidden]="businessContextService.allBusinesses?.length > 1">
          <ng-container *ngFor="let b of businessContextService.allBusinesses">
            <button *ngIf="b.businessKey !== businessContextService.currentBusiness.businessKey"
                    class="menu-item-button businesses-menu-item"
                    mat-menu-item (click)="onChangeBusinessClicked(b)">
              {{b.name}}
            </button>
          </ng-container>
        </mat-menu>
      </ng-container>
      <ng-container *ngIf="businessContextService.allBusinesses.length <= 1">
        <div *ngIf="businessContextService.currentBusiness"
             class="current-business">{{businessContextService.currentBusiness.name}}</div>
      </ng-container>

      <!--      <mat-icon class="cursor-pointer mr-1" [matMenuTriggerFor]="notifications" matBadge="2" matBadgeColor="accent">-->
      <!--        notifications-->
      <!--      </mat-icon>-->

      <!--      <mat-menu #notifications="matMenu">-->
      <!--        <button class="menu-item-button" mat-menu-item>-->
      <!--          <mat-icon>new_releases</mat-icon>-->
      <!--          <span>Larry's Lumber has joined</span>-->
      <!--        </button>-->
      <!--        <button class="menu-item-button" mat-menu-item>-->
      <!--          <mat-icon>new_releases</mat-icon>-->
      <!--          <span>12 new users have been added to Mary's Maltshop</span>-->
      <!--        </button>-->
      <!--      </mat-menu>-->
      <button [matTooltip]="authClientService.client?.email" mat-icon-button aria-label="profile-icon"
              [matMenuTriggerFor]="profile">
        <mat-icon>account_circle</mat-icon>
      </button>
      <mat-menu #profile="matMenu">
        <div mat-menu-item [routerLink]="routeUtilities.routes.application.profile.getNavigateUrl()">
          <span class="mr-1"><i class="fa fa-user"></i></span>Profile
        </div>
        <div mat-menu-item *ngIf="authClientService.isSetupCompleted() && businessContextService.currentBusiness?.businessKey && !businessContextService.currentBusiness.hasEnterpriseSubscription" [routerLink]="routeUtilities.routes.application.subscriptions.getNavigateUrl()">
          <span class="mr-1"><i class="fa fa-dollar-sign"></i></span>Licensing & Subscriptions
        </div>
        <div mat-menu-item *ngIf="!authClientService.isSetupCompleted() || !businessContextService.currentBusiness" [routerLink]="routeUtilities.routes.application.profileSetup.getNavigateUrl()">
          <span class="mr-1"><i class="fa fa-info"></i></span>Setup Wizard
        </div>
        <div mat-menu-item (click)="onLogoutClicked()">
          <span class="mr-1"><i class="fa fa-sign-out"></i></span>Logout
        </div>
      </mat-menu>
    </mat-toolbar>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <div class="secondary-footer">
      <div class="footer-items">

        <div class="footer-logo-container">
          <img class="footer-logo" src="../assets/images/logo-transparent.png">
        </div>
        <div class="footer-menu-item not-hoverable">
          Copyright {{year}} BOI Reporting LLC, Inc All Rights Reserved
        </div>
        <div class="footer-menu-item" [routerLink]="routeUtilities.routes.application.privacyPolicy.getNavigateUrl()"
             [routerLinkActive]="['link-active']">
          Privacy Policy
        </div>
        <div class="footer-menu-item" [routerLink]="routeUtilities.routes.application.terms.getNavigateUrl()"
             [routerLinkActive]="['link-active']">
          Terms & Conditions
        </div>
        <!--        <a href="https://www.linkedin.com/company/divine-management-services/" target="_blank" mat-mini-fab class="social-button">-->
        <!--          <i class="fab fa-linkedin-in"></i>-->
        <!--        </a>-->
        <!--        <a href="https://instagram.com/fincenboireporting4u" target="_blank" mat-mini-fab class="social-button">-->
        <!--          <i class="fab fa-instagram"></i>-->
        <!--        </a>-->
        <!--        <a href="https://x.com/divinemanage?s=11" target="_blank" mat-mini-fab class="social-button">-->
        <!--          <i class="fab fa-twitter"></i>-->
        <!--        </a>-->
        <!--        <a href="https://www.facebook.com/profile.php?id=61550343154621&mibextid=2JQ9oc" target="_blank" mat-mini-fab class="social-button">-->
        <!--          <i class="fab fa-facebook-f"></i>-->
        <!--        </a>-->
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
