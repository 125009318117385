import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { EnumModel } from 'src/app/shared/models/app/enum.model';
import { EnumUtilities } from 'src/app/core/utilities/app/enum.utilities';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';
import { BusinessTypesEnum } from 'src/app/shared/enums/domain/business-types.enum';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { MatTabGroup } from '@angular/material/tabs';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { Router } from '@angular/router';

@Component({
  selector: 'app-business-form',
  templateUrl: './business-form.component.html',
  styleUrls: ['./business-form.component.scss']
})
export class BusinessFormComponent extends BaseFormComponent<BusinessModel> implements OnInit, AfterViewInit {
  @Input() isProfileSetup = false;
  @Input() selectedTabIndex: number = 0;
  @Output() selectedTabIndexChange = new EventEmitter();
  @Input() requiresBankingLogin: boolean = false;
  @Input() fileList: FileList;
  @Output() fileListChange = new EventEmitter<FileList>();
  @Output() downloadLogoClick = new EventEmitter<BusinessModel>()

  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  //USED BY PROFILE SETUP
  @ViewChild('matTabGroup') matTabGroup: MatTabGroup;

  businessTypes: EnumModel[] = [];

  previewFileUrl: string = null;
  colors: {
    primaryColor: string,
    secondaryColor: string
  } = {primaryColor: null, secondaryColor:null};

  constructor(private notificationService: NotificationService, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.businessTypes = EnumUtilities.convertToSelectModels(BusinessTypesEnum, true);

    super.ngOnInit();

    this.subscriptions.add(this.formGroupRef.get('primaryColor').valueChanges.subscribe(val => {
      this.colors.primaryColor = val;
    }));

    this.subscriptions.add(this.formGroupRef.get('secondaryColor').valueChanges.subscribe(val => {
      this.colors.secondaryColor = val;
    }));
  }

  ngAfterViewInit(): void {
    if (this.matTabGroup) {
      this.matTabGroup.selectedIndex = this.selectedTabIndex;
    }
  }


  onLegalNameBlurred(): void {
    this.formGroupRef.get('name').setValue(this.formGroupRef.value.legalName);
  }

  onSelectedTabChanged(): void {
    this.selectedTabIndexChange.emit(this.selectedTabIndex);
  }

  onReportRowClicked(event: any): void {
    this.router.navigate([RouteUtilities.routes.application.reportEdit.getNavigateUrl(event.data.reportKey)]);
  }

  onFilesUploaded(fileList: FileList): void {
    if (fileList.length) {
      const mimeType = fileList[0].type;
      if (mimeType.match(/image\/*/) == null) {
        this.notificationService.showErrorNotification('Only images are supported.');
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(fileList[0]);
      reader.onload = (_event) => {
        this.previewFileUrl = <string>reader.result;
      }

      this.fileList = fileList;
      this.fileListChange.emit(this.fileList);
      this.formGroupRef.markAsDirty();
      this.value = this.formGroupRef.value;
      this.valueChange.emit(this.value);
    }
  }

  onDownloadLogoClicked(): void {
    this.downloadLogoClick.emit(this.value);
  }

  onWebsiteBlurred(): void {
    let value = this.value.website;

    if (value && !value.startsWith('http')) {
      this.formGroupRef.get('website').setValue('https://' + this.value.website);
    }
  }

  onColorChanged(field: string, $event: any): void {
    this.colors =  {
      primaryColor: (field === 'primaryColor' ? $event : this.colors.primaryColor),
      secondaryColor: (field === 'secondaryColor' ? $event : this.colors.secondaryColor)
    }
    this.formGroupRef.get(field).setValue($event);
    this.formGroupRef.markAsDirty();
    this.formGroupRef.updateValueAndValidity();
  }

  protected getFormGroup(): UntypedFormGroup {
    this.colors.primaryColor = this.value?.primaryColor;
    this.colors.secondaryColor = this.value?.secondaryColor;

    return new UntypedFormGroup({
      businessKey: new UntypedFormControl(this.value?.businessKey),
      parentBusinessKey: new UntypedFormControl(this.value?.parentBusinessKey),
      hasEnterpriseSubscription: new UntypedFormControl(this.value?.hasEnterpriseSubscription ?? false),
      name: new UntypedFormControl(this.value?.name),
      legalName: new UntypedFormControl(this.value?.legalName),
      type: new UntypedFormControl(this.value?.type || 0),
      description: new UntypedFormControl(this.value?.description),
      phoneNumber: new UntypedFormControl(this.value?.phoneNumber),
      website: new UntypedFormControl(this.value?.website),
      email: new UntypedFormControl(this.value?.email),
      employerIdentificationNumber: new UntypedFormControl(this.value?.employerIdentificationNumber),
      primaryColor: new UntypedFormControl(this.value?.primaryColor),
      secondaryColor: new UntypedFormControl(this.value?.secondaryColor),
      defaultReportNotification: new UntypedFormGroup({
        isDisabled: new UntypedFormControl(this.value?.defaultReportNotification?.isDisabled ?? false),
        startDate: new UntypedFormControl(this.value?.defaultReportNotification?.startDate),
        endDate: new UntypedFormControl(this.value?.defaultReportNotification?.endDate),
        delayInDays: new UntypedFormControl(this.value?.defaultReportNotification?.delayInDays ?? 30),
        copyToEmail: new UntypedFormControl(this.value?.defaultReportNotification?.copyToEmail),
        maxNotifications: new UntypedFormControl(this.value?.defaultReportNotification?.maxNotifications),
        message: new UntypedFormControl(this.value?.defaultReportNotification?.message)
      }),
      file: new UntypedFormGroup({
        fileKey:  new UntypedFormControl(this.value?.file?.fileKey),
        name:  new UntypedFormControl(this.value?.file?.name),
        contentType:  new UntypedFormControl(this.value?.file?.contentType),
        uri: new UntypedFormControl(this.value?.file?.uri)
      }),
      address: new UntypedFormGroup({
        fullAddress: new UntypedFormControl(this.value?.address?.fullAddress),
        addressLineOne: new UntypedFormControl(this.value?.address?.addressLineOne),
        addressLineTwo: new UntypedFormControl(this.value?.address?.addressLineTwo),
        city: new UntypedFormControl(this.value?.address?.city),
        state: new UntypedFormControl(this.value?.address?.state),
        postalCode: new UntypedFormControl(this.value?.address?.postalCode),
        country: new UntypedFormControl(this.value?.address?.country),
        latitude: new UntypedFormControl(this.value?.address?.latitude),
        longitude: new UntypedFormControl(this.value?.address?.longitude),

        isMailingAddressSame: new UntypedFormControl(this.value?.address?.isMailingAddressSame ?? false),
        fullAddressMailing: new UntypedFormControl(this.value?.address?.fullAddressMailing),
        addressLineOneMailing: new UntypedFormControl(this.value?.address?.addressLineOneMailing),
        addressLineTwoMailing: new UntypedFormControl(this.value?.address?.addressLineTwoMailing),
        cityMailing: new UntypedFormControl(this.value?.address?.cityMailing),
        stateMailing: new UntypedFormControl(this.value?.address?.stateMailing),
        postalCodeMailing: new UntypedFormControl(this.value?.address?.postalCodeMailing),
        countryMailing: new UntypedFormControl(this.value?.address?.countryMailing),
        latitudeMailing: new UntypedFormControl(this.value?.address?.latitudeMailing),
        longitudeMailing: new UntypedFormControl(this.value?.address?.longitudeMailing),
      })
    })
  };
}
