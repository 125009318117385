<app-base-page-template>
  <app-base-page-content-template>
    <app-report-activities-grid
      [isAdmin]="isAdmin"
      (rowClick)="onRowClicked($event)"
      [includeChildBusinesses]="true"
      [businessKey]="businessKey">
    </app-report-activities-grid>
  </app-base-page-content-template>
</app-base-page-template>
