import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pricing-page',
  templateUrl: './pricing-page.component.html',
  styleUrls: ['./pricing-page.component.scss']
})
export class PricingPageComponent extends BasePageComponent implements OnInit {
  constructor(private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.subscriptions.getNavigateUrl());
  }

  onGetStartedNowClicked(): void {
    this.router.navigateByUrl(this.routeUtilities.routes.application.subscriptions.getNavigateUrl());
  }
}
