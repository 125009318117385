import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { RoutingService } from 'src/app/core/services/app/routing.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { BusinessSubscriptionService } from 'src/app/core/services/domain/business-subscription.service';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import {
  BusinessSubscriptionPaymentFormComponent
} from 'src/app/shared/components/forms/business-subscription-payment-form/business-subscription-payment-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { BusinessSubscriptionTypesEnum } from 'src/app/shared/enums/domain/business-subscription-types.enum';
import { BusinessSubscriptionModel } from 'src/app/shared/models/domain/business-subscription.model';

@Component({
  selector: 'app-business-subscriptions-page',
  templateUrl: './business-subscriptions-page.component.html',
  styleUrls: ['./business-subscriptions-page.component.scss']
})
export class BusinessSubscriptionsPageComponent extends BaseEntryPageComponent<BusinessSubscriptionModel> implements OnInit {
  @ViewChild(BusinessSubscriptionPaymentFormComponent) creditCardForm: BusinessSubscriptionPaymentFormComponent;

  hasValidSubscription: boolean = null;
  type: BusinessSubscriptionTypesEnum;
  hideTypeSelection = false;

  constructor(
    private businessSubscriptionService: BusinessSubscriptionService,
    private businessContextService: BusinessContextService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    routingService: RoutingService,
    notificationService: NotificationService,
    pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    let sessionId = this.getQueryParameterFromRoute('sessionId', this.activatedRoute.snapshot);
    this.type = +this.getQueryParameterFromRoute('type', this.activatedRoute.snapshot) || BusinessSubscriptionTypesEnum.IndividualRecurring;

    if (sessionId) {
      this.businessSubscriptionService.createFromSessionId(sessionId).subscribe(data => {
        this.businessSubscriptionService.clearCache();
        this.router.navigateByUrl(this.routeUtilities.routes.application.dashboard.getNavigateUrl());

      }, err => {
        this.onHttpFailure(this.notificationService, err);
        this.reloadPage(this.router);
      });
    } else {
      this.businessSubscriptionService.search({businessKeys: [this.businessContextService.currentBusiness.businessKey], type: BusinessSubscriptionTypesEnum.IndividualRecurring, includeDeleted: true}).subscribe( results => {
        if (results?.length) {
          this.type = BusinessSubscriptionTypesEnum.IndividualRecurring;
          this.hideTypeSelection = true;
          this.hasValidSubscription = true;
        }

        this.value = <BusinessSubscriptionModel>{
          business: this.businessContextService.currentBusiness,
          type: this.type
        };
      });
    }
  }

  onCancelClicked() {
    this.router.navigateByUrl(RouteUtilities.routes.application.dashboard.getNavigateUrl());
  }
}



