<div *ngIf="businessName && !dialogData.email">
  You are about to grant access to <b>{{businessName}}</b>.
</div>
<div *ngIf="businessName && dialogData.email">
  You are about to send an invite to for <b>{{businessName}}</b>.
</div>
<form [formGroup]="formGroupRef">
  <div class="field-row mt-2">
    <mat-form-field class="mat-form-field-double">
      <mat-label class="required">Email</mat-label>
      <input [readonly]="dialogData.email" matInput type="text" formControlName="email">
    </mat-form-field>
  </div>
  <div class="field-row mt-2">
    <mat-form-field class="mat-form-field-double">
      <mat-label class="required">First Name</mat-label>
      <input [readonly]="dialogData.firstName" matInput type="text" formControlName="firstName">
    </mat-form-field>
  </div>
  <div class="field-row mt-2">
    <mat-form-field class="mat-form-field-double">
      <mat-label class="required">Last Name</mat-label>
      <input [readonly]="dialogData.lastName" matInput type="text" formControlName="lastName">
    </mat-form-field>
  </div>
  <div class="field-row mt-2">
    <mat-form-field class="mat-form-field-double">
      <mat-label>Additional Message</mat-label>
      <textarea
        #autosize="cdkTextareaAutosize"
        matInput
        cdkTextareaAutosize
        cdkAutosizeMinRows="5"
        cdkAutosizeMaxRows="5"
        formControlName="message">
      </textarea>
    </mat-form-field>
  </div>
  <div class="field-row mt-2">
    <mat-form-field class="mat-form-field-double">
      <mat-label>BCC Email</mat-label>
      <input matInput type="text" formControlName="copyToEmail">
    </mat-form-field>
  </div>
</form>
<div class="mt-2">
  <span class="mr-2">
    <button color="primary" [disabled]="isSaving" mat-flat-button color="primary" (click)="onSaveClicked()">Send</button>
  </span>
  <span>
    <button mat-flat-button color="primary" (click)="onCancelClicked()">Cancel</button>
  </span>
</div>
