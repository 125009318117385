// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appInsights: {
    instrumentationKey: '51b01e22-1eaa-47b3-b858-282bab714bf7 '
  },
  staticDataUrl: 'https://fincenboireportingprod.blob.core.windows.net/public/static-data',
  apiUrl: 'https://fincenboireporting-staging-app.azurewebsites.net/api',
  calendyUrl: 'https://calendly.com/boi-reporting',
  googleMapApiKey: '',
  auth0: {
    //domain: 'stg-fincenboireporting.us.auth0.com',
    domain: 'stg-login.fincenboireporting.com',
    clientId: 'N6gVY0m86mZKXWT7itMOAfSYbY89rJBY',
    audience: 'https://stg-fincenboireporting-api.com'
  },
  stripe: {
    publicApiKey: 'pk_test_51OOiYDBnixkrH5b8sHwVms8KcTvek4eo60lu32xYJ82e7NUE8C0gN5HGfBt1mSuOKhw59C5ldsDVp8TsEvan5Wkl00cMZGj2xG'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
