import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportNotificationTokenService } from 'src/app/core/services/domain/report-notification-token.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { ReportNotificationTokenModel } from 'src/app/shared/models/domain/report-notification-token.model';
import { RoutingService } from 'src/app/core/services/app/routing.service';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { BaseReportEntryPageComponent } from 'src/app/pages/shared/base/base-report-entry-page.component';
import { Observable, of } from 'rxjs';
import { DialogService } from 'src/app/core/services/domain/dialog.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CountryService } from 'src/app/core/services/domain/country.service';
import { TribalService } from 'src/app/core/services/domain/tribal.service';

@Component({
  selector: 'app-report-notification-token-page',
  templateUrl: './report-notification-token-page.component.html',
  styleUrls: ['./report-notification-token-page.component.scss']
})
export class ReportNotificationTokenPageComponent extends BaseReportEntryPageComponent<ReportNotificationTokenModel> implements OnInit {
  token: string;
  fileList: FileList;
  saveText: string = 'Next';
  businessName: string;
  reportCompanyApplicantTabIndex: number;
  reportBeneficialOwnerTabIndex: number;
  submitTabIndex: number;
  errorMessage: string = null;
  isLoaded = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private reportNotificationTokenService: ReportNotificationTokenService,
    private dialogService: DialogService,
    private countryService: CountryService,
    private tribalService: TribalService,
    routingService: RoutingService,
    notificationService: NotificationService, pendingChangesService: PendingChangesService) {
    super(notificationService, routingService, pendingChangesService);
  }

  ngOnInit(): void {
    this.enableSaveOnTabChange = false;
    this.token = this.getParameterFromRoute(this.routingKeysUtilities.routeParams.reportNotificationToken, this.activatedRoute.snapshot);

    if (!this.token) {
      this.notificationService.showErrorNotification('Token is required.');
    } else {
      this.reportNotificationTokenService.getReport(this.token).subscribe(reportNotificationToken => {
        this.value = reportNotificationToken;

        this.businessName = this.value.report.legalName;

        let tabIndex = 0;
        if (this.value.reportCompanyApplicant?.reportCompanyApplicantKey) {
          this.reportCompanyApplicantTabIndex = tabIndex;
          tabIndex = tabIndex + 1;
        }

        if (this.value.reportBeneficialOwner?.reportBeneficialOwnerKey) {
          this.reportBeneficialOwnerTabIndex = tabIndex;
          tabIndex = tabIndex + 1;
        }

        this.submitTabIndex = tabIndex;

        this.countryService.list().pipe(mergeMap(_ => {
          return this.tribalService.list();
        })).subscribe(_ => {
          this.isLoaded = true;
        });
      }, err => {
        this.isLoaded = true;

        if (err?.length === 1 && err[0].message) {
          this.errorMessage = err[0].message
        } else {
          this.onHttpFailure(this.notificationService, err);
        }
      });
    }
  }

  onSaveClicked(isSubmit: boolean = false): void {
    if (isSubmit) {
      this.onExecuteSave(isSubmit);
    }
  }

  private onExecuteSave(isSubmit: boolean): void {
    this.saveForm(isSubmit).subscribe(result => {
      if (result.result) {
        this.onSaveSuccess(`Report identification was submitted successfully.`);
      }
    });
  }

  saveForm(isSubmit: boolean): Observable<{ result: boolean, isUpdate: boolean}> {
    this.isSaving = true;
    let modelToSubmit: ReportNotificationTokenModel = <ReportNotificationTokenModel>{};
    let model = <any>this.value;
    if (model.companyApplicants?.length) {
      modelToSubmit.reportCompanyApplicant = model.companyApplicants[0];
    }

    if (model.beneficialOwners?.length) {
      modelToSubmit.reportBeneficialOwner = model.beneficialOwners[0];
    }

    modelToSubmit.report = model.report;
    modelToSubmit.token = model.token;
    modelToSubmit.issuedOnDateTime = model.issuedOnDateTime;
    modelToSubmit.accessedDateTime = model.accessedDateTime;

    return this.reportNotificationTokenService.updateReport(modelToSubmit, this.fileList).pipe(map(result => {
      this.isSaving = false;
      return {result: result, isUpdate: false};
    }), catchError(err => {
      this.isSaving = false;
      this.onSaveFailed(err);
      return of({result: false, isUpdate: false});
    }));
  }
}
