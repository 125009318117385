import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { Component, Directive, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ReportCompanyApplicantModel } from 'src/app/shared/models/domain/report-company-applicant.model';
import { ReportBeneficialOwnerModel } from 'src/app/shared/models/domain/report-beneficial-owner.model';
import { ReportIndividualModel } from 'src/app/shared/models/domain/report-individual.model';
import { ReportAddressModel } from 'src/app/shared/models/domain/report-address.model';
import { ReportAddressTypesEnum } from 'src/app/shared/enums/domain/report-address-types.enum';
import { ReportIdentificationModel } from 'src/app/shared/models/domain/report-identification.model';
import { ReportDocumentTypesEnum } from 'src/app/shared/enums/domain/report-document-types.enum';

@Component({
  template: '',
})
export abstract class BaseReportFormComponent<T> extends BaseFormComponent<T> {
  @Input() fileList: FileList;
  @Output() fileListChange: EventEmitter<FileList> = new EventEmitter<FileList>();
  @Input() selectedTabIndex: number = 0;
  @Output() selectedTabIndexChange = new EventEmitter();

  onSelectedTabChanged(): void {
    this.selectedTabIndexChange.emit(this.selectedTabIndex);
  }

  onFilesChanged(files: FileList): void {
    if (this.fileList != null) {
      this.fileList = this.mergeFileLists(this.fileList, files);
    } else {
      this.fileList = files;
    }

    this.fileListChange.emit(this.fileList);
  }

  onCopyToBeneficialOwnerClicked(companyApplicantControl: AbstractControl): void {
    let companyApplicantFormGroup = this.getFormGroupFromAbstractControl(companyApplicantControl);
    let companyApplicant = <ReportCompanyApplicantModel>companyApplicantFormGroup.value;

    let beneficialOwnersControls = (this.formGroupRef.get('beneficialOwners') as UntypedFormArray).controls

    let foundBeneficialOwnerControl = null;
    for (let beneficialOwnerControl of beneficialOwnersControls) {
      let beneficialOwnerFormGroup = this.getFormGroupFromAbstractControl(beneficialOwnerControl);
      let beneficialOwner = <ReportBeneficialOwnerModel>beneficialOwnerFormGroup.value;

      if (beneficialOwner.individual.email === companyApplicant.individual.email || (beneficialOwner.individual.firstName === companyApplicant.individual.firstName && beneficialOwner.individual.lastName === companyApplicant.individual.lastName)) {
        foundBeneficialOwnerControl = beneficialOwnerFormGroup;
      }
    }

    let individual = <ReportIndividualModel>JSON.parse(JSON.stringify(companyApplicant.individual));
    let address = <ReportIndividualModel>JSON.parse(JSON.stringify(companyApplicant.address));
    let identification = <ReportIndividualModel>JSON.parse(JSON.stringify(companyApplicant.identification));
    let newBeneficialOwner = <any>{
      isElectronic: companyApplicant.isElectronic,
      individual: individual,
      address: address,
      identification: identification
    };

    if (!foundBeneficialOwnerControl) {
      let beneficialOwnersFormArray = (this.formGroupRef.get('beneficialOwners') as UntypedFormArray);
      beneficialOwnersFormArray.push(this.getBeneficialOwnerFormGroup(newBeneficialOwner));
      beneficialOwnersFormArray.markAsDirty();
    } else {
      let  foundBeneficialOwnerFormGroup = foundBeneficialOwnerControl as UntypedFormGroup;
      foundBeneficialOwnerFormGroup.patchValue(newBeneficialOwner);
      foundBeneficialOwnerFormGroup.updateValueAndValidity();
      foundBeneficialOwnerFormGroup.markAsDirty();
    }
  }

  getFormArrayByProperty(property: string): UntypedFormArray {
    return this.formGroupRef.get(property) as UntypedFormArray;
  }

  getFormControlFromFormGroupByProperty(formGroup: AbstractControl, property: string): UntypedFormControl {
    return formGroup.get(property) as UntypedFormControl;
  }

  getFormGroupFromFormGroupByProperty(formGroup: AbstractControl, property: string): UntypedFormGroup {
    return formGroup.get(property) as UntypedFormGroup;
  }

  getFormGroupControlFromFormGroupByProperty(formGroup: AbstractControl, property: string): UntypedFormControl {
    return formGroup.get(property) as UntypedFormControl;
  }

  getFormGroupFromAbstractControl(abstractControl: AbstractControl): UntypedFormGroup {
    return abstractControl as UntypedFormGroup;
  }

  protected getCompanyApplicantFormGroup(value: ReportCompanyApplicantModel = null): UntypedFormGroup {
    return new UntypedFormGroup({
      reportCompanyApplicantKey: new UntypedFormControl(value?.reportCompanyApplicantKey),

      isDeleted: new UntypedFormControl(value?.isDeleted ?? false),
      isElectronic: new UntypedFormControl(value?.isElectronic ?? false),

      individual: this.getIndividualFormGroup(value?.individual),
      address: this.getAddressFormGroup(value?.address),
      identification: this.getIdentificationFormGroup(value?.identification),

      lastReminderSentDateTime: new UntypedFormControl(value?.lastReminderSentDateTime),
      remindersSent: new UntypedFormControl(value?.remindersSent)
    });
  }

  protected getBeneficialOwnerFormGroup(value: ReportBeneficialOwnerModel = null): UntypedFormGroup {
    return new UntypedFormGroup({
      reportBeneficialOwnerKey: new UntypedFormControl(value?.reportBeneficialOwnerKey),

      isDeleted: new UntypedFormControl(value?.isDeleted ?? false),
      isElectronic: new UntypedFormControl(value?.isElectronic ?? false),

      isParentGuardian: new UntypedFormControl(value?.isParentGuardian ?? false),
      isExempt: new UntypedFormControl(value?.isExempt ?? false),
      exemptLegalEntity: new UntypedFormControl(value?.exemptLegalEntity ?? null),
      individual: this.getIndividualFormGroup(value?.individual),
      address: this.getAddressFormGroup(value?.address),
      identification: this.getIdentificationFormGroup(value?.identification),

      lastReminderSentDateTime: new UntypedFormControl(value?.lastReminderSentDateTime),
      remindersSent: new UntypedFormControl(value?.remindersSent)
    });
  }

  protected getIndividualFormGroup(value: ReportIndividualModel = null): UntypedFormGroup {
    return new UntypedFormGroup({
      email: new UntypedFormControl(value?.email),
      lastName: new UntypedFormControl(value?.lastName),
      firstName: new UntypedFormControl(value?.firstName),
      middleName: new UntypedFormControl(value?.middleName),
      suffix: new UntypedFormControl(value?.suffix),
      dateOfBirth: new UntypedFormControl(value?.dateOfBirth),
    });
  }

  protected getAddressFormGroup(value: ReportAddressModel = null): UntypedFormGroup {
    return new UntypedFormGroup({
      addressType: new UntypedFormControl(value?.addressType ?? ReportAddressTypesEnum.Unknown),
      address: new UntypedFormControl(value?.address),
      city: new UntypedFormControl(value?.city),
      postalCode: new UntypedFormControl(value?.postalCode),
      countryCode:  new UntypedFormControl(value?.countryCode),
      stateCode:  new UntypedFormControl(value?.stateCode)
    });
  }

  protected getIdentificationFormGroup(value: ReportIdentificationModel = null): UntypedFormGroup {
    return new UntypedFormGroup({
      documentType: new UntypedFormControl(value?.documentType ?? ReportDocumentTypesEnum.Unknown),
      documentNumber: new UntypedFormControl(value?.documentNumber),
      countryCode: new UntypedFormControl(value?.countryCode),
      stateCode: new UntypedFormControl(value?.stateCode),
      tribalCode:  new UntypedFormControl(value?.tribalCode),
      tribalOther:  new UntypedFormControl(value?.tribalOther),
      documentFileKey: new UntypedFormControl(value?.documentFileKey),
      documentFileName: new UntypedFormControl(value?.documentFileName),
      hasUploadedFile: new UntypedFormControl(false)
    });
  }

  protected mergeFileLists(fileListA: FileList, fileListB: FileList): FileList {
    const dataTransfer = new DataTransfer();

    for (let i = 0; i < fileListA.length; i++) {
      dataTransfer.items.add(fileListA[i]);
    }

    for (let i = 0; i < fileListB.length; i++) {
      dataTransfer.items.add(fileListB[i]);
    }

    return dataTransfer.files;
  }
}
