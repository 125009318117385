import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http'
import { RollUpHeroComponent } from 'src/app/shared/components/views/roll-up/roll-up-hero.component';
import { RollUpItemComponent } from 'src/app/shared/components/views/roll-up/roll-up-item.component';
import {
  BaseEntryDialogTemplateComponent
} from 'src/app/shared/templates/base-entry-dialog-template/base-entry-dialog-template.component';

import { AppRoutingModule } from './routing/app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { AgGridModule } from 'ag-grid-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from '@auth0/auth0-angular';
import { SandboxPageComponent } from 'src/app/pages/application/admin/sandbox-page/sandbox-page.component';
import { ClientService } from 'src/app/core/services/domain/client.service';
import { AppHttpInterceptor } from './core/interceptors/app-http.interceptor';
import { environment } from 'src/environments/environment';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { StaticPageComponent } from 'src/app/pages/static/static-page.component';
import { ApplicationPageComponent } from 'src/app/pages/application/application-page.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import {
  AdminDashboardPageComponent
} from 'src/app/pages/application/admin/admin-dashboard-page/admin-dashboard-page.component';
import { DashboardPageComponent } from 'src/app/pages/application/dashboard-page/dashboard-page.component';
import { ClientsPageComponent } from 'src/app/pages/application/admin/clients-page/clients-page.component';
import {
  BusinessesAdminPageComponent
} from 'src/app/pages/application/admin/businesses-admin-page/businesses-admin-page.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PrivacyPolicyPageComponent } from './pages/shared/privacy-policy-page/privacy-policy-page.component';
import { TermsConditionsPageComponent } from './pages/shared/terms-conditions-page/terms-conditions-page.component';
import { BasePageTemplateComponent } from './shared/templates/base-page-template/base-page-template.component';
import {
  BasePageContentTemplateComponent
} from 'src/app/shared/templates/base-page-template/base-page-content-template.component';
import { MatSelectModule } from '@angular/material/select';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats,
  MatNativeDateModule,
  MatOptionModule,
  NativeDateAdapter
} from '@angular/material/core';
import { UploadComponent } from './shared/components/controls/upload/upload.component';
import {
  BaseEntryPageTemplateComponent
} from 'src/app/shared/templates/base-entry-page-template/base-entry-page-template.component';
import {
  BaseEntryPageContentTemplateComponent
} from 'src/app/shared/templates/base-entry-page-template/base-entry-page-content-template.component';
import {
  BaseEntryPageHeaderTemplateComponent
} from 'src/app/shared/templates/base-entry-page-template/base-entry-page-header-template.component';
import {
  BaseGridHeaderTemplateComponent
} from 'src/app/shared/templates/base-grid-template/base-grid-header-template.component';
import {
  BaseGridContentTemplateComponent
} from 'src/app/shared/templates/base-grid-template/base-grid-content-template.component';
import {
  BaseEntryPageFooterTemplateComponent
} from 'src/app/shared/templates/base-entry-page-template/base-entry-page-footer-template.component';
import { BaseGridTemplateComponent } from 'src/app/shared/templates/base-grid-template/base-grid-template.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { NotificationComponent } from './shared/components/controls/notification/notification.component';
import { FluidHeightDirective } from 'src/app/shared/directives/fluid-height.directive';
import { MatBadgeModule } from '@angular/material/badge';
import { ProfilePageComponent } from './pages/application/profile-page/profile-page.component';
import { AppInitService } from 'src/app/core/services/app/app-init.service';
import { ProfileSetupPageComponent } from './pages/application/profile-setup-page/profile-setup-page.component';
import { MatStepperModule } from '@angular/material/stepper';
import { BusinessesGridComponent } from './shared/components/grids/businesses-grid/businesses-grid.component';
import { BusinessService } from 'src/app/core/services/domain/business.service';
import { BusinessPageComponent } from 'src/app/pages/application/shared/businesses-page/business-page/business-page.component';
import { BusinessFormComponent } from './shared/components/forms/business-form/business-form.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderComponent } from './shared/components/controls/loader/loader.component';
import { MatTabsModule } from '@angular/material/tabs';
import { AddressSubFormComponent } from './shared/components/forms/address-sub-form/address-sub-form.component';
import { BusinessesPageComponent } from 'src/app/pages/application/shared/businesses-page/businesses-page.component';
import { ClientsGridComponent } from './shared/components/grids/clients-grid/clients-grid.component';
import { SafeHtmlPipe } from 'src/app/shared/pipes/safe-html.pipe';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { RedirectPageComponent } from 'src/app/pages/application/redirect-page/redirect-page.component';
import { ClientToBusinessAccessService } from 'src/app/core/services/domain/client-to-business-access.service';
import {
  BusinessAccessFormComponent
} from './shared/components/forms/business-access-form/business-access-form.component';
import {
  BusinessAccessGridComponent
} from './shared/components/grids/business-access-grid/business-access-grid.component';
import { MatDividerModule } from '@angular/material/divider';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ClientPageComponent } from './pages/application/admin/clients-page/client-page/client-page.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SafeUrlPipe } from 'src/app/shared/pipes/safe-url.pipe';
import { SaveButtonComponent } from './shared/components/controls/save-button/save-button.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BlockingLoaderComponent } from './shared/components/controls/blocking-loader/blocking-loader.component';
import { DialogService } from './core/services/domain/dialog.service';
import {
  ConfirmationDialogComponent
} from './shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { LocationService } from 'src/app/core/services/app/location.service';
import { GoogleMapsModule } from '@angular/google-maps';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table'
import { MatPaginatorModule } from '@angular/material/paginator';
import { LocationSearchComponent } from './shared/components/controls/location-search/location-search.component';
import { GlobalErrorHandlerService } from 'src/app/core/services/app/global-error-handler.service';
import { LoggingService } from 'src/app/core/services/app/logging.service';
import { LoginComponent } from './pages/shared/login/login.component';
import { CurrencyFormatterPipe } from 'src/app/shared/pipes/currency-formatter.pipe';
import { DateFormatterPipe } from 'src/app/shared/pipes/date-formatter.pipe';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatChipsModule } from '@angular/material/chips';
import { RoutingService } from 'src/app/core/services/app/routing.service';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { EnumFormatterPipe } from 'src/app/shared/pipes/enum-formatter.pipe';
import { PdfViewerComponent } from './shared/components/views/pdf-viewer/pdf-viewer.component';
import {
  PdfViewerDialogComponent
} from 'src/app/shared/components/dialogs/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { PercentFormatterPipe } from 'src/app/shared/pipes/percent-formatter.pipe';
import { YesNoFormatterPipe } from 'src/app/shared/pipes/yes-no-formatter.pipe';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DateUtilities } from 'src/app/core/utilities/app/date.utilities';
import {
  BusinessContextInitializationService
} from 'src/app/core/services/domain/business-context-initialization.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgChartsModule } from 'ng2-charts';
import { DashboardService } from 'src/app/core/services/domain/dashboard.service';
import { VideoPlayerComponent } from './shared/components/controls/video-player/video-player.component';
import { BusinessSubscriptionsPageComponent } from 'src/app/pages/application/subscriptions/business-subscriptions-page.component';
import { ScriptService } from 'src/app/core/services/app/script.service';
import { BusinessSubscriptionPaymentFormComponent } from 'src/app/shared/components/forms/business-subscription-payment-form/business-subscription-payment-form.component';
import { BusinessSubscriptionService } from 'src/app/core/services/domain/business-subscription.service';
import { VideoDialogComponent } from 'src/app/shared/components/dialogs/video-dialog/video-dialog.component';
import { PricingPageComponent } from 'src/app/pages/shared/pricing-page/pricing-page.component';
import { SalesTaxRatesService } from 'src/app/core/services/domain/sales-tax-rates.service';
import { ClientFormComponent } from 'src/app/shared/components/forms/client-form/client-form.component';
import { ReportFormComponent } from 'src/app/shared/components/forms/report-form/report-form.component';
import { ReportService } from 'src/app/core/services/domain/report.service';
import { ReportsPageComponent } from 'src/app/pages/application/shared/reports-page/reports-page.component';
import { ReportPageComponent } from 'src/app/pages/application/shared/reports-page/report-page/report-page.component';
import { ReportsGridComponent } from 'src/app/shared/components/grids/reports-grid/reports-grid.component';
import { MatRadioModule } from '@angular/material/radio';
import { CountryService } from 'src/app/core/services/domain/country.service';
import { StateService } from 'src/app/core/services/domain/state.service';
import { TribalService } from 'src/app/core/services/domain/tribal.service';
import {
  ReportAddressSubFormComponent
} from 'src/app/shared/components/forms/report-address-sub-form/report-address-sub-form.component';
import {
  ReportIndividualSubFormComponent
} from 'src/app/shared/components/forms/report-indiividual-sub-form/report-individual-sub-form.component';
import {
  ReportIdentificationSubFormComponent
} from 'src/app/shared/components/forms/report-identification-sub-form/report-identification-sub-form.component';
import { DynamicMatTabDirective } from 'src/app/shared/directives/dynamic-mat-tab.directive';
import {
  DashboardHeroNumberComponent
} from 'src/app/shared/components/dashboard/dashboard-hero-number/dashboard-hero-number.component';
import {
  ReportsGridDialogComponent
} from 'src/app/shared/components/dialogs/reports-grid-dialog/reports-grid-dialog.component';
import { ReportActivityService } from 'src/app/core/services/domain/report.activity.service';
import {
  ReportActivitiesPageComponent
} from 'src/app/pages/application/shared/report-activities-page/report-activities-page.component';
import {
  ReportActivitiesGridComponent
} from 'src/app/shared/components/grids/report-activities-grid/report-activities-grid.component';
import {
  ReportNotificationSubFormComponent
} from 'src/app/shared/components/forms/report-notification-sub-form/report-notification-sub-form.component';
import {
  ReportNotificationTokenPageComponent
} from 'src/app/pages/static/report-notification-token-page/report-notification-token-page.component';
import { ReportNotificationTokenService } from 'src/app/core/services/domain/report-notification-token.service';
import {
  ReportNotificationTokenFormComponent
} from 'src/app/shared/components/forms/report-notification-token-form/report-notification-token-form.component';
import {
  ReportIndividualsPageComponent
} from 'src/app/pages/application/shared/report-individuals-page/report-individuals-page.component';
import {
  ReportIndividualsGridComponent
} from 'src/app/shared/components/grids/report-individuals-grid/report-individuals-grid.component';
import { ReportNotificationService } from 'src/app/core/services/domain/report-notification.service';
import {
  BusinessesSubscriptionsPageComponent
} from 'src/app/pages/application/admin/businesses-subscriptions-page/businesses-subscriptions-page.component';
import {
  BusinessSubscriptionsGridComponent
} from 'src/app/shared/components/grids/business-subscriptions-grid/business-subscriptions-grid.component';
import {
  BusinessSubscriptionDialogComponent
} from 'src/app/shared/components/dialogs/business-subscription-dialog/business-subscription-dialog.component';
import {
  BusinessSubscriptionFormComponent
} from 'src/app/shared/components/forms/business-subscription-form/business-subscription-form.component';
import {
  SendNotificationDialogComponent
} from 'src/app/shared/components/dialogs/send-notification-dialog/send-notification-dialog.component';
import {
  AddAccessDialogComponent
} from 'src/app/shared/components/dialogs/add-access-dialog/add-access-dialog.component';
import * as moment from 'moment/moment';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import { DatepickerComponent } from 'src/app/shared/components/controls/date-picker/date-picker.component';

export function init_app(appInitService: AppInitService) {
  return () => appInitService.init();
}

// @Injectable()
// export class AppDateAdapter extends NativeDateAdapter {
//   format(date: Date, displayFormat: Object): string {
//     if (date === null || date === undefined) {
//       return '';
//     }
//
//     const momentDate = moment.utc(date);
//
//     if (momentDate.isValid()) {
//       return momentDate.local().format('MMM DD, YYYY');
//     }
//
//     return '';
//   }
// }
//
// export const APP_DATE_FORMATS: MatDateFormats = {
//   parse: {
//     dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
//   },
//   display: {
//     dateInput: 'input',
//     monthYearLabel: {year: 'numeric', month: 'numeric'},
//     dateA11yLabel: {
//       year: 'numeric', month: 'long', day: 'numeric'
//     },
//     monthYearA11yLabel: {year: 'numeric', month: 'long'},
//   }
// };

@NgModule({
  declarations: [
    //PIPES
    SafeHtmlPipe,
    SafeUrlPipe,
    CurrencyFormatterPipe,
    DateFormatterPipe,
    EnumFormatterPipe,
    PercentFormatterPipe,
    YesNoFormatterPipe,
    //COMPONENTS
    AppComponent,
    StaticPageComponent,
    SandboxPageComponent,
    ApplicationPageComponent,
    AdminDashboardPageComponent,
    DashboardPageComponent,
    BusinessesAdminPageComponent,
    BusinessSubscriptionsGridComponent,
    BusinessesSubscriptionsPageComponent,
    ClientsPageComponent,
    ReportsGridDialogComponent,
    PricingPageComponent,
    PrivacyPolicyPageComponent,
    TermsConditionsPageComponent,
    BaseEntryDialogTemplateComponent,
    BaseEntryPageTemplateComponent,
    BaseEntryPageContentTemplateComponent,
    BaseEntryPageFooterTemplateComponent,
    BaseEntryPageHeaderTemplateComponent,
    BasePageTemplateComponent,
    BasePageContentTemplateComponent,
    BaseGridTemplateComponent,
    BaseGridContentTemplateComponent,
    BaseGridHeaderTemplateComponent,
    UploadComponent,
    NotificationComponent,
    FluidHeightDirective,
    ProfilePageComponent,
    ClientFormComponent,
    ReportFormComponent,
    BusinessSubscriptionPaymentFormComponent,
    BusinessSubscriptionFormComponent,
    ProfileSetupPageComponent,
    BusinessesPageComponent,
    BusinessesGridComponent,
    BusinessPageComponent,
    BusinessesAdminPageComponent,
    BusinessSubscriptionsGridComponent,
    BusinessesSubscriptionsPageComponent,
    BusinessFormComponent,
    LoaderComponent,
    ReportNotificationTokenFormComponent,
    AddressSubFormComponent,
    ClientsGridComponent,
    RedirectPageComponent,
    BusinessAccessFormComponent,
    BusinessAccessGridComponent,
    ReportIndividualsPageComponent,
    ReportIndividualsGridComponent,
    ClientPageComponent,
    SaveButtonComponent,
    BlockingLoaderComponent,
    ConfirmationDialogComponent,
    AddAccessDialogComponent,
    SendNotificationDialogComponent,
    BusinessSubscriptionDialogComponent,
    VideoDialogComponent,
    LocationSearchComponent,
    LoginComponent,
    RollUpHeroComponent,
    RollUpItemComponent,
    PdfViewerComponent,
    PdfViewerDialogComponent,
    VideoPlayerComponent,
    BusinessSubscriptionsPageComponent,
    ReportsPageComponent,
    ReportActivitiesPageComponent,
    ReportActivitiesGridComponent,
    ReportNotificationSubFormComponent,
    ReportPageComponent,
    ReportNotificationTokenPageComponent,
    ReportAddressSubFormComponent,
    ReportIdentificationSubFormComponent,
    ReportIndividualSubFormComponent,
    ReportsGridComponent,
    DynamicMatTabDirective,
    DashboardHeroNumberComponent,
    DatepickerComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    GoogleMapsModule,
    ColorPickerModule,
    HttpClientModule,
    HttpClientModule,
    HttpClientJsonpModule,
    PdfViewerModule,
    ReactiveFormsModule,
    CurrencyMaskModule,
    AgGridModule.withComponents([]),
    NgChartsModule,
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      audience: environment.auth0.audience
    }),
    MatAutocompleteModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatTableModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatRadioModule,
    MatIconModule,
    MatSidenavModule,
    MatExpansionModule,
    MatMenuModule,
    MatTooltipModule,
    FormsModule,
    MatSelectModule,
    MatOptionModule,
    TextFieldModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatBadgeModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatDividerModule,
    DragDropModule
  ],
  providers: [
    AppInitService,
    AuthClientService,
    LoggingService,
    NotificationService,
    DialogService,
    PendingChangesService,
    RoutingService,
    ScriptService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [
        AppInitService
      ],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    //{provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    //{provide: DateAdapter, useClass: AppDateAdapter},
    //{provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
    BusinessContextService,
    BusinessContextInitializationService,
    BusinessService,
    CountryService,
    ReportNotificationService,
    StateService,
    ReportActivityService,
    TribalService,
    ClientToBusinessAccessService,
    ClientService,
    DashboardService,
    LocationService,
    BusinessSubscriptionService,
    ReportService,
    ReportNotificationTokenService,
    SalesTaxRatesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
