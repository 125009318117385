import { BaseEntryPageComponent } from 'src/app/shared/components/base/base-entry-page.component';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

Component({
  template: ''
})
export abstract class BaseReportEntryPageComponent<T> extends BaseEntryPageComponent<T> {
  enableSaveOnTabChange = true;
  fileList: FileList;

  abstract saveForm(isSubmit: boolean): Observable<{ result: boolean, isUpdate: boolean}>;

  protected onBeforeTabChanged(nextTabIndex: number, disableSaveOnChange: boolean = false): void {
    if (!this.enableSaveOnTabChange) {
      this.selectedTabIndex = nextTabIndex;
      return;
    }

    if (!this.isSaving) {
      if (this.formGroupRef.dirty && !disableSaveOnChange) {
        this.saveForm(false).subscribe(result => {
          if (result) {
            this.selectedTabIndex = nextTabIndex;
          }
        });
      } else {
        this.selectedTabIndex = nextTabIndex;
      }
    }
  }

  protected onSelectedTabChanged(direction: number, disableSaveOnChange: boolean = false): void {
    if (!this.enableSaveOnTabChange) {
      this.selectedTabIndex = this.selectedTabIndex + direction;;
      return;
    }

    if (!this.isSaving) {
      if (this.formGroupRef.dirty && !disableSaveOnChange) {
        this.saveForm(false).subscribe(result => {
          if (result) {
            this.selectedTabIndex = this.selectedTabIndex + direction;
          }
        });
      } else {
        this.selectedTabIndex = this.selectedTabIndex + direction;
      }
    }
  }
}
