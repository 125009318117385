<form [formGroup]="formGroupRef">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <div class="mt-2">
          <mat-form-field class="mat-form-field-double">
            <mat-label class="required">Email</mat-label>
            <input [readonly]="readonly"  matInput
                   type="text" formControlName="email">
          </mat-form-field>
        </div>
        <div class="mt-2">
          <mat-form-field class="mat-form-field-double">
            <mat-label class="required">{{lastNameNumber}} Last Name</mat-label>
            <input [readonly]="readonly"  matInput
                   type="text" formControlName="lastName">
          </mat-form-field>
        </div>
        <div class="mt-2">
          <mat-form-field class="mat-form-field-double">
            <mat-label class="required">{{firstNameNumber}} First Name</mat-label>
            <input [readonly]="readonly"  matInput
                   type="text" formControlName="firstName">
          </mat-form-field>
        </div>
        <ng-container *ngIf="!isElectronic">
          <div class="mt-2">
            <mat-form-field class="mat-form-field-double">
              <mat-label>{{middleNameNumber}} Middle Name</mat-label>
              <input [readonly]="readonly"  matInput
                     type="text" formControlName="middleName">
            </mat-form-field>
          </div>
          <div class="mt-2">
            <mat-form-field class="mat-form-field-double">
              <mat-label>{{suffixNumber}} Suffix</mat-label>
              <input [readonly]="readonly"  matInput
                     type="text" formControlName="suffix">
            </mat-form-field>
          </div>
          <div class="mt-2">
            <app-datepicker [required]="true" [readonly]="readonly" formControlName="dateOfBirth" label="{{dateOfBirthNumber}} Date of Birth"></app-datepicker>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</form>
