import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { catchError, first, map, mergeMap, tap } from 'rxjs/operators';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { of } from 'rxjs';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';
import { BusinessService } from 'src/app/core/services/domain/business.service';
import {
  BusinessContextInitializationService
} from 'src/app/core/services/domain/business-context-initialization.service';
import { ScriptService } from 'src/app/core/services/app/script.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppInitService {
  constructor(
    private authClientService: AuthClientService,
    private authService: AuthService,
    private businessContextInitializationService: BusinessContextInitializationService) {
  }
  async init(): Promise<boolean> {
      return this.authService.isAuthenticated$.pipe(catchError(err => {
        console.log(err);
        throw err;
      }),first(), mergeMap(isAuthenticated => {
        if (isAuthenticated) {
          return this.authClientService.refreshUser().pipe(map(_ => {
            return true;
          }));
        }

        this.authClientService.isAuthenticated = isAuthenticated;

        return of(isAuthenticated);
      }), mergeMap(isAuthenticated => {
        if (isAuthenticated) {
          return this.businessContextInitializationService.initBusinessContext();
        }

        return of(null);
      })).toPromise();

    // return this.scriptService.loadScript(paymentScript).pipe(first(), mergeMap(result => {
    //   return this.authService.isAuthenticated$.pipe(first(), mergeMap(isAuthenticated => {
    //     if (isAuthenticated) {
    //       return this.authClientService.refreshUser().pipe(map(_ => {
    //         return true;
    //       }));
    //     }
    //
    //     this.authClientService.isAuthenticated = isAuthenticated;
    //
    //     return of(isAuthenticated);
    //   }), mergeMap(isAuthenticated => {
    //     if (isAuthenticated) {
    //       return this.businessContextInitializationService.initBusinessContext();
    //     }
    //
    //     return of(null);
    //   }))
    // })).toPromise();
  }
}
