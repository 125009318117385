<form [formGroup]="formGroupRef">
  <div class="field-row mt-2">
    <mat-form-field class="mat-form-field-double">
      <mat-label class="required">{{identifyingDocumentTypeNumber}} Identifying Document Type</mat-label>
      <mat-select [disabled]="readonly" formControlName="documentType" (selectionChange)="onDocumentTypeChanged()">
        <mat-option *ngFor="let option of documentTypeOptions"
                    [value]="option.value">{{option.label}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="field-row mt-2">
    <mat-form-field class="mat-form-field-double">
      <mat-label class="required">{{identifyingDocumentNumberNumber}} Identifying Document Number</mat-label>
      <input [readonly]="readonly" matInput
             type="text" formControlName="documentNumber">
    </mat-form-field>
  </div>
  <div class="field-row mt-2">
    <mat-form-field class="mat-form-field-double">
      <mat-label class="required">{{countryJurisdictionNumber}} Country/Jurisdiction</mat-label>
      <mat-select [disabled]="readonly" formControlName="countryCode">
        <ng-container *ngFor="let option of countryOptions">
          <mat-option [value]="option.code">{{option.name}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="field-row mt-2">
    <mat-form-field class="mat-form-field-double">
      <mat-label class="required">{{stateNumber}} State</mat-label>
      <mat-select [disabled]="readonly || !!value.tribalCode || !stateOptions?.length" formControlName="stateCode">
        <mat-option *ngFor="let option of stateOptions"
                    [value]="option.code">{{option.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <ng-container *ngIf="value.documentType === documentTypes.StateLocalTribeIssuedIdentification">
    <div class="field-row mt-2">
      <span class="subtle-text" style="margin-left:165px">Or</span>
    </div>
    <div class="field-row mt-2">
      <mat-form-field class="mat-form-field-double">
        <mat-label>{{localTribeNumber}} Local/Tribe</mat-label>
        <mat-select [disabled]="value.documentType !== documentTypes.StateLocalTribeIssuedIdentification"
                    formControlName="tribalCode"
                    (selectionChange)="formGroupRef.get('tribalOther').setValue(null)">
          <mat-option *ngFor="let option of tribalOptions"
                      [value]="option.code">{{option.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="field-row mt-2" *ngIf="value.tribalCode === otherTribalCode">
      <mat-form-field class="mat-form-field-double">
        <mat-label>{{otherTribeNumber}} Other Local/Tribe Description</mat-label>
        <input matInput type="text" formControlName="firstRegistrationTribalOther">
      </mat-form-field>
    </div>
  </ng-container>
  <div class="field-row mt-2">
    <ng-container *ngIf="value.documentFileName && !value.hasUploadedFile">
      <div class="flex flex-shrink" >
        <div class="flex flex-shrink mr-3" *ngIf="enableDownload">
          <button class="mr-5" color="primary" for="input-file-id" mat-flat-button (click)="onDownloadIdentificationClicked()">Download Identification</button>
        </div>
        <div class="flex">
          <button mat-mini-fab color="primary" (click)="onDeleteIdentificationClicked()" *ngIf="value.documentFileName && !readonly">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <div class="mt-1" *ngIf="value.documentFileName">File Uploaded: <span class="bold">{{value.documentFileName}}</span></div>
    </ng-container>
    <ng-container *ngIf="!readonly && (!value.documentFileName || value.hasUploadedFile)">
      <div class="flex">
        <div class="flex flex-shrink mr-3">
          <app-upload
            [isRequired]="true"
            [label]="value.hasUploadedFile ? (identifyingDocumentImageNumber ?? '') + ' Replace Identification' : (identifyingDocumentImageNumber ?? '') + ' Upload Identification'"
            accept=".jpg,.jpeg,.pdf,.png"
            (fileListChange)="onFilesUploaded($event)"></app-upload>
        </div>
        <div class="flex">
          <button mat-mini-fab color="primary" (click)="onDeleteIdentificationClicked()" *ngIf="value.documentFileName">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <div class="mt-1">
        <div *ngIf="value.documentFileName">File Uploaded: <span class="bold">{{value.documentFileName}}</span></div>
        <div class="subtle-text">Attach a clear, readable image of the page or side  of the identifying document that shows the unique identifying number and other identifiable information.</div>
        <div class="subtle-text">Only <4mb files of jpg, jpeg, pdf and png are supported.</div>
        <div class="subtle-text">Each file name must be unique.</div>
        <div class="subtle-text">The file name must only contains letters, numbers, and/or the following characters !@#$%()_-.=+[]&#10100;&#10101;|;~</div>
      </div>
    </ng-container>
  </div>
</form>
