<mat-card>
  <mat-card-content>
    <mat-stepper linear #stepper (selectionChange)="onStepperChanged()">
      <!--- STEP 1 VERIFY EMAIL -->
      <mat-step [completed]="authClientService.client?.isEmailVerified" editable="false">
        <ng-template matStepLabel>Verify Email</ng-template>
        <div *ngIf="isCheckingVerification">
          <app-loader></app-loader>
        </div>
        <div *ngIf="!isCheckingVerification" class="mt-2">
          <h3>To confirm your email address, an email was sent to <strong>{{authClientService.client.email}}</strong> with the subject
            "Verify your email".  <span class="bold">This email can take up to 5 minutes to be received.</span>
            <br>
            <br>
            Open the email and click the "Verify Your Account" button or the "Verify Link" link.
            <br>
            Once you have received an acknowledgement that says "Email Verified", you may close the window.
            <br>
            Come back to fincenboireporting.com website and click the "Check Verification" button below.
            <br>
            <br>
            <span class="bold">If you do not see the email in your inbox, please check your spam folder or Send the verification email by clicking the button below.</span>
          </h3>
        </div>
        <div class="stepper-buttons">
          <app-save-button class="mr-2" (click)="onCheckVerificationStatusClicked()" [isSaving]="isSaving" [text]="'Verify'" [processingText]="'Verifying'"></app-save-button>
          <app-save-button color="accent" (click)="onSendVerifyEmailClicked()" [isSaving]="isSendingVerificationEmail" [text]="'Send Verification'" [processingText]="'Sending'"></app-save-button>
        </div>
      </mat-step>
      <!--- STEP 1 CLIENT PROFILE -->
      <mat-step [completed]="!!authClientService.client?.clientKey" [stepControl]="clientFormGroup" >
        <ng-template matStepLabel>Setup your profile</ng-template>
        <div *ngIf="clientValue" class="mt-2">
          <app-client-form [isProfileSetup]="true" [(formGroupRef)]="clientFormGroup" [(value)]="clientValue"></app-client-form>
          <div class="stepper-buttons">
            <app-save-button class="mr-2" text="Next"  (click)="onSaveClientProfileClicked()" [isSaving]="isSaving">
            </app-save-button>
          </div>
        </div>
        <ng-container *ngIf="!clientValue">
          <app-loader></app-loader>
        </ng-container>
      </mat-step>
      <!--- STEP 2 BUSINESS PROFILE, IF THEY HAVE CREATED ONE THEN TO GO TO OTHER SCREEN -->
      <mat-step [completed]="!!businessContextService.currentBusiness?.businessKey" [stepControl]="businessFormGroup" *ngIf="showBusinessCard">
        <form [formGroup]="businessFormGroup">
          <ng-template matStepLabel>Manage your business</ng-template>
          <div class="mt-2">
            <app-business-form *ngIf="businessValue"
                               [isProfileSetup]="true"
                               #businessForm
                               [(formGroupRef)]="businessFormGroup"
                               [(value)]="businessValue"
                               [(fileList)]="businessFileList">
            </app-business-form>
          </div>
          <div class="stepper-buttons">
            <button mat-flat-button color="accent" matStepperPrevious>Back</button>
            <app-save-button class="ml-2 mr-2" text="Next" (click)="onSaveBusinessClicked()" [isDisabled]="isSaving" [isSaving]="isSaving"></app-save-button>
          </div>
        </form>
      </mat-step>
<!--      <mat-step [completed]="!!businessContextService.currentBusiness?.businessKey && !!authClientService.client?.clientKey">-->
<!--        <ng-template matStepLabel>Walkthrough</ng-template>-->
<!--        <div class="mt-2">-->
<!--          <div class="flex">-->
<!--            <div class="flex">-->
<!--              <app-video-player [content]="walkthroughVideoConfig">-->
<!--              </app-video-player>-->
<!--            </div>-->
<!--            <div class="flex flex-direction-column ml-2">-->
<!--              <div>-->
<!--                <h3><span class="bold">My Businesses</span></h3>-->
<!--                <p>-->
<!--                  We provide essential administrative services to your business to improve efficiency, sustainability, and profitability.  My business’ screen stores your company contact information.  This information will be used on your business cards, letters and through other parts of the application.-->
<!--                </p>-->
<!--              </div>-->
<!--              <div>-->
<!--                <h3><span class="bold">Accounting</span></h3>-->
<!--                <p>-->
<!--                  We provide an accounting suite that helps manage your company accounts payables, accounts receivable, invoices, customers, bills, and reports.  At the end of the year, your company will only need to print out reports and give them to their accountant. We have a vast range of tools and features within this accounting suite that will help you organize your day-to-day responsibilities regarding your company financial needs.  This suite doesn’t only benefit you but your clients too.-->
<!--                </p>-->
<!--              </div>-->
<!--              <div>-->
<!--                <h3><span class="bold">Payroll</span></h3>-->
<!--                <p>-->
<!--                  We provide a scalable, cost-effecitve, and hassle-free payroll setup for your company inclusive of W2s, 1099s, and year-end fees within our application.-->
<!--                </p>-->
<!--              </div>-->
<!--              <div>-->
<!--                <h3><span class="bold">Documents</span></h3>-->
<!--                <p>-->
<!--                  We offer pre-written business letters, business cards, simple legal contracts, and over 300 pre-made flyers for your business. You get to choose from 100 letter templates and over 50 business card templates. You have the option of leaving the letter as is or modifying it to fit your company needs.  We understand that a business needs smooth and ongoing administrative support.-->
<!--                </p>-->
<!--              </div>-->
<!--              <div>-->
<!--                <h3><span class="bold">Leads/Services</span></h3>-->
<!--                <p>-->
<!--                  Our application allows two professional people/companies to meet each other's needs. Your business will also benefit by having access to other consumers outside of our application.  We connect consumers to your company with one low monthly price.  Once you publish your business on our site, we can help find clients for you.-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="stepper-buttons">-->
<!--            <button mat-flat-button color="accent" matStepperPrevious>Back</button>-->
<!--            <div class="inline-block ml-2">-->
<!--              <button mat-flat-button color="primary" matStepperNext>Next</button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </mat-step>-->
      <mat-step [completed]="!!businessContextService.currentBusiness?.businessKey && !!authClientService.client?.clientKey">
        <ng-template matStepLabel>Finish</ng-template>
        <div class="mt-2">
            <h3><span class="bold">Congratulations, you have successfully setup your account.</span></h3>
          <div class="mt-2">
            Next you will be redirected to purchase a license if you haven't done so already.
          </div>
          <div class="stepper-buttons">
            <button mat-flat-button color="accent" matStepperPrevious>Back</button>
            <div class="inline-block ml-2">
              <button mat-flat-button color="primary" (click)="onFinishClicked()">Finish</button>
            </div>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </mat-card-content>
</mat-card>
<app-blocking-loader *ngIf="showBlockingLoader"></app-blocking-loader>

