import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { TribalModel } from 'src/app/shared/models/domain/tribal.model';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class TribalService {
  private http: HttpClient;
  private url = environment.staticDataUrl + '/';

  static otherCode = 'Other';

  public tribalCache: TribalModel[] = null;

  constructor(httpBackend: HttpBackend) {
    this.http = new HttpClient(httpBackend);
  }

  list(): Observable<TribalModel[]> {
    if (this.tribalCache) {
      return of(JSON.parse(JSON.stringify(this.tribalCache)));
    }

    return this.http.get<TribalModel[]>(`${this.url}tribal.json`).pipe(tap(data => {
      this.tribalCache = data;
    }));
  }
}
