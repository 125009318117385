<app-base-entry-page-template entryTitle="Manage Business" [value]="value" [formGroupRef]="formGroupRef" [showFooter]="selectedTabIndex < 2"
                              [isSaving]="isSaving" (saveClick)="onSaveClicked()" (cancelClick)="onCancelClicked()">
  <app-base-entry-page-content-template>
    <app-business-form *ngIf="value"
                       [(selectedTabIndex)]="selectedTabIndex"
                       [(formGroupRef)]="formGroupRef"
                       [(value)]="value" [(fileList)]="fileList"
                       (downloadLogoClick)="onDownloadLogoClicked($event)">
    </app-business-form>
  </app-base-entry-page-content-template>
  <app-base-entry-page-footer-template>
<!--    <button *ngIf="value?.businessKey && selectedTabIndex === 0" mat-flat-button color="primary" (click)="onDeleteClicked()">Delete</button>-->
  </app-base-entry-page-footer-template>
</app-base-entry-page-template>

