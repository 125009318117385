import {Component, Input, OnInit} from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/components/base/base-form.component';
import { UntypedFormGroup } from '@angular/forms';
import { ReportIndividualModel } from 'src/app/shared/models/domain/report-individual.model';

@Component({
  selector: 'app-report-individual-sub-form',
  templateUrl: './report-individual-sub-form.component.html',
  styleUrls: ['./report-individual-sub-form.component.scss']
})
export class ReportIndividualSubFormComponent extends BaseFormComponent<ReportIndividualModel> implements OnInit {
  @Input() isElectronic = false;
  @Input() readonly = false;
  @Input() lastNameNumber: string | number = '';
  @Input() firstNameNumber: string | number = '';
  @Input() middleNameNumber: string | number = '';
  @Input() suffixNumber: string | number = '';
  @Input() dateOfBirthNumber: string | number = '';

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected getFormGroup(): UntypedFormGroup {
    return this.formGroupRef;
  }
}
