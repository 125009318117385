import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { PendingChangesService } from 'src/app/core/services/app/pending-changes.service';
import { NotificationService } from 'src/app/core/services/app/notification.service';
import { AuthClientService } from 'src/app/core/services/auth/auth-client.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LoggingService } from 'src/app/core/services/app/logging.service';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  observable = new Subject<void>();
  errorMsgs: string[] = [];

  constructor(
    private authClientService: AuthClientService,
    private notificationService: NotificationService,
    private loggingService: LoggingService
  ) {
    this.observable.pipe(debounceTime(2000)).subscribe(t => {
      this.notificationService.showErrorNotification(this.errorMsgs);
      this.errorMsgs = [];
    })
  }

  handleError(error: any) {
    if (this.authClientService.isAdmin) {
      let errorMsg = error?.message || 'Some javascript error occurred!';
      if (!(error instanceof HttpErrorResponse) && error.rejection) {
        errorMsg = error.rejection;
        errorMsg ??= 'Some network error occurred!';
      }

      if (errorMsg?.indexOf && errorMsg?.indexOf('ExpressionChangedAfterItHasBeenCheckedError') === -1) {
        this.errorMsgs.push(errorMsg);
        this.observable.next();
      }

      this.loggingService.logException(error);

      console.error(error);
    }
  }
}
