<form [formGroup]="formGroupRef" autocomplete="off">
  <mat-tab-group #matTabGroup [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onSelectedTabChanged()">
    <mat-tab label="General Information">
      <div class="form-section-header-title mt-2">General Information</div>
      <fieldset>
        <legend>
          Filing Type
        </legend>
        <div class="field-row mt-2">
          <div>
            <span class="mat-label required">1. Filing Type</span>
          </div>
          <mat-radio-group
            (change)="onFilingTypeChanged()"
            [disabled]="readonly"
            formControlName="filingType">
            <div>
              <div class="flex">
                <div class="flex flex-shrink" style="margin-top:2px">
                  <mat-radio-button [value]="filingTypes.InitialReport">Initial Report</mat-radio-button>
                </div>
                <div class="flex flex-shrink">
                  <mat-icon class="ml-1" matSuffix
                            #tooltip="matTooltip" (click)="tooltip.toggle()"
                            matTooltip="Select if this is your first BOI filed for a reporting company.">
                    help_outline
                  </mat-icon>
                </div>
              </div>
            </div>

            <div class="mt-1">
              <div class="flex">
                <div class="flex flex-shrink" style="margin-top:2px">
                  <mat-radio-button [value]="filingTypes.CorrectPriorReport">Correct Prior Report</mat-radio-button>
                </div>
                <div class="flex flex-shrink">
                  <mat-icon class="ml-1" matSuffix
                            matTooltip="Select if previously filled out a report and made an error.">
                    help_outline
                  </mat-icon>
                </div>
              </div>
            </div>

            <div class="mt-1">
              <div class="flex">
                <div class="flex flex-shrink" style="margin-top:2px">
                  <mat-radio-button [value]="filingTypes.UpdatePriorReport">Update Prior Report</mat-radio-button>
                </div>
                <div class="flex flex-shrink">
                  <mat-icon class="ml-1" matSuffix
                            matTooltip="Select if something changed on your report. (ig new beneficial owner, address change).">
                    help_outline
                  </mat-icon>
                </div>
              </div>
            </div>

            <div class="mt-1">
              <div class="flex">
                <div class="flex flex-shrink" style="margin-top:2px">
                  <mat-radio-button [value]="filingTypes.NewlyExemptEntity">Newly Exempt Entity</mat-radio-button>
                </div>
                <div class="flex flex-shrink">
                  <mat-icon class="ml-1" matSuffix matTooltip="Select if you filed a report but are now exempt.">
                    help_outline
                  </mat-icon>
                </div>
              </div>
            </div>
          </mat-radio-group>
        </div>
      </fieldset>

      <fieldset>
        <legend>
          Filing Details
        </legend>
        <div class="field-row mt-2">
          <app-datepicker [readonly]="readonly" formControlName="createdDateTime"
                          label="Date Prepared"></app-datepicker>
        </div>
        <div class="field-row mt-2">
          <div class="form-section-content mt-2">
            <div class="flex">
              <div class="flex flex-shrink">
                <mat-form-field class="mat-form-field">
                  <mat-label>Status</mat-label>
                  <mat-select [disabled]="true" formControlName="status">
                    <mat-option *ngFor="let option of reportStatusOptions"
                                [value]="option.value">{{option.label}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="flex flex-shrink ml-4" *ngIf="value.transcriptionFileKey">
                <div>
                  <button class="mr-5" color="primary" mat-flat-button (click)="onDownloadTranscriptionClicked()">
                    Download Transcription
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="field-row mt-2">
          <mat-form-field class="mat-form-field-double">
            <mat-label>Notes</mat-label>
            <textarea
              [readonly]="readonly"
              #autosize="cdkTextareaAutosize"
              matInput
              cdkTextareaAutosize
              cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="5"
              formControlName="clientNotes">
              </textarea>
          </mat-form-field>
        </div>
        <div class="field-row mt-2" *ngIf="value?.systemNotes">
          <mat-form-field class="mat-form-field-double">
            <mat-label>System Notes</mat-label>
            <textarea
              [readonly]="true"
              #autosize="cdkTextareaAutosize"
              matInput
              cdkTextareaAutosize
              cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="5"
              formControlName="systemNotes">
              </textarea>
          </mat-form-field>
        </div>
        <div class="field-row mt-2" *ngIf="value.reportKey">
          <mat-form-field class="mr-2 mat-form-field-double">
            <mat-label>Report Diagnostic Key</mat-label>
            <input [readonly]="true" matInput type="text" formControlName="reportKey">
          </mat-form-field>
        </div>
      </fieldset>
    </mat-tab>
    <mat-tab label="Reporting Company">
      <div class="form-section-header-title mt-2">Reporting Company Information</div>
      <fieldset>
        <legend>
          Legal Name and Alternate Name(s)
        </legend>
        <div class="field-row mt-2">
          <div class="flex">
            <div class="flex flex-shrink" style="margin-top:2px">
              <div class="flex flex-shrink" style="margin-top: -2px;">
                <mat-checkbox
                  [disabled]="readonly"
                  (change)="onForeignPoolInvestmentVehicleChanged();"
                  formControlName="isForeignPoolInvestmentVehicle">
                </mat-checkbox>
              </div>
              <div class="flex flex-shrink ml-1">
                4. Foreign pooled Investment Vehicle
              </div>
            </div>
            <div class="flex flex-shrink">
              <mat-icon matSuffix
                        matTooltip="A foreign pooled investment vehicle that is not registered to do business in the US is not a Reporting Company.">
                help_outline
              </mat-icon>
            </div>
          </div>
        </div>
        <div class="field-row mt-2">
          <b>Legal Name: {{value?.legalName}}</b>
          <!--          <mat-form-field class="mr-2 mat-form-field-double">-->
          <!--            <mat-label>Reporting Company Legal Name</mat-label>-->
          <!--            <input [disabled]="true" [readonly]="true" matInput type="text" formControlName="legalName">-->
          <!--          </mat-form-field>-->
        </div>
        <div class="field-row mt-2">
          <mat-form-field class="mr-2 mat-form-field-double">
            <mat-label>6. Alternate Name (e.g. Trade Name, DBA)</mat-label>
            <input [readonly]="readonly" matInput type="text" formControlName="alternateName">
            <mat-icon matSuffix matTooltip="Enter any trade names.">
              help_outline
            </mat-icon>
          </mat-form-field>
        </div>
      </fieldset>

      <fieldset>
        <legend>Form of Identification</legend>
        <div class="field-row mt-2">
          <mat-form-field class="mat-form-field-double">
            <mat-label class="required">7. Tax Identification Type</mat-label>
            <mat-select [disabled]="readonly" formControlName="taxIdentificationType"
                        (selectionChange)="onTaxIdentificationTypeChanged()">
              <mat-option *ngFor="let option of taxIdentificationTypeOptions"
                          [value]="option.value">{{option.label}}</mat-option>
            </mat-select>
            <mat-icon matSuffix matTooltip="EIN – Select if you have a US Employer Identification Number.
SSS-ITIN – Select if uses a US Social Security Number or Individual Taxpayer Identification Number.
Foreign – Select if the reporting company has a TAX ID issued by a foreign jurisdiction and does not have a US tax ID number.">
              help_outline
            </mat-icon>
          </mat-form-field>
        </div>
        <div class="field-row mt-2">
          <mat-form-field class="mat-form-field-double">
            <mat-label class="required">8. Tax Identifier #</mat-label>
            <input [readonly]="readonly" matInput type="text" formControlName="taxIdentifier">
          </mat-form-field>
        </div>
        <div class="field-row mt-2">
          <mat-form-field class="mat-form-field-double">
            <mat-label [ngClass]="{required: value.taxIdentificationType === taxIdentificationTypes.Foreign}">9.
              Country/Jurisdiction (If Foreign Tax ID Only)
            </mat-label>
            <mat-select [disabled]="readonly || value.taxIdentificationType !== taxIdentificationTypes.Foreign"
                        formControlName="taxIdentifierForeignCountryCode">
              <ng-container *ngFor="let option of countryOptions">
                <ng-container *ngIf="option.code !== usCountryCode">
                  <mat-option [value]="option.code">{{option.name}}</mat-option>
                </ng-container>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </fieldset>

      <fieldset>
        <legend>
          Jurisdiction of Formation or First Registration
        </legend>
        <div class="field-row mt-2">
          <mat-form-field class="mat-form-field-double">
            <mat-label class="required">10.a. Country/Jurisdiction of Formation</mat-label>
            <mat-select [disabled]="readonly" formControlName="firstRegistrationCountryCode"
                        (selectionChange)="onFirstRegistrationCountryChanged()">
              <mat-option *ngFor="let option of countryOptions"
                          [value]="option.code">{{option.name}}</mat-option>
            </mat-select>
            <mat-icon matSuffix
                      matTooltip="If United States Is selected, fill in complete address. If a foreign country is selected, select state/us territory or tribal jurisdiction in which reporting company first registered to do business in the US. If tribal jurisdiction not listed, select other and enter in tribe.">
              help_outline
            </mat-icon>
          </mat-form-field>
        </div>
      </fieldset>

      <fieldset *ngIf="firstRegistrationStateOptions?.length">
        <legend>
          {{value.firstRegistrationCountryCode === usCountryCode ? 'Domestic' : 'Foreign'}} Reporting Company
        </legend>
        <div class="field-row mt-2">
          <mat-form-field class="mat-form-field-double">
            <mat-label class="required">b. State of Formation</mat-label>
            <mat-select [disabled]="readonly || !!value.firstRegistrationTribalCode"
                        formControlName="firstRegistrationStateCode">
              <mat-option *ngFor="let option of firstRegistrationStateOptions"
                          [value]="option.code">{{option.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="field-row mt-2">
          <span class="subtle-text" style="margin-left:165px">Or</span>
        </div>
        <div class="field-row mt-2">
          <mat-form-field class="mat-form-field-double">
            <mat-label>c. Tribal jurisdiction of formation</mat-label>
            <mat-select [disabled]="readonly || !!value.firstRegistrationStateCode"
                        formControlName="firstRegistrationTribalCode"
                        (selectionChange)="formGroupRef.get('firstRegistrationTribalOther').setValue(null)">
              <mat-option *ngFor="let option of tribalOptions"
                          [value]="option.code">{{option.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="field-row mt-2" *ngIf="value.firstRegistrationTribalCode === otherTribalCode">
          <mat-form-field class="mat-form-field-double">
            <mat-label class="required">d. Name of the Other Tribe</mat-label>
            <input [readonly]="readonly" matInput type="text" formControlName="firstRegistrationTribalOther">
          </mat-form-field>
        </div>
      </fieldset>

      <fieldset>
        <legend>
          <div>
            <div class="flex">
              <div class="flex flex-shrink" style="margin-top:2px">
                Current US Business Address
              </div>
              <div class="flex flex-shrink">
                <mat-icon matSuffix matTooltip="Must be physical business address, no P.O. box.">
                  help_outline
                </mat-icon>
              </div>
            </div>
          </div>
        </legend>
        <div class="field-row mt-2">
          <app-report-address-sub-form
            [readonly]="readonly"
            [usOnly]="true"
            [(value)]="value.companyAddress"
            addressLabel="11. Address (number, street, and apt. or suite no.)"
            cityLabel="12. City"
            countryLabel="13. U.S. or U.S. Territory"
            stateLabel="14. State"
            postalCodeLabel="15. ZIP code"
            [formGroupRef]="getFormGroupFromAbstractControl(formGroupRef.get('companyAddress'))">
          </app-report-address-sub-form>
        </div>
      </fieldset>
    </mat-tab>

    <mat-tab label="Company Applicant(s)">
      <div class="form-section-header-title mt-2">Company Applicant(s) Information</div>
      <ng-container *ngIf="value.filingType !== ReportFilingTypesEnum.NewlyExemptEntity">
        <fieldset>
          <legend>
            Existing Reporting Company
          </legend>
          <div class="field-row mt-2">
            <div class="flex">
              <div class="flex flex-shrink" style="margin-top:2px">
                <div class="flex flex-shrink" style="margin-top: -2px;">
                  <mat-checkbox
                    [disabled]="readonly"
                    (change)="onExistingReportingCompanyChanged();"
                    formControlName="existingReportingCompany">
                  </mat-checkbox>
                </div>
                <div class="flex flex-shrink ml-1">
                  16. Existing Reporting Company
                </div>
              </div>
              <div class="flex flex-shrink">
                <mat-icon matSuffix matTooltip="Check if business existed prior to Jan 1, 2024.">
                  help_outline
                </mat-icon>
              </div>
            </div>
          </div>
        </fieldset>
        <ng-container *ngIf="!this.value.existingReportingCompany">
          <div class="flex flex-end mt-2" *ngIf="!value.isForeignPoolInvestmentVehicle">
            <mat-icon class="primary-color cursor-pointer mr-2" style="transform: scale(2);" *ngIf="!readonly"
                      (click)="onAddCompanyApplicantClicked()" matSuffix matTooltip="Add Company Applicant">
              add_circle
            </mat-icon>
          </div>
          <fieldset
            *ngFor="let companyApplicantFormGroup of getFormArrayByProperty('companyApplicants').controls; let i = index">
            <legend>
              <div class="flex">
                <div class="flex flex-shrink">Company Applicant #{{i + 1}}</div>
                <div class="flex flex-shrink" style="margin-top: -2px !important;">
                  <mat-icon matSuffix
                            matTooltip="An individual who directly files the document that creates or registers the company to do business. No company or legal entities may be listed as a company applicant. No more than two company applicants should be reported.">
                    help_outline
                  </mat-icon>
                </div>
                <div class="flex flex-shrink cursor-pointer" *ngIf="!readonly" style="margin-top: -2px !important;">
                  <mat-icon class="primary-color" (click)="onRemoveCompanyApplicantClicked(i)" matSuffix
                            matTooltip="Remove Company Applicant">
                    delete
                  </mat-icon>
                </div>
                <div class="flex flex-shrink cursor-pointer" *ngIf="!readonly" style="margin-top: -2px !important;">
                  <mat-icon class="primary-color" (click)="onCopyToBeneficialOwnerClicked(companyApplicantFormGroup)"
                            matSuffix matTooltip="Copy to Beneifical Owner">
                    content_copy
                  </mat-icon>
                </div>
              </div>
            </legend>
            <div class="field-row mt-2">
              <app-datepicker *ngIf="companyApplicantFormGroup.value.lastReminderSentDateTime" [readonly]="readonly"
                              [formControl]="getFormControlFromFormGroupByProperty(companyApplicantFormGroup, 'lastReminderSentDateTime')"
                              label="Last Reminder Sent On"></app-datepicker>
            </div>
            <div class="field-row mt-2">
              <mat-checkbox
                [disabled]="readonly"
                [formControl]="getFormGroupControlFromFormGroupByProperty(companyApplicantFormGroup, 'isElectronic')">
                Check here to collect their identification information electronically.
              </mat-checkbox>
            </div>
            <div class="field-row mt-2">
              <div class="form-section-header">Legal Name and Date of Birth</div>
              <app-report-individual-sub-form
                [isElectronic]="companyApplicantFormGroup.value?.isElectronic"
                [readonly]="readonly"
                firstNameNumber="20."
                lastNameNumber="19."
                middleNameNumber="21."
                suffixNumber="22."
                dateOfBirthNumber="23."
                [formGroupRef]="getFormGroupFromFormGroupByProperty(companyApplicantFormGroup, 'individual')">
              </app-report-individual-sub-form>
              <ng-container *ngIf="!companyApplicantFormGroup.value?.isElectronic">
                <div class="flex">
                  <div class="form-section-header mt-2" style="margin-top:2px">
                    Current Address
                  </div>
                  <div class="flex flex-shrink">
                    <mat-icon matSuffix
                              matTooltip="Select business address if you are an applicant who forms the entity in the course of business, such as a paralegal and enter in business address. Otherwise, the individual’s residential address must be reported.">
                      help_outline
                    </mat-icon>
                  </div>
                </div>
                <app-report-address-sub-form
                  [readonly]="readonly"
                  [showAddressType]="true"
                  addressTypeLabel="24. Address Type"
                  addressLabel="25. Address (number, street, and apt. or suite no.)"
                  cityLabel="26. City"
                  countryLabel="27. Country/Jurisdiction"
                  stateLabel="28. State"
                  postalCodeLabel="29. ZIP/Foreign postal code"
                  [formGroupRef]="getFormGroupFromFormGroupByProperty(companyApplicantFormGroup, 'address')"></app-report-address-sub-form>
                <div class="flex">
                  <div class="form-section-header mt-2" style="margin-top:2px">
                    Form of Identification and Issuing Jurisdiction
                  </div>
                  <div class="flex flex-shrink">
                    <mat-icon matSuffix
                              matTooltip="Select a non-expired passport, non-expired driver’s license, or non-expired State/local/tribe-issued identification document.  If you do not have any of the above, select a non-expired passport.">
                      help_outline
                    </mat-icon>
                  </div>
                </div>
                <app-report-identification-sub-form
                  [readonly]="readonly"
                  identifyingDocumentTypeNumber="30."
                  identifyingDocumentNumberNumber="31."
                  countryJurisdictionNumber="32.a."
                  stateNumber="32.b."
                  localTribeNumber="32.c."
                  otherTribeNumber="32.d."
                  identifyingDocumentImageNumber="33."
                  (downloadIdentificationClick)="onDownloadCompanyApplicantIdentificationClicked(companyApplicantFormGroup.value.identification.documentFileKey, companyApplicantFormGroup.value.identification.documentFileName)"
                  (fileListChange)="onFilesChanged($event)"
                  [formGroupRef]="getFormGroupFromFormGroupByProperty(companyApplicantFormGroup, 'identification')">
                </app-report-identification-sub-form>
              </ng-container>
            </div>
          </fieldset>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="value.filingType === ReportFilingTypesEnum.NewlyExemptEntity">
        <div class="mt-5 mb-5">
          This section does not apply to newly exempt entities.
        </div>
      </ng-container>
    </mat-tab>

    <mat-tab label="Beneficial Owner(s)">
      <div class="form-section-header-title mt-2">Beneficial Owner(s) Information</div>
      <ng-container *ngIf="value.filingType !== ReportFilingTypesEnum.NewlyExemptEntity">
        <div class="flex flex-end mt-2" *ngIf="!value.isForeignPoolInvestmentVehicle">
          <mat-icon class="primary-color cursor-pointer mr-2" style="transform: scale(2);" *ngIf="!readonly"
                    (click)="onAddBeneficialOwnerClicked()" matSuffix matTooltip="Add Beneficial Owner">
            add_circle
          </mat-icon>
        </div>
        <fieldset
          *ngFor="let beneficialOwnerFormGroup of getFormArrayByProperty('beneficialOwners').controls; let i = index">
          <legend>
            <div class="flex">
              <div class="flex flex-shrink">Beneficial Owner #{{i + 1}}</div>
              <div class="flex flex-shrink" style="margin-top: -2px !important;">
                <mat-icon matSuffix
                          matTooltip="A person who owns directly or indirectly 25% or more of a reporting entity or exercises substantial control over a reporting company.">
                  help_outline
                </mat-icon>
              </div>
              <div class="flex flex-shrink cursor-pointer" *ngIf="!readonly && !value.isForeignPoolInvestmentVehicle"
                   style="margin-top: -2px !important;">
                <mat-icon class="primary-color" (click)="onRemoveBeneficialOwnerClicked(i)" matSuffix
                          matTooltip="Remove Beneficial Owner">
                  delete
                </mat-icon>
              </div>
            </div>
          </legend>
          <div class="field-row mt-2" *ngIf="beneficialOwnerFormGroup.value.lastReminderSentDateTime">
            <app-datepicker *ngIf="beneficialOwnerFormGroup.value.lastReminderSentDateTime" [readonly]="readonly"
                            [formControl]="getFormControlFromFormGroupByProperty(beneficialOwnerFormGroup, 'lastReminderSentDateTime')"
                            label="Last Reminder Sent On"></app-datepicker>
          </div>
          <div class="field-row mt-2">
            <div class="form-section-header">Exempt Entity</div>
            <div class="flex">
              <div class="flex flex-shrink" style="margin-top:2px">
                <div class="flex flex-shrink" style="margin-top: -2px;">
                  <mat-checkbox
                    [disabled]="readonly"
                    [formControl]="getFormGroupControlFromFormGroupByProperty(beneficialOwnerFormGroup, 'isExempt')">
                  </mat-checkbox>
                </div>
                <div class="flex flex-shrink ml-1">
                  36. Exempt Entity
                </div>
              </div>
              <div class="flex flex-shrink">
                <mat-icon matSuffix
                          matTooltip="If beneficial owner holds ownership exclusively through one or more exempt entities, and the names of that exempt entity are being reported instead of beneficial owner information.">
                  help_outline
                </mat-icon>
              </div>
            </div>
          </div>
          <ng-container *ngIf="!beneficialOwnerFormGroup.value.isExempt">
            <div class="field-row mt-2">
              <mat-checkbox
                [disabled]="readonly"
                [formControl]="getFormGroupControlFromFormGroupByProperty(beneficialOwnerFormGroup, 'isElectronic')">
                Check
                here to collect their identification information electronically.
              </mat-checkbox>
            </div>
            <div class="field-row mt-2">
              <div class="flex">
                <div class="flex flex-shrink" style="margin-top:2px">
                  <div class="flex flex-shrink" style="margin-top: -2px;">
                    <mat-checkbox
                      [disabled]="readonly"
                      [formControl]="getFormGroupControlFromFormGroupByProperty(beneficialOwnerFormGroup, 'isParentGuardian')">
                    </mat-checkbox>
                  </div>
                  <div class="flex flex-shrink ml-1">
                    35. Parent/Guardian information instead of minor child
                  </div>
                </div>
                <div class="flex flex-shrink">
                  <mat-icon matSuffix
                            matTooltip="Check if the Beneficial Owner is a minor child and the parent/guardian information is provided instead.">
                    help_outline
                  </mat-icon>
                </div>
              </div>
            </div>
            <div class="form-section-header mt-2">Legal Name and Date of Birth</div>
            <app-report-individual-sub-form
              firstNameNumber="39."
              lastNameNumber="38."
              middleNameNumber="40."
              suffixNumber="41."
              dateOfBirthNumber="42."
              [isElectronic]="beneficialOwnerFormGroup.value?.isElectronic"
              [readonly]="readonly"
              [formGroupRef]="getFormGroupFromFormGroupByProperty(beneficialOwnerFormGroup, 'individual')">
            </app-report-individual-sub-form>
            <ng-container *ngIf="!beneficialOwnerFormGroup.value?.isElectronic">
              <div class="form-section-header mt-2">Residential Address</div>
              <app-report-address-sub-form
                addressLabel="43. Address (number, street, and apt. or suite no.)"
                cityLabel="44. City"
                countryLabel="45. Country/Jurisdiction"
                stateLabel="46. State"
                postalCodeLabel="47. ZIP/Foreign postal code"
                [readonly]="readonly"
                [showAddressType]="false"
                [formGroupRef]="getFormGroupFromFormGroupByProperty(beneficialOwnerFormGroup, 'address')"></app-report-address-sub-form>
              <div class="flex">
                <div class="form-section-header mt-2" style="margin-top:2px">
                  Form of Identification and Issuing Jurisdiction
                </div>
                <div class="flex flex-shrink">
                  <mat-icon matSuffix
                            matTooltip="Select a non-expired passport, non-expired driver’s license, or non-expired State/local/tribe-issued identification document.  If you do not have any of the above, select a non-expired passport.">
                    help_outline
                  </mat-icon>
                </div>
              </div>
              <app-report-identification-sub-form
                identifyingDocumentTypeNumber="48."
                identifyingDocumentNumberNumber="49."
                countryJurisdictionNumber="50.a."
                stateNumber="50.b."
                localTribeNumber="50.c."
                otherTribeNumber="50.d."
                identifyingDocumentImageNumber="51."
                [readonly]="readonly"
                (downloadIdentificationClick)="onDownloadBeneficialOwnerIdentificationClicked(beneficialOwnerFormGroup.value.identification.documentFileKey, beneficialOwnerFormGroup.value.identification.documentFileName)"
                (fileListChange)="onFilesChanged($event)"
                [formGroupRef]="getFormGroupFromFormGroupByProperty(beneficialOwnerFormGroup, 'identification')"></app-report-identification-sub-form>
              <ng-container *ngIf="beneficialOwnerFormGroup.value.isExempt">
                <div class="form-section-header mt-2">Legal Name</div>
                <mat-form-field class="mr-2 mat-form-field-double">
                  <mat-label>Individual's Last Name or Entity's Legal Name</mat-label>
                  <input [readonly]="readonly" matInput type="text"
                         [formControl]="getFormGroupControlFromFormGroupByProperty(beneficialOwnerFormGroup, 'exemptLegalEntity')">
                </mat-form-field>
              </ng-container>
            </ng-container>
          </ng-container>
        </fieldset>
      </ng-container>
      <ng-container *ngIf="value.filingType === ReportFilingTypesEnum.NewlyExemptEntity">
        <div class="mt-5 mb-5">
          This section does not apply to newly exempt entities.
        </div>
      </ng-container>
    </mat-tab>

    <mat-tab label="Automatic Notifications">
      <div class="form-section-header-title mt-2">Automatic Notifications</div>
      <fieldset>
        <legend>
          Settings
        </legend>
        <div class="field-row mt-2">
          <app-report-notification-sub-form
            [readonly]="readonly"
            [formGroupRef]="getFormGroupFromAbstractControl(formGroupRef.get('reportNotification'))">
          </app-report-notification-sub-form>
        </div>
      </fieldset>
    </mat-tab>

    <mat-tab label="Submit">
      <div class="form-section-header-title mt-2">Submit BOI Application</div>
      <fieldset>
        <legend>
          Submitter Information
        </legend>
        <div class="field-row mt-2">
          <app-datepicker *ngIf="value.lastReminderSentDateTime" [readonly]="readonly"
                          formControlName="lastReminderSentDateTime" label="Last Reminder Sent On"></app-datepicker>
        </div>
        <div class="field-row mt-2">
          <mat-form-field class="mr-2 mat-form-field-double">
            <mat-label class="required">Submitter Email</mat-label>
            <input [readonly]="readonly" matInput type="text" formControlName="submitterEmail">
          </mat-form-field>
        </div>
        <div class="field-row mt-2">
          <mat-form-field class="mr-2 mat-form-field-double">
            <mat-label class="required">Submitter First Name</mat-label>
            <input [readonly]="readonly" matInput type="text" formControlName="submitterFirstName">
          </mat-form-field>
        </div>
        <div class="field-row mt-2">
          <mat-form-field class="mr-2 mat-form-field-double">
            <mat-label class="required">Submitter Last Name</mat-label>
            <input [readonly]="readonly" matInput type="text" formControlName="submitterLastName">
          </mat-form-field>
        </div>
      </fieldset>
    </mat-tab>

    <mat-tab label="Admin" *ngIf="isAdmin">
      <div class="form-section-header-title mt-2">Administrative Use Only</div>
      <fieldset>
        <legend>
          Status
        </legend>
        <div class="field-row mt-2">
          <mat-form-field class="mat-form-field-double">
            <mat-label>Status</mat-label>
            <mat-select formControlName="status">
              <mat-option *ngFor="let option of reportStatusOptions"
                          [value]="option.value">{{option.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="field-row mt-2">
          <mat-form-field class="mat-form-field-double">
            <mat-label>Upload Status</mat-label>
            <mat-select formControlName="uploadStatus">
              <mat-option *ngFor="let option of reportStatusUploadOptions"
                          [value]="option.value">{{option.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </fieldset>
      <fieldset>
        <legend>
          Transcription
        </legend>
        <div class="field-row mt-2">
          <app-datepicker [readonly]="true" formControlName="completedDateTime" label="Completed Date"></app-datepicker>
        </div>
        <div class="field-row mt-2">
          <ng-container *ngIf="value.transcriptionFileName && !value.hasUploadedFile">
            <div class="flex flex-shrink">
              <div class="flex flex-shrink mr-3">
                <button class="mr-5" color="primary" mat-flat-button (click)="onDownloadTranscriptionClicked()">Download
                  Transcription
                </button>
              </div>
              <div class="flex">
                <button mat-mini-fab color="primary" (click)="onDeleteTranscriptionClicked()"
                        *ngIf="value.transcriptionFileName">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
            <div class="mt-1" *ngIf="value.transcriptionFileName">File Uploaded: <span
              class="bold">{{value.transcriptionFileName}}</span></div>
          </ng-container>
          <ng-container *ngIf="(!value.transcriptionFileName || value.hasUploadedFile)">
            <div class="flex">
              <div class="flex flex-shrink mr-3">
                <app-upload
                  [label]="value.hasUploadedFile ? 'Replace Transcription' : 'Upload Transcription'"
                  accept=".jpg,.jpeg,.pdf,.png"
                  (fileListChange)="onTranscriptionFilesUploaded($event)"></app-upload>
              </div>
              <div class="flex">
                <button mat-mini-fab color="primary" (click)="onDeleteTranscriptionClicked()"
                        *ngIf="value.transcriptionFileName">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
            <div class="mt-1">
              <div *ngIf="value.transcriptionFileName">File Uploaded: <span
                class="bold">{{value.transcriptionFileName}}</span></div>
              <div class="subtle-text">Only pdf is supported.</div>
              <div class="subtle-text">Each file name must be unique.</div>
            </div>
          </ng-container>
        </div>
      </fieldset>
      <fieldset>
        <legend>
          Notes
        </legend>
        <div class="field-row mt-2">
          <!--          <div>-->
          <!--            <button color="primary" mat-flat-button>Email Submitter System Notes</button>-->
          <!--          </div>-->
          <div class="mt-2">
            <mat-form-field class="mat-form-field-double">
              <mat-label>System Notes</mat-label>
              <textarea
                #autosize="cdkTextareaAutosize"
                matInput
                cdkTextareaAutosize
                cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="5"
                formControlName="systemNotes">
                </textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="field-row mt-2">
          <mat-form-field class="mat-form-field-double">
            <mat-label>Internal Notes</mat-label>
            <textarea
              #autosize="cdkTextareaAutosize"
              matInput
              cdkTextareaAutosize
              cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="5"
              formControlName="internalNotes">
              </textarea>
          </mat-form-field>
        </div>
      </fieldset>
    </mat-tab>
  </mat-tab-group>
</form>
<app-blocking-loader *ngIf="showBlockingLoader"></app-blocking-loader>
