import { Component, OnInit } from '@angular/core';
import { BusinessModel } from 'src/app/shared/models/domain/business.model';
import { BusinessService } from 'src/app/core/services/domain/business.service';
import { BasePageComponent } from 'src/app/shared/components/base/base-page.component';
import { Router } from '@angular/router';
import { RouteUtilities } from 'src/app/routing/route.utilities';
import { BusinessContextService } from 'src/app/core/services/domain/business-context.service';

@Component({
  selector: 'app-businesses-page',
  templateUrl: './businesses-page.component.html',
  styleUrls: ['./businesses-page.component.scss']
})
export class BusinessesPageComponent extends BasePageComponent implements OnInit {
  businesses: BusinessModel[] = [];

  constructor(
    private router: Router,
    private businessService: BusinessService,
    private businessContextService: BusinessContextService) {
    super();
  }

  ngOnInit(): void {
    this.businessService.search({businessKey: this.businessContextService.currentBusiness.businessKey, includeChildBusinesses: true}).subscribe((res)=> {
      this.businesses = res.filter(t => t.parentBusinessKey == this.businessContextService.currentBusiness.businessKey);
    },error => {

    })
  }

  onAddButtonClicked(): void {
    this.router.navigate([RouteUtilities.routes.application.businessAdd.getNavigateUrl()]);
  }

  onRowClicked($event: any): void {
    this.router.navigate([RouteUtilities.routes.application.businessEdit.getNavigateUrl($event.data.businessKey)]);
  }
}
